import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";

import BlueRectangularButton from "../../../components/common/blueRectangularButton";
import { ReactComponent as Checkmark } from "../../../components/common/icons/checkmark.svg";
import { ReactComponent as Info } from "../../../components/common/icons/infoBlack.svg";
import {
  eventNames,
  sendConsultIntakeViewSection,
} from "../../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../../utils/analytics/helpers";
import LoadingSpinner from "../../../components/common/loadingSpinner";

import AddressSearchInput from "../../../components/care/consultIntake/addressAutocomplete";
import { AddressInfo, ConsultIntake } from "../../../types/care";
import { careService } from "../../../services/care";
import classNames from "classnames";
import LinkedText from "../../../components/common/linkedText";
import { Link } from "react-router-dom";
import { SHIPPING_DAYS } from "../../../components/care/constants";

const ShippingQuestions = ({
  consultIntake,
  submitPage,
  loading,
}: {
  consultIntake: ConsultIntake;
  submitPage: (values: any, callback: (response: any) => void) => void;
  loading: boolean;
}) => {
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({});
  const [prevAddressInfo, setPrevAddressInfo] = useState<AddressInfo>();
  const [addressLoading, setAddressLoading] = useState(false);
  const [editingAddress, setEditingAddress] = useState(false);
  const bannerImage = "/images/graphics/banner-pills.png";

  // for tooltips
  const addressFirstLineQuestion = useRef(null);
  const addressSecondLineQuestion = useRef(null);
  const cityQuestion = useRef(null);
  const zipQuestion = useRef(null);
  const stateQuestion = useRef(null);
  const phoneNumberQuestion = useRef(null);

  type AllQuestionsKeys =
    | "address_first_line"
    | "address_second_line"
    | "city"
    | "state_code"
    | "zip_code"
    | "phone_number";
  const allQuestions = {
    address_first_line: addressFirstLineQuestion,
    address_second_line: addressSecondLineQuestion,
    city: cityQuestion,
    state_code: stateQuestion,
    zip_code: zipQuestion,
    phone_number: phoneNumberQuestion,
  };

  /* Effects */
  // First render
  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consultIntake.consult.uid,
      testHash: consultIntake?.consult?.test_hash,
      section: "pharmacy",
    });
    const fetchAddressInfo = async () => {
      try {
        setAddressLoading(true);
        const response = await careService.asyncFetchConsultAddressInfo(
          consultIntake.consult.uid
        );
        // check if data is present
        if (response.data && response.data.address_first_line) {
          setPrevAddressInfo(response.data);
        } else {
          setEditingAddress(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setAddressLoading(false);
      }
    };
    fetchAddressInfo();
  }, []);

  // utils
  const getError = (
    key: string,
    errors: {
      [key: string]: string;
    },
    apiErrors: {
      [key: string]: string[];
    }
  ) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      console.log("errors", key, errors[key]);
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      console.log("error is ", apiErrors[key][0]);
      return apiErrors[key][0];
    }
  };

  const getInitialValues = () => {
    if (consultIntake?.address_first_line) {
      return {
        address_first_line: consultIntake.address_first_line || "",
        address_second_line: consultIntake.address_second_line || "",
        city: consultIntake.city || "",
        zip_code: consultIntake.zip_code || "",
        state_code: consultIntake.state_code || "",
        phone_number: consultIntake.phone_number || "",
      };
    }

    if (prevAddressInfo?.address_first_line) {
      return {
        address_first_line: prevAddressInfo?.address_first_line || "",
        address_second_line: prevAddressInfo?.address_second_line || "",
        city: prevAddressInfo?.city || "",
        zip_code: prevAddressInfo?.zip_code || "",
        state_code: prevAddressInfo?.state_code || "",
        phone_number: prevAddressInfo?.phone_number || "",
      };
    }

    return {
      address_first_line: consultIntake.address_first_line || "",
      address_second_line: consultIntake.address_second_line || "",
      city: consultIntake.city || "",
      zip_code: consultIntake.zip_code || "",
      state_code: consultIntake.state_code || "",
      phone_number: consultIntake.phone_number || "",
    };
  };

  return (
    <div className="block w-full mt-8 sm:mt-10">
      <Helmet>
        <title>Care | Consult | Intake | Shipping</title>
      </Helmet>
      <div className="max-w-3xl px-4">
        {addressLoading ? (
          <LoadingSpinner />
        ) : (
          <React.Fragment>
            <h3 className="text-center">Pharmacy & Shipping Information</h3>
            <div className="rounded-2xl bg-evvy-white mb-8 flex flex-col">
              <div className="bg-evvy-white rounded-2xl rounded-b-none">
                <img
                  src={bannerImage}
                  alt="Our Pharmacy"
                  className="rounded-2xl rounded-b-none h-24 w-full object-cover"
                />
                <div className="px-5 py-5 sm:px-8 border-b border-gray-300">
                  <div className="b1 my-2">
                    All Rx medications are specially-formulated for you by our
                    NABP Accredited partner pharmacy.
                  </div>
                  <div className="t4 mt-7 mb-4">
                    Evvy’s Partner pharmacy Provides
                  </div>
                  <ul className="list-none ml-0 b1">
                    <li className="flex items-center">
                      <Checkmark className="mr-2 shrink-0" />
                      Specially-formulated treatments prescribed by your
                      provider
                    </li>
                    <li className="flex items-center">
                      <Checkmark className="mr-2 shrink-0" />
                      Pure, prescription-grade ingredients you can’t get over
                      the counter
                    </li>
                    <li className="flex items-center">
                      <Checkmark className="mr-2 shrink-0" />
                      Fast, free shipping in {SHIPPING_DAYS}, with express
                      option available
                    </li>
                  </ul>
                </div>
                <div className="flex items-center py-5 px-5 sm:px-8 rounded-b-lg">
                  <span className="mr-4 self-start my-auto">
                    <Info />
                  </span>
                  <div className="b3 medium">
                    If you would like to select your own pharmacy, please
                    contact{" "}
                    <a
                      href="mailto:support@evvy.com"
                      onClick={analyticsClickHandler({
                        eventName:
                          eventNames.CONSULT_INTAKE_CLICKED_ALT_PHARMACY,
                        eventArgs: {
                          consultId: consultIntake.consult.uid,
                          testHash: consultIntake?.consult?.test_hash,
                        },
                      })}
                    >
                      support@evvy.com
                    </a>
                    . Please note that most of the treatments providers will
                    recommend are specially formulated, and may not be available
                    at your local pharmacy.
                  </div>
                </div>
              </div>
            </div>
            <Formik
              initialValues={getInitialValues()}
              validate={(values) => {
                const errors: { [key in AllQuestionsKeys]?: string } = {};
                ReactTooltip.rebuild();

                // required questions
                var requiredQuestions = [
                  "address_first_line",
                  "city",
                  "zip_code",
                  "state_code",
                  "phone_number",
                ];
                requiredQuestions.forEach((key) => {
                  if (!values[key as AllQuestionsKeys]) {
                    errors[key as AllQuestionsKeys] =
                      "This is a required question";
                    const element =
                      allQuestions[key as AllQuestionsKeys].current;
                    if (element) {
                      ReactTooltip.show(element); // manually show, without requiring hover
                    }
                  }
                });
                return errors;
              }}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values) => {
                setError(null);
                ReactTooltip.rebuild();
                submitPage(values, (response) => {
                  if (typeof response === "object") {
                    setApiErrors(response);
                    Object.keys(response).forEach((k) => {
                      const element =
                        allQuestions[k as AllQuestionsKeys].current;
                      if (element) {
                        ReactTooltip.show(element);
                      }
                    });
                  } else {
                    setError(response || "Error saving consult intake");
                  }
                });
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form>
                  <div className="flex-1 bg-evvy-white p-5 sm:p-8 mb-3 rounded-lg">
                    <div className="t1 medium mb-1">Your shipping address</div>
                    <div className="b1 mt-2 mb-5">
                      If your provider prescribes treatment, this is where it
                      will be shipped:
                    </div>

                    {prevAddressInfo && !editingAddress && (
                      <ConfirmShippingAddress
                        addressInfo={prevAddressInfo}
                        setEditingAddress={setEditingAddress}
                      />
                    )}

                    <div
                      className={classNames({
                        hidden: !editingAddress,
                      })}
                    >
                      <ReactTooltip
                        effect="solid"
                        place="left"
                        type="error"
                        backgroundColor="#FFA684"
                        textColor="#11161A"
                        className="rounded-xs py-20"
                      />

                      <div
                        className={`border rounded-md mb-4 ${
                          getError("address_first_line", errors, apiErrors)
                            ? "border-coral"
                            : "border-transparent"
                        }`}
                        data-tip={getError(
                          "address_first_line",
                          errors,
                          apiErrors
                        )}
                        data-for="address_first_line"
                        ref={addressFirstLineQuestion}
                      >
                        <ReactTooltip
                          id="address_first_line"
                          effect="solid"
                          place="left"
                          type="error"
                          backgroundColor="#FFA684"
                          textColor="#11161A"
                          className="rounded-xs py-20"
                        />
                        <div className="t4 mb-2">Street address</div>

                        <AddressSearchInput
                          setFormFieldValue={setFieldValue}
                          getInitialValues={getInitialValues}
                          className=""
                        />
                        <Field
                          name="address_first_line"
                          as="input"
                          className="hidden w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                          autoComplete="off"
                        />
                      </div>

                      <div
                        className={`border rounded-md mb-4 ${
                          getError("address_second_line", errors, apiErrors)
                            ? "border-coral"
                            : "border-transparent"
                        }`}
                        data-tip={getError(
                          "address_second_line",
                          errors,
                          apiErrors
                        )}
                        data-for="address_second_line"
                        ref={addressSecondLineQuestion}
                      >
                        <ReactTooltip
                          id="address_second_line"
                          effect="solid"
                          place="left"
                          type="error"
                          backgroundColor="#FFA684"
                          textColor="#11161A"
                          className="rounded-xs py-20"
                        />
                        <div className="t4 mb-2">Apartment/Other</div>
                        <Field
                          name="address_second_line"
                          as="input"
                          className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                          autoComplete="off"
                        />
                      </div>
                      <div className="grid grid-cols-4 gap-2">
                        <div
                          className={`col-span-4 sm:col-span-2 border rounded-md mb-2 ${
                            getError("city", errors, apiErrors)
                              ? "border-coral"
                              : "border-transparent"
                          }`}
                          data-tip={getError("city", errors, apiErrors)}
                          data-for="city"
                          ref={cityQuestion}
                        >
                          <ReactTooltip
                            id="city"
                            effect="solid"
                            place="left"
                            type="error"
                            backgroundColor="#FFA684"
                            textColor="#11161A"
                            className="rounded-xs py-20"
                          />
                          <div className="t4 mb-2">City</div>
                          <Field
                            name="city"
                            as="input"
                            className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                            autoComplete="off"
                          />
                        </div>
                        <div
                          className={`col-span-4 sm:col-span-2 border rounded-md mb-2 ${
                            getError("state_code", errors, apiErrors)
                              ? "border-coral"
                              : "border-transparent"
                          }`}
                          data-tip={getError("state_code", errors, apiErrors)}
                          data-for="state_code"
                          ref={stateQuestion}
                        >
                          <ReactTooltip
                            id="state_code"
                            effect="solid"
                            place="left"
                            type="error"
                            backgroundColor="#FFA684"
                            textColor="#11161A"
                            className="rounded-xs py-20"
                          />
                          <div className="t4 mb-2">State</div>
                          <Field
                            name="state_code"
                            as="input"
                            className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                            autoComplete="off"
                          />
                        </div>
                        <div
                          className={`col-span-4 sm:col-span-2 border rounded-md mb-2 ${
                            getError("zip_code", errors, apiErrors)
                              ? "border-coral"
                              : "border-transparent"
                          }`}
                          data-tip={getError("zip_code", errors, apiErrors)}
                          data-for="zip_code"
                          ref={zipQuestion}
                        >
                          <ReactTooltip
                            id="zip_code"
                            effect="solid"
                            place="left"
                            type="error"
                            backgroundColor="#FFA684"
                            textColor="#11161A"
                            className="rounded-xs py-20"
                          />
                          <div className="t4 mb-2">Zipcode</div>
                          <Field
                            name="zip_code"
                            as="input"
                            className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                            autoComplete="off"
                          />
                        </div>
                        <div
                          className={`col-span-4 sm:col-span-2 border rounded-md mb-2 ${
                            getError("phone_number", errors, apiErrors)
                              ? "border-coral"
                              : "border-transparent"
                          }`}
                          data-tip={getError("phone_number", errors, apiErrors)}
                          data-for="phone_number"
                          ref={phoneNumberQuestion}
                        >
                          <ReactTooltip
                            id="phone_number"
                            effect="solid"
                            place="left"
                            type="error"
                            backgroundColor="#FFA684"
                            textColor="#11161A"
                            className="rounded-xs py-20"
                          />
                          <div className="t4 mb-2">Phone Number</div>
                          <Field
                            name="phone_number"
                            as="input"
                            className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    {(errors && Object.keys(errors).length > 0) || error ? (
                      <div className="bg-coral p-2 px-3 font-medium rounded-md my-4">
                        {error
                          ? error
                          : "Please fix the errors above to continue"}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex mt-6">
                    <BlueRectangularButton
                      text="Continue"
                      paddingXClass="sm:px-32"
                      disabled={loading}
                      onClick={handleSubmit}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const ConfirmShippingAddress = ({
  addressInfo,
  setEditingAddress,
}: {
  addressInfo: AddressInfo;
  setEditingAddress: (editing: boolean) => void;
}) => {
  return (
    <div>
      <div className="b1">
        <div>
          {addressInfo.address_first_line}
          {addressInfo.address_second_line && (
            <>
              <br />
              {addressInfo.address_second_line}
            </>
          )}
          <br />
          {addressInfo.city}, {addressInfo.state_code} {addressInfo.zip_code}
        </div>
        <div>Phone: {addressInfo.phone_number}</div>
      </div>
      <div className="flex mt-3">
        <div
          onClick={() => {
            setEditingAddress(true);
          }}
          className="linkedText underline-2 caption medium border-b border-evvy-black cursor-pointer"
        >
          Edit address
        </div>
      </div>
    </div>
  );
};

export default ShippingQuestions;
