import React, { useEffect, useMemo, useRef, useState } from "react";
import { Consult, Prescription, PrescriptionRecord } from "../../../types/care";
import { Link, useLocation, useParams } from "react-router-dom";
import Layout from "../../../components/layout";
import OrderDetailsCard from "./orderDetailsCard";
import PrescriptionCard from "./prescriptionCard";
import { subscriptionService } from "../../../services/subscription";
import ErrorBoundary from "../../../components/common/errorBoundary";
import Modal from "../../../components/common/modal";
import NextTestContentTemplate from "../../../components/plan_unfurled/modalTemplates/nextTest";
import { setMoveTestUpError } from "../../plan/planSlice";
import { useDispatch } from "react-redux";
import { VAGINAL_TEST_PRICE } from "../../constants";
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";
import { sendViewedRefillsPage } from "../../../utils/analytics/customEventTracking";
import PrescriptionModal from "../../../components/care/PrescriptionModal";
import useSubscription from "../../../hooks/useSubscription";
import usePrescriptions from "../../../hooks/care/usePrescriptions";
import CheckoutButton from "../../../components/care/CheckoutButton";
import { useNavigate } from "react-router-dom";

type RefillsCartProps = {
  consults: Consult[];
};

const RefillsCart: React.FC<RefillsCartProps> = ({ consults }) => {
  const currentUser = useLoggedInUser();
  const dispatch = useDispatch();

  const { consultId, treatment } = useParams();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const consult = consults.find((c) => c.uid === consultId);
  const {
    prescriptionsWithNoRefillsRemaining,
    prescriptionsWithRefillsRemaining,
    prescriptionsNotRefillable,
    isLoading: loadingPrescriptions,
  } = usePrescriptions(consultId);

  const prescriptions = useMemo(
    () => [
      ...(prescriptionsWithRefillsRemaining || []),
      ...(prescriptionsWithNoRefillsRemaining || []),
      ...(prescriptionsNotRefillable || []),
    ],
    [
      prescriptionsWithRefillsRemaining,
      prescriptionsWithNoRefillsRemaining,
      prescriptionsNotRefillable,
    ]
  );

  const [lineItems, setLineItems] = useState<{
    prescriptions: PrescriptionRecord[];
    test: boolean;
  }>({ prescriptions: [], test: false });
  const isMember = currentUser.subscription?.isMember;
  const [modalData, setModalData] = React.useState<{ type: string } | null>(
    null
  );
  const [prescriptionModalOpen, setPrescriptionModalOpen] =
    useState<boolean>(false);
  const [selectedPrescriptionSlug, setSelectedPrescriptionSlug] =
    useState<Prescription["slug"]>();
  const location = useLocation();
  const fullPath = location?.state?.from || "/";
  const lastPathSegment = fullPath?.split("/")?.filter(Boolean)?.pop(); // split the path into an array and remove empty strings to grab the last item in the array (ex. care, treatment)
  const [stickyFooterVisible, setStickyFooterVisible] = useState(false);
  const cartCheckoutRef = useRef(null);
  const { subscription } = useSubscription();
  const [createCheckoutLinkError, setCreateCheckoutLinkError] = React.useState<
    string | null
  >(null);

  const cartCount = lineItems.prescriptions.length + (lineItems.test ? 1 : 0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStickyFooterVisible(false);
        } else if (cartCount > 0) {
          setStickyFooterVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (cartCheckoutRef.current) {
      observer.observe(cartCheckoutRef.current);
    }

    return () => {
      if (cartCheckoutRef.current) {
        observer.unobserve(cartCheckoutRef.current);
      }
    };
  }, [cartCount]);

  useEffect(() => {
    if (treatment) {
      const prescription = prescriptions.find(
        (p) => p.product.slug === treatment
      );
      if (prescription && !lineItems.prescriptions.includes(prescription)) {
        addToCart({ prescription });
        navigate(`/care/consults/${consultId}/refills`);
      }
    }
  }, [treatment, prescriptions, lineItems]);

  // If the user is a member, don't add the test to cart.
  // Instead, show the "Move test up to today" modal.
  const renderModal = (closeModal: () => void, handleSubmit: () => void) => {
    return (
      <NextTestContentTemplate
        handleClose={closeModal}
        handleSubmit={handleSubmit}
        subscription={subscription}
      />
    );
  };

  const handleCloseModal = () => {
    setModalData(null);
  };

  const handleSubmit = () => {
    subscriptionService.triggerNextCharge(
      (response: any) => {
        dispatch(setMoveTestUpError(false));
      },
      (error: any) => {
        dispatch(setMoveTestUpError(true));
        console.error(error);
      }
    );
  };

  const addToCart = ({
    prescription,
    test,
  }: {
    prescription?: PrescriptionRecord;
    test?: boolean;
  }) => {
    if (prescription) {
      setLineItems({
        prescriptions: [...(lineItems.prescriptions || []), prescription],
        test: lineItems.test,
      });
    } else if (test) {
      setLineItems({
        prescriptions: lineItems.prescriptions,
        test: true,
      });
    }
  };

  const orderTest = () => {
    if (isMember) {
      setModalData({
        type: "_nexttest",
      });
    } else {
      addToCart({ test: true });
    }
  };

  const prescriptionsPrice = lineItems.prescriptions.reduce(
    (acc, PrescriptionRecord) =>
      Number(acc) + Number(PrescriptionRecord.product?.ecomm_product?.price),
    0
  );

  // TODO: use the actual price of the test instead of hardcoded
  const totalPrice =
    prescriptionsPrice + (lineItems.test ? VAGINAL_TEST_PRICE : 0);

  useEffect(() => {
    sendViewedRefillsPage({
      consultId: consultId,
      prescriptionsAvailable: prescriptions.map(
        (prescription) => prescription.product.slug
      ),
    });
  }, [consultId]);

  const handleOpenPrescriptionModal = (prescription: PrescriptionRecord) => {
    setSelectedPrescriptionSlug(prescription?.product?.slug);
    setPrescriptionModalOpen(true);
  };

  const handleClosePrescriptionModal = () => {
    setPrescriptionModalOpen(false);
  };

  if (!consultId || !consult) return null;

  return (
    <>
      <Layout title={`Care | Refills Cart`} bgClass="bg-evvy-cream" centered>
        <div className="self-start">
          {/* border  wrapper div*/}
          <button
            className={`ml-4 p-1 rounded-full border-2 border-evvy-black text-evvy-black group flex items-center pointer-events-auto`}
            aria-label="Back to care"
            onClick={goBack}
          >
            <span className="px-2 h-8 inline uppercase tracking-wider font-semibold">
              <span>
                <h4 className="font-bold leading-6 margin-0 inline">←</h4>
              </span>
            </span>
          </button>
        </div>
        {modalData !== null && (
          <ErrorBoundary>
            <Modal
              closeModal={() => handleCloseModal()}
              widthClass={"w-plan-column-sm"}
              hasFocusItem
            >
              {renderModal(
                () => handleCloseModal(),
                () => handleSubmit()
              )}
            </Modal>
          </ErrorBoundary>
        )}
        <div className="sm:mb-12 mt-12 w-max-w-8xl">
          <div className="mx-10 mb-12 sm:mx-auto">
            <h1>Stay on track</h1>
            <p className="md:max-w-4xl max-w-2xl text-lg">
              Running low on a treatment? If your provider prescribed any of
              your treatments with the option for refills, you will be able to
              see them below. Once these refills are out, you will need to
              re-test so that a provider can review your results to determine
              which treatments are best for you.
            </p>
          </div>

          {loadingPrescriptions && <p>Loading...</p>}
          {!loadingPrescriptions && (
            <div className="grid lg:grid-cols-3 gap-1 flex-wrap md:grid-cols-5">
              <div className="lg:col-span-2 md:col-span-5">
                {prescriptions?.map((prescription, i) => (
                  <PrescriptionCard
                    key={prescription?.uid}
                    consult={consult}
                    prescription={prescription}
                    addToCart={addToCart}
                    orderTest={() => orderTest()}
                    first={i === 0}
                    last={i === prescriptions.length - 1}
                    selected={
                      lineItems.prescriptions.includes(prescription) ||
                      treatment === prescription.product.slug
                    }
                    hasTestInCart={lineItems.test}
                    openModal={() => handleOpenPrescriptionModal(prescription)}
                  />
                ))}
              </div>
              <div
                className="lg:col-span-1 md:col-span-5"
                ref={cartCheckoutRef}
              >
                <OrderDetailsCard
                  lineItems={lineItems}
                  setLineItems={setLineItems}
                  totalPrice={totalPrice}
                  consult={consult}
                  prescriptionSlugs={prescriptions.map(
                    (prescription) => prescription.product.slug
                  )}
                />
              </div>
            </div>
          )}
        </div>
        {stickyFooterVisible && (
          <Layout.StickyFooter className="flex-row xs:hidden">
            <div>
              {cartCount} {cartCount === 1 ? "item" : "items"} added to cart
            </div>
            <CheckoutButton
              lineItems={lineItems}
              consultId={consult?.uid}
              prescriptionSlugs={prescriptions.map(
                (prescription) => prescription.product.slug
              )}
              setError={setCreateCheckoutLinkError}
              location="footer"
            />
          </Layout.StickyFooter>
        )}
      </Layout>
      {prescriptionModalOpen && selectedPrescriptionSlug && (
        <PrescriptionModal
          consultId={consultId}
          prescriptionSlug={selectedPrescriptionSlug}
          onClose={handleClosePrescriptionModal}
        />
      )}
    </>
  );
};

export default RefillsCart;
