import { Prescription } from "../../../types/care";
import { cn } from "../../../utils/cn";
import { FORM_FACTOR_TO_IMAGE_V2 } from "../constants";
import TransparentCarePhasePill from "../transparentCare/TransparentCarePhasePill";

const ALaCareTreatmentCard: React.FC<{
  treatment: Prescription;
  phase: string;
}> = ({ treatment, phase }) => {
  const formFactor =
    treatment.form_factor as keyof typeof FORM_FACTOR_TO_IMAGE_V2;
  const image = FORM_FACTOR_TO_IMAGE_V2[formFactor]?.image?.individual;

  return (
    <div className="bg-white border-evvy-black border-opacity-10 border rounded-2xl w-full max-w-[165px] sm:max-w-[285px] flex flex-col">
      <div className="flex items-center justify-center rounded-2xl bg-gradient-to-b from-[#D8CFCA] to-white via-[#D8CFCA] via-75% h-[130px] sm:h-[200px] relative">
        <TransparentCarePhasePill
          phase={phase}
          className="absolute top-3 left-3"
        />
        <img
          src={image}
          alt={formFactor}
          className={cn(
            "drop-shadow-sm",
            FORM_FACTOR_TO_IMAGE_V2[formFactor]?.sizeClassName?.individual
          )}
        />
      </div>
      <div className="flex flex-col justify-between flex-grow">
        <div className="px-5 mb-5">
          <div className="text-lg font-medium leading-tight sm:leading-normal mb-1 sm:mb-0">
            {treatment.title_short_display}
          </div>
          <div className="text-sm text-gray-500">{treatment.use_tag}</div>
        </div>
        <div className="px-5 pb-5">
          <button
            className={cn(
              "p-2.5 w-full flex justify-between border rounded hover:bg-evvy-blue transition-colors duration-100",
              {
                "border-evvy-black hover:border-transparent": true,
              }
            )}
            // onClick= TODO
          >
            <div className="t4">Add</div>
            <div className="b2 semibold text-right">
              ${treatment.ecomm_product.price.toString().split(".")[0]}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ALaCareTreatmentCard;
