import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../../components/layout";
import Modal from "../../components/common/modal";
import TreatmentNote from "../../components/care/treatmentNote";
import TreatmentPlan from "../../components/care/TreatmentPlan";
import TreatmentCalendar from "../../components/care/treatmentCalendar/TreatmentCalendar";
import TreatmentFAQs from "../../components/care/treatmentFAQs";
import PrescriptionModal from "../../components/care/PrescriptionModal";
import WhatsNextModal from "../../components/care/whatsNextModal";
import { careService } from "../../services/care";
import { getParameterByName } from "../../utils/urls";
import {
  sendViewedTreatmentPage,
  eventNames,
} from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import useTreatmentPlan from "../../hooks/care/useTreatmentPlan";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { careRetestCheckoutLink } from "../constants";
import { subscriptionService } from "../../services/subscription";
import classNames from "classnames";
import { getTreatmentPlanUrlFromConsult } from "../../components/care/utils";
import { ProblemsModuleExpandedModal } from "../../components/plan_unfurled/modalTemplates/problemsModuleExpandedModal";

// An individual treatment plan for a consult
const ConsultTreatment = ({ consults }) => {
  /*
   * State
   */
  const { consultId } = useParams();
  const { treatmentPlan, error: treatmentPlanError } =
    useTreatmentPlan(consultId);
  const currentUser = useLoggedInUser();

  const [consultIntake, setConsultIntake] = useState(null);
  const [menopausal, setIsMenopausal] = useState(null);
  const [prescriptionModalOpen, setPrescriptionModalOpen] = useState(false);
  const [selectedPrescriptionSlug, setSelectedPrescriptionSlug] = useState();
  // display various sections based on first/second time visitor
  const consultKey = `visited-consult-${consultId}`; // localstorage key for visiting this consult
  const [hasVisited, setHasVisited] = useState(
    localStorage.getItem(consultKey) ? true : false
  );
  const [showNote, setShowNote] = useState(!hasVisited);
  const [showTreatment, setShowTreatment] = useState(true); // stop hiding the treatment on page load
  const [infoModalOpen, setInfoModalOpen] = useState(!hasVisited);
  const [retestLink, setRetestLink] = useState(careRetestCheckoutLink);
  const [problemsModuleModalData, setProblemsModuleModalData] = useState();

  useEffect(() => {
    if (currentUser?.subscription?.isMember) {
      getMembershipPortalLink();
    }
  }, [currentUser]); // set on currentUser

  const getMembershipPortalLink = () => {
    subscriptionService.fetchPortalLink(
      (response) => {
        const { url } = response.data || {};
        if (url) {
          setRetestLink(url);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  };

  const sendAnalyticsViewPage = useCallback(
    (treatmentPlanData) => {
      sendViewedTreatmentPage({
        consultId,
        testHash: treatmentPlanData?.test?.hash,
        pageStatus: showNote ? "full" : "collapsed",
        pathwayType: treatmentPlanData?.consult?.plan_type,
      });
    },
    [consultId, showNote]
  );

  const recordVisit = useCallback(
    (treatmentPlanData) => {
      // record in local storage that user has visited (so we can render different version next time)
      localStorage.setItem(consultKey, true);

      // clear local storage if using ?new debug query param to reset the state
      if (getParameterByName("new")) {
        localStorage.removeItem(consultKey);
        setHasVisited(false);
      }

      sendAnalyticsViewPage(treatmentPlanData);
    },
    [consultKey, sendAnalyticsViewPage]
  );

  useEffect(() => {
    recordVisit(treatmentPlan);
  }, [recordVisit, treatmentPlan]); // record visit when treatment plan is loaded

  const fetchConsultIntake = useCallback(() => {
    careService.fetchConsultIntake(consultId, (response) => {
      setConsultIntake(response.data);
      if (consultIntake?.intake_status === "completed") {
        setHasVisited(true);
      }
      const isMenopausal = response.data?.is_menopausal;
      setIsMenopausal(isMenopausal);
    });
  }, [consultId, consultIntake?.intake_status]);

  useEffect(() => {
    window.scrollTo(0, 0); // don't preserve scroll position when loading this page
    fetchConsultIntake();
  }, [fetchConsultIntake]);

  const consult = consults.filter((c) => c.uid === consultId)[0];
  const test = treatmentPlan && treatmentPlan.test;

  // base URL determines route of the prescription modal
  const baseUrl = consult && getTreatmentPlanUrlFromConsult(consult);

  const handleOpenPrescriptionModal = (prescription) => {
    setSelectedPrescriptionSlug(prescription?.slug);
    setPrescriptionModalOpen(true);
  };

  const handleClosePrescriptionModal = () => {
    setPrescriptionModalOpen(false);
  };
  const isALaCareTreatmentPage = consult?.purchase_type === "a-la-care";

  return (
    <>
      <Layout
        title="Care | Consult | Treatment"
        padded={false}
        bgClass="bg-evvy-cream"
        full
      >
        {treatmentPlanError && (
          <div className="text-red-500 mx-auto">
            Error fetching treatment plan: {treatmentPlanError}
          </div>
        )}

        {!treatmentPlanError && !consult && (
          <div className="mx-auto">
            Invalid Consult ID...{" "}
            <Link to="/care/" className="underline">
              Go back to safety
            </Link>
          </div>
        )}

        {!treatmentPlanError && consult && (
          <>
            <div className="max-w-7xl w-full mx-auto px-4">
              <button
                onClick={() => (window.location = "/care/")}
                className={`p-1.5 rounded-full border-2 border-evvy-black text-evvy-black group flex items-center pointer-events-auto`}
                aria-label="Back to care"
              >
                <span className="uppercase w-7 h-7 text-center">
                  <h4 className="font-bold leading-6">←</h4>
                </span>
              </button>
            </div>
            {/* Second time user gets a toggle */}
            {hasVisited && (
              <div
                className={`p-6 pt-0 border-concrete flex ${
                  showNote ? "" : "border-b"
                }`}
              >
                <div
                  className="text-center flex items-center mx-auto cursor-pointer"
                  onClick={
                    !showNote
                      ? analyticsClickHandler({
                          eventName: eventNames.TREATMENT_EXPAND_PAGE_SECTION,
                          eventArgs: {
                            consultId,
                            testHash: treatmentPlan?.test?.hash,
                            section: "note",
                          },
                          clickHandler: () => setShowNote(!showNote),
                        })
                      : () => setShowNote(!showNote)
                  }
                >
                  <div className="font-semibold">
                    {showNote ? "Hide" : "Show"} Provider's Note
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 opacity-50 ml-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d={
                        showNote
                          ? "M4.5 15.75l7.5-7.5 7.5 7.5"
                          : "M19.5 8.25l-7.5 7.5-7.5-7.5"
                      }
                    />
                  </svg>
                </div>
              </div>
            )}

            {/* Show modal if first time user */}
            {infoModalOpen && !isALaCareTreatmentPage && (
              <Modal
                closeModal={() => setInfoModalOpen(false)}
                widthClass="w-11/12"
              >
                <WhatsNextModal
                  handleClose={() => setInfoModalOpen(false)}
                  consult={consult}
                  menopausal={menopausal}
                />
              </Modal>
            )}

            {/* Show note automatically if first time user */}
            <div
              className={`overflow-hidden ${
                showNote
                  ? "h-auto opacity-100 duration-500 transition-all"
                  : "h-0 opacity-0"
              }`}
            >
              <TreatmentNote
                consult={consult}
                test={test}
                consultIntake={consultIntake}
                openProblemsModuleModal={() => setProblemsModuleModalData(test)}
              />
            </div>

            {/* Second time user gets a toggle */}
            {hasVisited && (
              <div
                className={`p-6 border-concrete flex ${
                  showTreatment
                    ? "bg-white border-color-white transition-all duration-500"
                    : "border-b"
                }`}
              >
                <div
                  className="text-center flex items-center mx-auto cursor-pointer"
                  onClick={
                    !showTreatment
                      ? analyticsClickHandler({
                          eventName: eventNames.TREATMENT_EXPAND_PAGE_SECTION,
                          eventArgs: {
                            consultId,
                            testHash: treatmentPlan?.test?.hash,
                            section: "treatment",
                          },
                          clickHandler: () => setShowTreatment(!showTreatment),
                        })
                      : () => setShowTreatment(!showTreatment)
                  }
                >
                  <div className="font-semibold">
                    {showTreatment ? "Hide" : "Show"} Treatment
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 opacity-50 ml-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d={
                        showTreatment
                          ? "M4.5 15.75l7.5-7.5 7.5 7.5"
                          : "M19.5 8.25l-7.5 7.5-7.5-7.5"
                      }
                    />
                  </svg>
                </div>
              </div>
            )}

            {treatmentPlan && (
              <>
                {/* Show treatment automatically plan if first time user */}
                <div
                  className={classNames(
                    "overflow-hidden",
                    "duration-500",
                    "transition-all",
                    {
                      "h-auto opacity-100": showTreatment,
                      "h-0 opacity-0": !showTreatment,
                    }
                  )}
                >
                  <TreatmentPlan
                    treatmentPlan={treatmentPlan}
                    consult={consult}
                    isMenopausal={menopausal}
                    showRefillsCard={consult.ready_for_refills}
                    openPrescriptionModal={handleOpenPrescriptionModal}
                    baseUrl={baseUrl}
                    retestLink={retestLink}
                  />
                </div>
                {!isALaCareTreatmentPage && (
                  <TreatmentCalendar
                    consult={consult}
                    isMenopausal={menopausal}
                    baseUrl={baseUrl}
                    retestLink={retestLink}
                    openPrescriptionModal={handleOpenPrescriptionModal}
                  />
                )}
                <TreatmentFAQs treatmentPlan={treatmentPlan} />
              </>
            )}
          </>
        )}
      </Layout>
      {prescriptionModalOpen && selectedPrescriptionSlug && (
        <PrescriptionModal
          consultId={consultId}
          prescriptionSlug={selectedPrescriptionSlug}
          onClose={handleClosePrescriptionModal}
        />
      )}
      {problemsModuleModalData && (
        <Modal
          closeModal={() => setProblemsModuleModalData(undefined)}
          widthClass="w-plan-column-md"
          hasFocusItem
        >
          <ProblemsModuleExpandedModal
            data={{ testSummary: problemsModuleModalData }}
            handleClose={() => setProblemsModuleModalData(undefined)}
          />
        </Modal>
      )}
    </>
  );
};

export default ConsultTreatment;
