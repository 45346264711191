import { cloneElement, FC, useState } from "react";
import { CollapsibleDropdownProps } from "./collapsibleDropdown";

type CollapsibleDropdownGroupProps = {
  children: React.ReactElement<CollapsibleDropdownProps>[];
  className?: string;
  collapseOthersOnOpen?: boolean;
  startingOpenIndex?: number;
};

export const CollapsibleDropdownGroup: FC<CollapsibleDropdownGroupProps> = ({
  children,
  className,
  startingOpenIndex = 0,
  collapseOthersOnOpen,
}) => {
  const [openIndices, setOpenIndices] = useState<number[]>(
    startingOpenIndex !== undefined ? [startingOpenIndex] : []
  );

  return (
    <div className={className}>
      {children.map((child, index) =>
        cloneElement(child, {
          key: index,
          open: openIndices.includes(index),
          handleOpen: (open: boolean) => {
            if (open) {
              setOpenIndices(
                collapseOthersOnOpen ? [index] : [...openIndices, index]
              );
            } else {
              setOpenIndices(openIndices.filter((i) => i !== index));
            }
          },
        })
      )}
    </div>
  );
};
