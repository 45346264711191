import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

// components
import LoadingSpinner from "../common/loadingSpinner";

// services
import { testsService } from "../../services/tests";

// lib
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { eventNames } from "../../utils/analytics/customEventTracking";
import useTreatmentPlan from "../../hooks/care/useTreatmentPlan";
import { getCareIntakeUrl, getTreatmentPlanUrlFromConsult } from "./utils";
import { VIEW_RX_PRODUCTS } from "./constants";

const PastTreatmentCard = ({
  consult,
  index,
  numConsults,
  currentlyCareEligible,
}) => {
  const [loading, setLoading] = useState(false);
  const { treatmentPlan } = useTreatmentPlan(consult.uid);
  const [test, setTest] = useState(null);

  const isFirstCard = index === 0;
  const isLastCard = index === numConsults - 1;

  // get test associated with this plan
  const fetchTest = useCallback(() => {
    setLoading(true);
    testsService.fetchTest(
      consult.test_hash,
      (response) => {
        setTest(response.data);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      }
    );
  }, [consult]);

  useEffect(() => {
    fetchTest();
  }, [consult, fetchTest]);

  return (
    <div
      key={index}
      className={`${isFirstCard ? "rounded-t-lg" : ""} ${
        isLastCard ? "rounded-b-lg" : ""
      } border border-gray-300 p-6 sm:p-10`}
    >
      {loading || !treatmentPlan || !test ? (
        <LoadingSpinner />
      ) : (
        <React.Fragment>
          <div className="bg-evvy-pine px-2 py-1 text-xs tracking-wider font-medium uppercase rounded-full mb-4 ml-0 w-fit text-white">
            Vaginitis Treatment
          </div>
          <div className="grid grid-cols-2 gap-8 mb-2">
            <div className="col-span-1">
              <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500">
                Test ID
              </div>
              <div className="font-semibold">
                {test?.pretty_hash?.toUpperCase()}
              </div>
            </div>
            <div className="col-span-1">
              <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500">
                Test Date
              </div>
              <div className="font-semibold">
                {test?.healthcontext?.sample_taken_at}
              </div>
            </div>
            <div className="col-span-1">
              <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500">
                Clinical Intake
              </div>
              <Link
                className="underline cursor-pointer inline-block font-semibold persistSize underline-offset-4"
                to={getCareIntakeUrl("demographics", consult)}
              >
                View
              </Link>
            </div>
            <div className="col-span-1">
              <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500">
                Your Provider
              </div>
              <div className="font-semibold">
                {consult?.clinician_display_name}
              </div>
            </div>
          </div>
          <div className="text-sm uppercase tracking-wider font-semibold text-gray-500 mt-10">
            Your treatment
          </div>
          <ul className="list-none mx-auto mt-4 mb-0 grid grid-cols-1 sm:grid-cols-2">
            {treatmentPlan?.prescriptions.map((p) => (
              <li key={p.slug} className="flex col-span-1 h-9 mb-4 sm:mb-8">
                {p.image_url && (
                  <React.Fragment>
                    <div className="flex-shrink-0 bg-mud rounded-md h-9 w-9 mr-4">
                      <img
                        src={p.image_url}
                        alt=""
                        className="h-full w-full rounded-md"
                      />
                    </div>
                    <div className={`b2 regular self-center`}>
                      {p.title_short_display}
                    </div>
                  </React.Fragment>
                )}
              </li>
            ))}
          </ul>
          <Link
            onClick={analyticsClickHandler({
              eventName: eventNames.PAST_CONSULTATIONS_CLICKED_VIEW_TREATMENT,
              eventArgs: {
                numPastConsultations: numConsults,
                careEligible: currentlyCareEligible,
                consultId: consult?.uid,
                testHash: consult?.test_hash,
                consultStatus: consult?.get_status_display,
              },
            })}
            to={getTreatmentPlanUrlFromConsult(consult)}
          >
            <div className="w-full p-8 mt-4 t4 text-evvy-black text-center bg-evvy-white border border-evvy-black">
              {consult.purchase_type === "a-la-care"
                ? VIEW_RX_PRODUCTS
                : "View Treatment Program →"}
            </div>
          </Link>
        </React.Fragment>
      )}
    </div>
  );
};

export default PastTreatmentCard;
