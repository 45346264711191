import { Link } from "react-router-dom";

const stats = [
  {
    mobilePercentage: "88%",
    desktopPercentage: "100%",
    mobileDescription:
      "went from dysbiosis to a protective microbiome* within 2 months.",
    desktopDescription: "reported symptom relief.",
  },
  {
    mobilePercentage: "100%",
    desktopPercentage: "88%",
    mobileDescription:
      "felt they had access to the care and resources needed to manage their vaginal health.",
    desktopDescription: "moved from dysbiosis to a protective microbiome.",
  },
  {
    mobilePercentage: "100%",
    desktopPercentage: "100%",
    mobileDescription: "felt in control, understood, and encouraged.",
    desktopDescription: "felt in control and understood.",
  },
];

const EvvyStudyStats = () => (
  <>
    <div className="my-14 sm:my-20 max-w-6xl mx-3 sm:mx-auto">
      <div className="uppercase text-center text-sm font-semibold mb-2">
        Integrative, Supportive, and Effective
      </div>
      <div className="text-center text-2xl md:max-w-xl md:mx-auto mb-14 hidden md:block">
        The Complete Treatment Program was designed based on our{" "}
        <Link
          to="https://evvy-public-content.s3.us-east-2.amazonaws.com/documents/Evvy_Innovative_Vaginal_Care_Platform_White_Paper.pdf"
          className="!text-2xl !underline"
        >
          IRB-approved study
        </Link>
        .
      </div>
      <div className="text-center text-xl mx-3 md:max-w-xl md:mx-auto mb-14 block md:hidden">
        Results from our{" "}
        <Link
          to="https://evvy-public-content.s3.us-east-2.amazonaws.com/documents/Evvy_Innovative_Vaginal_Care_Platform_White_Paper.pdf"
          className="!text-xl !underline"
        >
          IRB-approved study
        </Link>{" "}
        proved the efficacy of our Complete Treatment Program.
      </div>
      <div className="flex flex-col mx-3 md:flex-row md:flex-wrap md:justify-between items-center">
        {stats.map((stat, index) => (
          <>
            <div className="flex flex-row items-center w-full md:max-w-[300px] justify-between md:justify-normal">
              <h2 className="bg-highlighter-yellow text-[40px] mb-3 md:mb-0 md:mr-5 !leading-none !my-0">
                <span className="md:hidden">{stat.mobilePercentage}</span>
                <span className="hidden md:inline">
                  {stat.desktopPercentage}
                </span>
              </h2>
              <div className="leading-normal max-w-[60%] xs:max-w-[70%]">
                <span className="md:hidden">{stat.mobileDescription}</span>
                <span className="hidden md:inline">
                  {stat.desktopDescription}
                </span>
              </div>
            </div>
            {index < stats.length - 1 && (
              <>
                <div className="w-full border-b border-dotted border-evvy-black opacity-20 my-8 md:hidden" />
                <div className="h-12 border-l border-dotted border-evvy-black opacity-20 mx-5 hidden md:block" />
              </>
            )}
          </>
        ))}
      </div>
    </div>
  </>
);

export default EvvyStudyStats;
