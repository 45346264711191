import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import Layout from "../../components/layout";
import { testsService } from "../../services/tests";
import { sendViewedHealthHistorySection } from "../../utils/analytics/customEventTracking";
import { Test } from "../../types/test";
import { loadSurvicate } from "../../utils/analytics/helpers";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";

const TITLE = "You’re done!";
const SUBTITLE = "What’s next";
const BULLETS = [
  "📫 Make sure to ship your sample to our lab via the United States\nPostal Service and according to the instructions guide",
  "🔬 You’ll get an email once your results are ready (approximately\n2-3 weeks after they arrive at our lab) ",
];
const LDT_BULLET_2 =
  "🔬 You’ll get an email once your results are ready (approximately\n7-10 business days after they arrive at our lab) ";
const COMPREHENSIVE_BULLET_2 =
  "🔬 You’ll get an email once your preliminary results are ready in 1-3 business days after they arrive at our lab. We will email you again once your final results are available (approximately 7-10 business days after they arrive at our lab)";

const TestDone = () => {
  // route params
  const { testHash: hash } = useParams();
  const [test, setTest] = useState<Test>();
  const currentUser = useLoggedInUser();

  // effects
  useEffect(() => {
    const fetchTestAndSendViewedHealthHistoryEvent = () => {
      if (hash) {
        testsService.fetchTest(
          hash,
          (response: any) => {
            setTest(response.data);
            sendViewedHealthHistorySection({
              section: "done",
              sectionStatus: "blank",
              testOrder: response.data.test_order,
              testHash: hash,
              version: 1,
            });
          },
          (error: any) => {
            console.error(error);
          }
        );
      }
    };

    fetchTestAndSendViewedHealthHistoryEvent();
  }, [hash]);

  useEffect(() => {
    if (currentUser && test) {
      return loadSurvicate(currentUser, {
        hasExpan: test.has_vpcr_test,
        hasUpsellEnabled: test.expan_upsell_enabled,
      });
    }
  }, [currentUser, test]);

  // actions

  return (
    <Layout title="Test | Done" bgClass={"bg-evvy-cream"}>
      <div className="max-w-2xl py-10 mx-auto px-8 sm:px-0 pb-24">
        <div className="w-l">
          <h2 className="mr-2">{TITLE}</h2>
          <div className="bg-evvy-white p-8 rounded-t-lg">
            <div className="uppercase mb-4 inline-block font-semibold tracking-wider text-sm">
              {SUBTITLE}
            </div>
            <p>{BULLETS[0]}</p>
            <p>
              {test?.has_vpcr_test
                ? COMPREHENSIVE_BULLET_2
                : test?.is_ldt
                ? LDT_BULLET_2
                : BULLETS[1]}
            </p>

            <div className="mt-8">
              💙 Keep up with us on{" "}
              <a
                href="https://www.instagram.com/evvy/"
                target="_blank"
                className="underline inline-block medium text-md cursor-pointer"
              >
                Instagram
              </a>{" "}
              or{" "}
              <a
                href="https://www.evvy.com/supportgroup/"
                target="_blank"
                className="underline inline-block medium text-md cursor-pointer"
              >
                join our support community!
              </a>
            </div>
            <div className="mt-8"></div>
            <div className="py-4 flex">
              <Link to={`/tests/`} className="w-full  block mx-auto">
                <button className="cursor-pointer tracking-wider w-full py-6 px-20 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none">
                  Go to my tests →
                </button>
              </Link>
            </div>
          </div>
          <img
            className="w-full rounded-b-lg"
            src="/images/graphics/triple-science-blue.png"
            loading="lazy"
            alt="Evvy Microbiome Test"
          />
        </div>
      </div>
    </Layout>
  );
};

export default TestDone;
