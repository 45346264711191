import { cn } from "../../../utils/cn";
import { transparentCarePhaseColorMap } from "../../../utils/colors";

const TransparentCarePhasePill: React.FC<{
  phase: string;
  className?: string;
}> = ({ phase, className }) => (
  <div
    className={cn(
      "uppercase text-sm font-semibold rounded-full border border-black border-opacity-5 px-2.5 py-0.5 max-w-min z-10",
      transparentCarePhaseColorMap[
        phase as keyof typeof transparentCarePhaseColorMap // hacky type assertion to appease TS
      ].full,
      className
    )}
  >
    {phase}
  </div>
);

export default TransparentCarePhasePill;
