import React, { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";

import { testsService } from "../../services/tests";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import {
  eventNames,
  sendClickedActivateTest,
  sendClickedChecklistCTA,
  sendClickedTestSampleTrackingLink,
  sendViewedChecklist,
} from "../../utils/analytics/customEventTracking";
import { strDateToTimestamp, formatDate } from "../../utils/datetime";
import { subscriptionService } from "../../services/subscription";
import { ChecklistStatus, Test } from "../../types/test";
import { Subscription } from "../../types/subscription";
import { GET_CLINICAL_CARE } from "../../pages/constants";

import { Cycle } from "./checkList/cycle";
import { CompleteCycle } from "./checkList/completeCycle";
import { CircleCheckmark } from "../common/circleCheckmark";
import LoadingSpinner from "../common/loadingSpinner";
import { ReactComponent as ArrowDown } from "../../components/common/icons/arrow-down-no-tail.svg";
import { ReactComponent as ArrowUp } from "../../components/common/icons/arrow-up-no-tail.svg";
import {
  getButtonCTATextForConsultation,
  handleCareIntroCTAClick,
} from "../care/utils";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { useNavigate } from "react-router-dom";

const CARE_DESCRIPTION =
  "Access first-of-its-kind, integrative vaginal healthcare designed for your vaginal microbiome.";

const A_LA_CARE_ONLY_DESCRIPTION = "Order from a selection of Rx products.";

const ELIGIBLE_FOR_BOTH_DESCRIPTION =
  "You can order a personalized, complete treatment program or individual Rx products.";

const STEPS = [
  {
    slug: "activate",
    title: "Activate your test and<br>take your sample",
    actions: [
      {
        actionSlug: "activate",
        ctaCopy: "Activate your test →",
        ctaLink: "",
        ctaTarget: "",
        description:
          "Full instructions can be found in your test kit. Make sure to fill out all your health forms online!",
      },
      {
        actionSlug: "complete-hh",
        ctaCopy: "",
        ctaLink: "",
        ctaTarget: "",
        description:
          "Take your sample and send it back to us to receive your results and plan.",
      },
    ],
  },
  {
    slug: "view-results",
    title: "Review Results + Plan",
    actions: [
      {
        actionSlug: "view-sample-report",
        ctaCopy: "",
        ctaLink: "",
        ctaTarget: "",
        description:
          "While you wait for your results, learn more about the vaginal microbiome and it’s impact on overall health ",
      },
      {
        actionSlug: "view-initial-results",
        ctaCopy: "",
        ctaLink: "",
        ctaTarget: "",
        description:
          "Review your preliminary results thoroughly while we process your full test results! ",
      },
      {
        actionSlug: "view-initial-results-attention",
        ctaCopy: "",
        ctaLink: "",
        ctaTarget: "",
        description:
          "We’ve detected something requiring attention in your preliminary results, so please review thoroughly while we process your full test results.",
      },
      {
        actionSlug: "view-results",
        ctaCopy: "View Your Plan →",
        ctaLink: "/plan/",
        ctaTarget: "_self",
        description:
          "First view your results, then check out the personalized plan we’ve created based on your unique microbiome.",
      },
    ],
  },
  {
    slug: "care",
    title: "Talk to your Coach",
    careTitle: "Get Care",
    actions: [
      {
        actionSlug: "clinical-care",
        ctaCopy: `${GET_CLINICAL_CARE} →`,
        ctaLink: "", // handled by handleCareIntroCTAClick
        ctaTarget: "_self",
        description: CARE_DESCRIPTION,
      },
      {
        actionSlug: "finish-intake",
        ctaCopy: "dynamic", // handled by getButtonCTATextForConsultation
        ctaLink: "", // handled by handleCareIntroCTAClick
        ctaTarget: "_self",
        description: CARE_DESCRIPTION,
      },
      {
        actionSlug: "track-consult-status",
        ctaCopy: "dynamic", // handled by getButtonCTATextForConsultation
        ctaLink: "", // handled by handleCareIntroCTAClick
        ctaTarget: "_self",
        description: CARE_DESCRIPTION,
      },
      {
        actionSlug: "view-treatment-plan",
        ctaCopy: "dynamic", // handled by getButtonCTATextForConsultation
        ctaLink: "", // handled by handleCareIntroCTAClick
        ctaTarget: "_self",
        description: CARE_DESCRIPTION,
      },
      {
        actionSlug: "book-coaching",
        ctaCopy: "Book a Coaching Call →",
        ctaLink: "",
        ctaTarget: "_blank",
        description:
          "Book a 1:1 call with a board-certified health coach to talk through your results and plan.",
      },
    ],
  },
  {
    slug: "retest",
    title: "Retest with Evvy",
    actions: [
      {
        actionSlug: "order",
        ctaCopy: "Order your next test →",
        ctaLink: "",
        ctaTarget: "_blank",
        description:
          "Based on your results, we recommend you retest in 3 months to ensure that your microbiome is healthy.",
      },
      {
        actionSlug: "track",
        ctaCopy: "Track my order →",
        ctaLink: "",
        ctaTarget: "_blank",
        description: "Your next Evvy test is shipped and on the way!",
      },
    ],
  },
];

const GENERIC_ERROR_MESSAGE =
  "Error fetching your information. Please refresh the page or contact support@evvy.com if the issue persists.";
const CHECKLIST_TITLE_MD = "Your Evvy<br>Journey";
const REORDER_MEMBER_CTA_COPY = "Update shipping date →";

export const CheckList: React.FC<{
  openActivateModal: () => void;
}> = ({ openActivateModal }) => {
  const COMPLETE_MESSAGE_COPY = "You’re on your way to a healthier microbiome!";
  const BANNER_COPY = {
    activate: "Activate your test and take your sample",
    delivered: "Your test has been delivered!",
  };
  const [loading, setLoading] = useState(true);
  const [test, setTest] = useState<Test>();
  const [error, setError] = useState("");
  const [currentStatus, setCurrentStatus] = useState<ChecklistStatus>({
    step_number: 1,
    step: "activate",
    action: "activate",
    cta_link: "",
    completed_steps: [],
  });
  const [cycleComplete, setCycleComplete] = useState(false);
  const [newTestDelivered, setNewTestDelivered] = useState(false);
  const totalNumSteps = STEPS.length;

  // effects
  useEffect(() => {
    async function fetchTest() {
      setLoading(true);
      try {
        const response = await testsService.fetchCurrentTest();
        if (response) {
          const currentTest = response.data as Test;
          setTest(currentTest);
          const checklistData = response.data
            .checklist_status as ChecklistStatus;
          setCurrentStatus(checklistData);
          setCycleComplete(
            checklistData.completed_steps.includes(totalNumSteps)
          );
          if (currentTest && !currentTest.activated_at) {
            setNewTestDelivered(true);
          }
        }
        // trigger view checklist event
        // in some cases, where user has no test on file, the response will be null
        sendViewedChecklist({
          testOrder: response?.data?.test_order,
          testHash: response?.data?.hash,
          checklistStatus:
            response?.data?.checklist_status?.action || currentStatus.action,
        });
      } catch (error) {
        console.error(error);
        setError(GENERIC_ERROR_MESSAGE);
      } finally {
        setLoading(false);
      }
    }
    fetchTest();
  }, []); // Fetches test upon page load

  if (loading) {
    return (
      <div className="bg-white p-8 rounded-2xl">
        <div className="p-10">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white px-7 pt-7 pb-4 sm:px-8 sm:pt-8 sm:pb-4 rounded-2xl">
      <div
        className={`${
          newTestDelivered ? "bg-evvy-silverfish text-sky-700" : "bg-evvy-cream"
        } p-2.5 text-center`}
      >
        {newTestDelivered
          ? BANNER_COPY.delivered
          : test?.activated_at
          ? `Test ID: ${test?.pretty_hash}`
          : BANNER_COPY.activate}
      </div>
      {error ? (
        <div className="text-center text-red py-4">{error}</div>
      ) : (
        currentStatus && (
          <>
            <div className="flex justify-between content-center pt-4 pb-0">
              <h3 className="my-auto text-[36px]">
                <Markdown>{CHECKLIST_TITLE_MD}</Markdown>
              </h3>
              {cycleComplete ? (
                <CompleteCycle />
              ) : (
                <Cycle
                  completedStepNumber={Math.max(
                    ...currentStatus.completed_steps,
                    currentStatus.step_number - 1
                  )}
                />
              )}
            </div>
            {cycleComplete && (
              <div className="b1 semibold">{COMPLETE_MESSAGE_COPY}</div>
            )}
            <ol className="mx-auto pt-5 my-auto">
              {Array.from({ length: totalNumSteps }, (_, i) => i + 1).map(
                (index) => (
                  <li key={index} className="py-2">
                    <ChecklistStep
                      test={test}
                      currentStatus={currentStatus}
                      stepNumber={index}
                      openActivateModal={openActivateModal}
                    />
                  </li>
                )
              )}
            </ol>
          </>
        )
      )}
    </div>
  );
};

const ChecklistStep: React.FC<{
  test?: Test;
  currentStatus: ChecklistStatus;
  stepNumber: number;
  openActivateModal: () => void;
}> = ({ test, currentStatus, stepNumber, openActivateModal }) => {
  const currentUser = useLoggedInUser();

  const ARTICLE_LINK = "https://www.evvy.com/blog/meet-your-microbiome";
  // is this checklist step the one we're currently on?
  const isCurrentStep = stepNumber === currentStatus.step_number;
  // is this checklist step completed?
  const isCompleted = currentStatus.completed_steps.includes(stepNumber);
  // step is enabled if is current step or past step
  const isStepEnabled = isCurrentStep || stepNumber < currentStatus.step_number;
  const stepCopy = stepNumber > 0 ? STEPS[stepNumber - 1] : STEPS[0];
  const actionConfig =
    stepCopy.actions.filter(
      ({ actionSlug }) => actionSlug === currentStatus.action
    )[0] || stepCopy.actions[stepCopy.actions.length - 1];
  const ctaType = currentStatus.action;

  // special handling for care step
  const isCareStep = stepCopy.slug === "care";
  const isCareEligibleStep = isCareStep && ctaType !== "book-coaching";

  // special handling for recommended plan experiment
  const firstTimeVaginitisCare =
    !currentUser.care.vaginitis.hasCompletedConsult;
  const navigate = useNavigate();

  //  state
  const [stepExpanded, setStepExpanded] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState<Subscription>();

  useEffect(() => {
    setStepExpanded(isCurrentStep);

    // fetch subscription info if we're on the order step
    async function fetchSubscription() {
      setLoading(true);
      try {
        const response = await subscriptionService.fetchSubscriptionInfo();
        if (response) {
          const subscriptionData = response.data as Subscription;
          setSubscription(subscriptionData);
        }
      } catch (error) {
        console.error(error);
        setError(GENERIC_ERROR_MESSAGE);
      } finally {
        setLoading(false);
      }
    }
    if (isCurrentStep && ctaType === "order") {
      fetchSubscription();
    }
  }, [isCurrentStep, ctaType]);

  // utils
  const getCTACopy = () => {
    // if it's a care CTA and it's not related to coaching, then just use the care CTA helper
    if (isCareEligibleStep && test?.latest_vaginitis_consult) {
      return getButtonCTATextForConsultation(
        test.latest_vaginitis_consult,
        firstTimeVaginitisCare
      );
    }

    if (ctaType === "order" && subscription?.next_charge_date) {
      return REORDER_MEMBER_CTA_COPY;
    }
    return actionConfig.ctaCopy;
  };

  let careCopy;
  if (test?.eligible_for_care && test?.eligible_for_a_la_care) {
    careCopy = ELIGIBLE_FOR_BOTH_DESCRIPTION;
  } else if (test?.eligible_for_a_la_care) {
    careCopy = A_LA_CARE_ONLY_DESCRIPTION;
  } else if (test?.eligible_for_care || test?.care_eligibility_expires_at) {
    careCopy = CARE_DESCRIPTION;
  }

  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-start">
          <CircleCheckmark
            circleClassName={isCompleted ? "fill-evvy-blue" : ""}
            className="mr-4 shrink-0"
          />
          <div>
            <div
              className={`t3 medium ${
                isStepEnabled ? "text-evvy-black" : "text-evvy-black-dull"
              }`}
            >
              <Markdown>
                {(test?.eligible_for_care ||
                  test?.care_eligibility_expires_at ||
                  test?.eligible_for_a_la_care) &&
                stepCopy.careTitle
                  ? stepCopy.careTitle
                  : stepCopy.title}
              </Markdown>
            </div>
            {stepExpanded ? (
              <div className="b2 py-2">
                <Markdown>
                  {/* special dynamic copy for evvy member retest and for care eligible description */}
                  {ctaType === "order" && subscription?.next_charge_date
                    ? `Your next Evvy test will ship on ${formatDate(
                        strDateToTimestamp(subscription.next_charge_date)
                      )}. Click below to update your shipping date!`
                    : isCareStep && careCopy
                    ? careCopy
                    : actionConfig.description}
                </Markdown>
                {isCurrentStep && ctaType === "view-sample-report" && (
                  <a
                    className="persistSize underline"
                    href={ARTICLE_LINK}
                    onClick={analyticsClickHandler({
                      eventName: eventNames.CHECKLIST_CLICKED_LINK,
                      eventArgs: {
                        testOrder: test?.test_order,
                        testHash: test?.hash,
                        testStatus: test?.get_status_display,
                        link: ARTICLE_LINK,
                      },
                    })}
                    target="_blank"
                    rel="noreferrer"
                  >
                    here.
                  </a>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <button
          disabled={!isStepEnabled}
          onClick={() => setStepExpanded(!stepExpanded)}
          className="self-start"
        >
          {stepExpanded ? (
            <ArrowUp
              className={`mt-1 shrink-0 ${
                isStepEnabled ? "fill-evvy-black" : "fill-evvy-black-dull"
              }`}
            />
          ) : (
            <ArrowDown
              className={`mt-1 shrink-0 ${
                isStepEnabled ? "fill-evvy-black" : "fill-evvy-black-dull"
              }`}
            />
          )}
        </button>
      </div>

      {!loading && isCurrentStep && actionConfig.ctaCopy ? (
        <ChecklistCTA
          disabled={loading}
          link={currentStatus.cta_link || actionConfig.ctaLink}
          target={actionConfig.ctaTarget || "_self"}
          isPrimaryCTA={ctaType !== "view-results"}
          ctaText={getCTACopy()}
          onClick={() => {
            const eventArgs = {
              testOrder: test?.test_order,
              testHash: test?.hash,
              testStatus: test?.get_status_display,
              ctaText: getCTACopy().replace(" →", ""), // remove arrow
              checklistStatus: ctaType,
              stepNumber,
            };
            sendClickedChecklistCTA(eventArgs);

            if (
              isCareEligibleStep &&
              test?.latest_vaginitis_consult &&
              test?.latest_vaginitis_consult?.purchase_type === "v0-bundle"
            ) {
              // if it's a care CTA and it's not related to coaching and not a-la-care, then just use the care CTA helper
              handleCareIntroCTAClick(
                test,
                test.latest_vaginitis_consult,
                false,
                navigate,
                getCTACopy(),
                "checklist"
              );
            } else if (isCareEligibleStep) {
              navigate("/care/");
            }

            if (ctaType === "activate") {
              sendClickedActivateTest({
                testOrder: test?.test_order,
                testHash: test?.hash,
              });
              openActivateModal();
            } else if (ctaType === "track") {
              sendClickedTestSampleTrackingLink({
                ...eventArgs,
                location: "checklist",
              });
            }
          }}
        />
      ) : (
        ""
      )}

      {error ? <div className="text-red-500 py-2">{error}</div> : ""}
    </>
  );
};

const ChecklistCTA: React.FC<{
  disabled: boolean;
  ctaText: string;
  link?: string;
  target?: string;
  onClick?: () => void;
  isPrimaryCTA: boolean;
}> = ({
  disabled,
  ctaText,
  onClick,
  link,
  target = "_self",
  isPrimaryCTA = true,
}) => {
  const borderColor = isPrimaryCTA ? "border-evvy-blue" : "border-evvy-black";
  const backgroundColor = isPrimaryCTA ? "bg-evvy-blue" : "bg-evvy-white";
  const classNameStyles = `uppercase font-semibold tracking-wider text-sm w-full rounded-sm text-center block border ${borderColor} ${backgroundColor} py-5`;
  return (
    <div className="pt-4">
      {link ? (
        <a href={link} target={target} className={classNameStyles}>
          {ctaText}
        </a>
      ) : (
        <button
          onClick={onClick}
          className={classNameStyles}
          disabled={disabled}
        >
          {ctaText}
        </button>
      )}
    </div>
  );
};
