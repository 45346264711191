import { useState, useEffect } from "react";
import FocusTrap from "focus-trap-react"; // traps focus inside child, this is for accessibility when tabbing

// quite a simple component, shows modal
// handle hide-show in parent component, just pass in close() prop
const Modal = ({
  children,
  closeModal = () => {},
  widthClass = "",
  hasFocusItem = false,
  allowOverflow = false,
  preventBodyScroll = false,
}) => {
  // mobile modal slides in from bottom, so we render different content and animate it on a delay
  const isMobile = window && window.innerWidth < 600 ? true : false;
  const [mobileModalLoaded, setMobileModalLoaded] = useState(!isMobile);

  useEffect(() => {
    if (isMobile && !mobileModalLoaded) {
      setTimeout(() => {
        setMobileModalLoaded(true); // when loading, wait a bit then slide the mobile modal in
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (preventBodyScroll) {
      document.body.style.overflow = "hidden";
    }
    return () => (document.body.style.overflow = "unset");
  }, [preventBodyScroll]);

  // rendered in middle of screen all the time
  const webContent = (
    <div
      className="fixed z-20 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="justify-center min-h-screen text-center block px-8">
        <div
          className={`fixed inset-0 bg-evvy-black bg-opacity-90 transition-opacity ${
            closeModal ? "cursor-pointer" : ""
          }`}
          aria-hidden="true"
          onClick={closeModal ? () => closeModal() : null}
        ></div>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block align-middle h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div
          className={`inline-block max-w-screen-lg my-8  bg-white text-left ${
            allowOverflow ? "" : "overflow-hidden"
          } shadow-xl transform transition-all align-middle rounded-lg ${widthClass}
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );

  // slides in from the bottom and is dependent on height of modal contents
  const mobileContent = (
    <div
      className="fixed z-20 inset-0 overflow-y-auto hide-scrollbar"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="min-h-screen text-center flex flex-col justify-end  pt-20">
        <div
          className={`fixed inset-0 bg-evvy-black bg-opacity-90 transition-opacity ${
            closeModal ? "cursor-pointer" : ""
          }`}
          aria-hidden="true"
          onClick={closeModal ? () => closeModal() : null}
        ></div>
        <div
          className={`relative duration-500 inline-block w-full max-w-full bg-white text-left overflow-hidden shadow-xl transform transition-all align-middle rounded-t-3xl`}
          style={{ top: mobileModalLoaded ? "0" : `${window.innerHeight}px` }} // handle animation in pixels b/c tailwind classes don't cover it
        >
          {children}
        </div>
      </div>
    </div>
  );

  const renderContent = isMobile ? mobileContent : webContent;

  // only wrap focus trap if modal has a focusable element within it, otherwise it will throw an error
  return (
    <>
      {hasFocusItem ? (
        <FocusTrap
          focusTrapOptions={{
            initialFocus: false,
          }}
        >
          {renderContent}
        </FocusTrap>
      ) : (
        renderContent
      )}
    </>
  );
};

export default Modal;
