import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
// hooks
import { useLatestReadyTest } from "../../../hooks/test/useLatestReadyTest";
import { useProblemsSummary } from "../../../hooks/care/useProblemsSummary";
import { useALaCareRecommendations } from "../../../hooks/care/useALaCareRecommendations";
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";
import useSubscription from "../../../hooks/useSubscription";
// utils
import { formatDate, strDateToTimestamp } from "../../../utils/datetime";
import { PDP_URL } from "../../constants";
import { subscriptionService } from "../../../services/subscription";
import { setMoveTestUpError } from "../../plan/planSlice";
// types
import { Test, TestTrendSummary } from "../../../types/test";
import {
  ALaCareRecommendations,
  Consult,
  Prescription,
  TreatmentPathway,
} from "../../../types/care";
import { Subscription } from "../../../types/subscription";

// components
import Layout from "../../../components/layout";
import { Bullets } from "../../../components/common/bullets";
import { BackButton } from "../../../components/common/backButton";
import ProblemsModule from "../../../components/tests/problemsModule";
import PillsImg from "../../../components/common/images/treatments/evvy-pill-bottle.png";

// icons
import { ReactComponent as AlertTriangle } from "../../../components/common/icons/alert-triangle.svg";
import { EvvyMarkdown } from "../../../components/common/evvyMarkdown";
import { ReactComponent as Checkmark } from "../../../components/common/icons/checkmark.svg";
import { CircleCheckmark } from "../../../components/common/circleCheckmark";
import RectangularButton from "../../../components/common/rectangularButton";
import Modal from "../../../components/common/modal";
import { ProblemsModuleExpandedModal } from "../../../components/plan_unfurled/modalTemplates/problemsModuleExpandedModal";
import NextTestContentTemplate from "../../../components/plan_unfurled/modalTemplates/nextTest";
import CareFAQs from "../../../components/care/careFAQs";
import HowItWorks from "../../../components/care/howItWorks";
import LoadingSpinner from "../../../components/common/loadingSpinner";
import CareFullTreatmentDiagram from "../../../components/care/images/care-full-treatment-diagram.png";
import AdditionalCareOptions from "../../../components/care/additionalCareOptions";
import {
  getTreatmentImageFromFormFactor,
  getTreatmentPurposeTagColor,
} from "../../../components/care/utils";
import { TreatmentUseTag } from "../../../components/care/aLaCare/treatmentUseTag";
import { useALaCareStickyCart } from "../../../components/care/aLaCare/cart";
import { CartItem, useCart } from "../../../contexts/aLaCareCartContext";
import {
  sendShopTreatmentsClickedRetestOnramp,
  sendShopTreatmentsClickedTreatment,
  sendShopTreatmentsClickedTreatmentProgramOnramp,
  sendViewedShopTreatmentsPage,
} from "../../../utils/analytics/customEventTracking";
import { BaseAnalyticsArgsContext } from "../../../contexts/baseAnalyticsArgsContext";
import { useBaseAnalyticsArgs } from "../../../hooks/useBaseAnalyticsArgs";

const A_LA_CARE_BULLETS = [
  "Pure, prescription-grade formulations",
  "Based on the latest microbiome science",
  "Free shipping directly to you",
];

const PAGE_WIDTH = "max-w-7xl";

// TODO: should only be available for people who are  eligible and haven't already purchased care for their test
export const ShopTreatmentsPage = ({
  completedConsults,
}: {
  completedConsults: Consult[];
}) => {
  // fetch latestReadyTest
  // and then fetch a la care recommendations using hook
  const navigate = useNavigate();
  const { test } = useLatestReadyTest();
  const { testSummary, isLoading: problemsSummaryLoading } = useProblemsSummary(
    test?.hash
  );
  const { aLaCareRecommendations, isLoading: aLaCareLoading } =
    useALaCareRecommendations(test?.hash);

  // if the user is not eligible for a la care, redirect to care page
  if (
    aLaCareRecommendations?.a_la_care.eligible === false ||
    aLaCareRecommendations?.a_la_care.all_treatments?.length === 0
  ) {
    navigate("/care/");
  }

  const currentUser = useLoggedInUser();
  const { subscription, refetchSubscription } = useSubscription();
  const { state: cartState } = useCart();
  const loading = problemsSummaryLoading || aLaCareLoading;
  const latestVaginitisConsult = test?.latest_vaginitis_consult;
  const alreadyPaid = latestVaginitisConsult?.consult_paid;
  // state
  const [modalData, setModalData] = useState<{ type: string; data: any }>();

  const mayBePregnant =
    testSummary?.problems_summary.context.includes("Trying to conceive") ||
    false;
  const hasPastConsults = completedConsults?.length > 0;

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const baseAnalyticsArgs = useMemo(() => {
    return {
      testHash: test?.hash,
      consultId: test?.latest_vaginitis_consult?.uid,
      careEligible: aLaCareRecommendations?.v0_bundle.eligible,
      eligibleTreatmentPathways: test?.eligible_treatment_pathways?.map(
        (pathway) => pathway.slug
      ),
      microbiomeState: testSummary?.problems_summary.microbiome_state,
      conditions: testSummary?.problems_summary.conditions,
      symptoms: testSummary?.problems_summary.symptoms,
      aLaCareEligible: aLaCareRecommendations?.a_la_care.eligible,
      recommendedALaCareTreatments:
        aLaCareRecommendations?.a_la_care.recommended_treatments.map(
          (treatment) => treatment.slug
        ),
      selectedTreatments: cartState?.items.map((item) => item.id),
    };
  }, [test, testSummary, aLaCareRecommendations, cartState]);

  const {
    cartComponent: ALaCart,
    addToCart,
    removeFromCart,
  } = useALaCareStickyCart({ test });

  // analytics
  const recordShopTreatmentsPageVisited = (test: Test) => {
    const pageViewKey = `visited-shop-treatments-${test.hash}`;
    if (!localStorage.getItem(pageViewKey)) {
      localStorage.setItem(pageViewKey, "true");
    }
  };

  useEffect(() => {
    // send analytics event, only fire on first render
    if (test && testSummary && aLaCareRecommendations) {
      sendViewedShopTreatmentsPage(baseAnalyticsArgs);
      recordShopTreatmentsPageVisited(test);
    }
  }, [test, testSummary, aLaCareRecommendations]);

  return (
    <BaseAnalyticsArgsContext.Provider value={{ baseAnalyticsArgs }}>
      <Layout title={"Care | Shop"} bgClass="bg-evvy-white" full centered>
        <div className="w-full items-center">
          {/* Intro section */}
          <IntroSection
            hasPastConsults={hasPastConsults}
            eligibleForTreatmentProgram={
              aLaCareRecommendations?.v0_bundle.eligible || false
            }
            loading={loading}
          />

          {/* loading */}
          <div
            className={classNames("flex justify-center min-h-screen", {
              hidden: !loading,
            })}
          >
            <LoadingSpinner />
          </div>

          {/* Recommended Treatments section */}
          {testSummary && aLaCareRecommendations && (
            <RecommendedTreatmentsSection
              testSummary={testSummary}
              aLaCareRecommendations={aLaCareRecommendations}
              openProblemsModal={() =>
                setModalData({ type: "problems", data: testSummary })
              }
              mayBePregnant={mayBePregnant}
              addToCart={addToCart}
              removeFromCart={removeFromCart}
              alreadyPaid={alreadyPaid}
            />
          )}
          {/* On ramp to treatment program if eligible for v0 bundle */}
          {aLaCareRecommendations?.v0_bundle.eligible && (
            <OnRampToTreatmentProgram
              eligibleTreatmentPathways={
                aLaCareRecommendations.v0_bundle.eligible_treatment_pathways
              }
            />
          )}

          {testSummary && aLaCareRecommendations && (
            <MoreProductsSection
              aLaCareRecommendations={aLaCareRecommendations}
              mayBePregnant={mayBePregnant}
              addToCart={addToCart}
              removeFromCart={removeFromCart}
              alreadyPaid={alreadyPaid}
            />
          )}

          {/* only shown for treatment program ineligible */}
          {currentUser &&
            aLaCareRecommendations &&
            !aLaCareRecommendations?.v0_bundle.eligible && (
              <>
                <OnRampToRetest
                  isMember={currentUser.subscription.isMember}
                  subscription={subscription}
                  openMoveNextOrderModal={() =>
                    setModalData({ type: "reorder-test", data: {} })
                  }
                />
                <div className="pb-8 sm:pb-16 px-4 sm:px-0">
                  <HowItWorks careType="a_la_care" />
                </div>
                <ALaCareValueProps />
              </>
            )}

          <CareFAQs
            consult={latestVaginitisConsult}
            aLaCareEligible={aLaCareRecommendations?.a_la_care.eligible}
            v0BundleEligible={aLaCareRecommendations?.v0_bundle.eligible}
            analyticsEventArgs={{}}
            faqsPage={"shop-treatments"}
          />

          {/* only shown for treatment program ineligible */}
          {currentUser &&
            aLaCareRecommendations &&
            !aLaCareRecommendations?.v0_bundle.eligible && (
              <div className="bg-evvy-cream py-20">
                <AdditionalCareOptions
                  consult={latestVaginitisConsult}
                  // TODO: add analytics events
                  analyticsEventArgs={{}}
                />
              </div>
            )}
        </div>
      </Layout>
      {modalData && (
        <Modal
          closeModal={() => setModalData(undefined)}
          widthClass={
            modalData.type === "problems"
              ? "w-plan-column-md"
              : "w-plan-column-sm"
          }
          hasFocusItem
        >
          {modalData.type === "problems" ? (
            <ProblemsModuleExpandedModal
              data={{ testSummary: modalData.data }}
              handleClose={() => setModalData(undefined)}
            />
          ) : modalData.type === "reorder-test" ? (
            <NextTestContentTemplate
              handleClose={() => setModalData(undefined)}
              subscription={subscription}
              handleSubmit={() => {
                subscriptionService.triggerNextCharge(
                  (response: any) => {
                    refetchSubscription();
                  },
                  (error: any) => dispatch(setMoveTestUpError(true))
                );
              }}
            />
          ) : (
            <div></div>
          )}
        </Modal>
      )}
      {/* cart component */}
      {ALaCart}
    </BaseAnalyticsArgsContext.Provider>
  );
};

const IntroSection = ({
  hasPastConsults,
  eligibleForTreatmentProgram,
  loading,
}: {
  hasPastConsults: boolean;
  eligibleForTreatmentProgram: boolean;
  loading: boolean;
}) => {
  const header = "Individual Rx Products";

  return (
    <div className={`${PAGE_WIDTH} mx-auto px-4 sm:px-0`}>
      {eligibleForTreatmentProgram && (
        <div className="flex justify-start">
          <BackButton backLink="/care/" backText="back" />
        </div>
      )}
      <div className="sm:pt-12 sm:pb-0 space-y-7 sm:space-y-4">
        <h1 className="hidden sm:block">{header}</h1>
        <h2 className="sm:hidden">{header}</h2>
        <div className="sm:flex sm:items-start sm:justify-between">
          <Bullets
            bullets={A_LA_CARE_BULLETS}
            className="sm:flex sm:space-x-5 pb-2 sm:pb-0 medium"
          />
          {!loading && !eligibleForTreatmentProgram && hasPastConsults && (
            <div className="mt-2.5">
              <Link
                to="/care/past-treatments/"
                className="linkedText underline caption medium border-b border-evvy-black cursor-pointer w-max items-center"
                onClick={() => {}} // TODO: analytics events
              >
                View past treatment programs
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const RecommendedTreatmentsSection = ({
  testSummary,
  aLaCareRecommendations,
  openProblemsModal,
  mayBePregnant,
  addToCart,
  removeFromCart,
  alreadyPaid,
}: {
  testSummary: TestTrendSummary;
  aLaCareRecommendations: ALaCareRecommendations;
  openProblemsModal: () => void;
  mayBePregnant: boolean;
  addToCart: (item: CartItem) => void;
  removeFromCart: (item: CartItem) => void;
  alreadyPaid?: boolean;
}) => {
  const problemsSummary = testSummary.problems_summary;
  const showDisclaimer = problemsSummary.microbiome_state === "disrupted";
  const eligibleForTreatmentProgram = aLaCareRecommendations.v0_bundle.eligible;
  const eligibleForAntibioticsOrAntifungalTreatment =
    aLaCareRecommendations.v0_bundle.eligible_treatment_pathways.some(
      (pathway) =>
        pathway.primary_treatment === "antibiotic" ||
        pathway.primary_treatment === "antifungal"
    );

  // If they are not eligible for a treatment program that offers antibiotics or antifungals through Evvy:
  // direct them to their provider instead of the Treatment Program (this should be an edge case)
  const disclaimerCopy = `Your symptoms and results may be consistent with an infection. The Rx products below are designed to relieve symptoms and cannot treat an active infection.${
    eligibleForTreatmentProgram && eligibleForAntibioticsOrAntifungalTreatment
      ? " We recommend you seek treatment (e.g. antibiotic and/or antifungal) through the [Evvy Complete Treatment Program.](https://my.evvy.com/care) or from your provider."
      : " We recommend you seek treatment (e.g. antibiotic and/or antifungal) from your provider."
  }`;

  const onlyProbioticsRecommended =
    aLaCareRecommendations.a_la_care.recommended_treatments.every(
      (treatment) => treatment.type == "probiotic"
    );

  let goalCopy = "";
  if (
    problemsSummary.microbiome_state === "healthy" &&
    onlyProbioticsRecommended
  ) {
    goalCopy = "Maintain a healthy vaginal microbiome";
  } else if (
    problemsSummary.microbiome_state === "suboptimal" &&
    onlyProbioticsRecommended
  ) {
    goalCopy = "Build a healthy vaginal microbiome";
  } else {
    goalCopy = "Soothe your symptoms";
  }

  const subtitle =
    "The below Rx products are often prescribed to people with similar test results and symptoms. Prescriptions are determined at the sole discretion of an Evvy-affiliated provider based on your results, health profile, and clinical intake. Please note: once you check out, you will not be able to request additional treatments until after you re-test.";

  return (
    <div className="bg-evvy-cream w-full">
      <div className={`${PAGE_WIDTH} mx-auto py-7 px-4 sm:px-0 sm:py-14`}>
        <div className="sm:flex sm:justify-between">
          <div className="space-y-4 max-w-2xl sm:pr-4 sm:flex sm:flex-col">
            {showDisclaimer && (
              <div className="bg-highlighter-salmon/70 p-4 rounded-lg space-y-2 order-1 sm:order-3 sm:mt-4">
                <div className="flex my-auto items-center">
                  <AlertTriangle className="stroke-evvy-black h-4 w-5 mr-1" />
                  <div className="t4">Attention</div>
                </div>
                <div className="b2 medium">
                  <EvvyMarkdown>{disclaimerCopy}</EvvyMarkdown>
                </div>
              </div>
            )}
            <div className="sm:hidden order-2 ">
              <ProblemsModule
                testSummary={testSummary}
                showBackgroundContainer
                openModal={openProblemsModal}
              />
            </div>
            <h3 className="order-3 sm:order-1 sm:text-5xl">{goalCopy}</h3>
            <div className="regular order-4 sm:order-2">
              <div className="b1 hidden sm:block">{subtitle}</div>
              <div className="b2 sm:hidden">{subtitle}</div>
            </div>
          </div>
          <div className="max-w-lg hidden sm:block lg:min-w-[448px]">
            <ProblemsModule
              testSummary={testSummary}
              showBackgroundContainer
              openModal={openProblemsModal}
            />
          </div>
        </div>
        {/* add treatment card for every recommended treatment */}
        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-10 py-10">
          {aLaCareRecommendations.a_la_care.recommended_treatments.map(
            (treatment, index) => (
              <TreatmentCard
                key={index}
                treatment={treatment}
                mayBePregnant={mayBePregnant}
                addToCart={addToCart}
                disabled={alreadyPaid}
                removeFromCart={removeFromCart}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

const MoreProductsSection = ({
  aLaCareRecommendations,
  mayBePregnant,
  addToCart,
  alreadyPaid,
  removeFromCart,
}: {
  aLaCareRecommendations: ALaCareRecommendations;
  mayBePregnant: boolean;
  addToCart: (item: CartItem) => void;
  removeFromCart: (item: CartItem) => void;
  alreadyPaid?: boolean;
}) => {
  // get remaining treatments (if any), filter out any that have matching slug
  const remainingTreatments =
    aLaCareRecommendations.a_la_care.all_treatments.filter(
      (treatment) =>
        !aLaCareRecommendations.a_la_care.recommended_treatments.some(
          (recommendedTreatment) => recommendedTreatment.slug === treatment.slug
        )
    );

  if (remainingTreatments.length === 0) {
    return <></>;
  }

  return (
    <div className={`${PAGE_WIDTH} mx-auto pt-7 sm:py-10 px-4 sm:px-0`}>
      <h3>More Products</h3>
      <div className="b1 sm:max-w-3xl">
        Your specific prescriptions will be determined at the sole discretion of
        an Evvy-affiliated provider based on your results, health profile, and
        clinical intake.
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-10 py-10">
        {remainingTreatments.map((treatment, index) => (
          <TreatmentCard
            key={index}
            treatment={treatment}
            mayBePregnant={mayBePregnant}
            addToCart={addToCart}
            removeFromCart={removeFromCart}
            disabled={alreadyPaid}
          />
        ))}
      </div>
    </div>
  );
};

export const PURPOSE_TAG_TO_BG_COLOR = {
  rebuild: "bg-evvy-blue",
  relief: "bg-highlighter-mint",
  treat: "bg-highlighter-salmon",
};

const FORM_FACTOR_TO_IMAGE = {
  pill: {
    image: getTreatmentImageFromFormFactor("pill"),
    sizeClassName: "h-36",
  },
  cream: {
    image: getTreatmentImageFromFormFactor("cream"),
    sizeClassName: "h-40 sm:h-48 absolute bottom-0",
  }, // TODO: replace with actual image later
  "applicator-cream": {
    image: getTreatmentImageFromFormFactor("applicator-cream"),
    sizeClassName: "h-40 sm:h-48 absolute bottom-0",
  },
  suppository: {
    image: getTreatmentImageFromFormFactor("suppository"),
    sizeClassName: "w-56",
  },
};

const TreatmentCard = ({
  treatment,
  mayBePregnant,
  addToCart,
  removeFromCart,
  disabled,
}: {
  treatment: Prescription;
  mayBePregnant: boolean;
  addToCart: (item: CartItem) => void;
  removeFromCart: (item: CartItem) => void;
  disabled?: boolean;
}) => {
  const { form_factor } = treatment;
  const navigate = useNavigate();
  const { state, dispatch } = useCart();
  const inCart = state.items.some((item) => item.id === treatment.slug);
  const baseAnalyticsArgs = useBaseAnalyticsArgs();

  const image = form_factor
    ? FORM_FACTOR_TO_IMAGE[form_factor].image
    : PillsImg;
  const imageSizeClassName = form_factor
    ? FORM_FACTOR_TO_IMAGE[form_factor].sizeClassName
    : "w-32";
  const purposeTagBGColor = getTreatmentPurposeTagColor(treatment.purpose_tag);

  // Add to cart functionality
  const handleAddToCartClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    addToCart({
      id: treatment.slug,
      name: treatment.title_short_display,
      price: treatment.ecomm_product.price,
      quantity: 1,
    });
  };

  // Remove from cart functionality
  const handleRemoveFromCartClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    removeFromCart({
      id: treatment.slug,
      name: treatment.title_short_display,
      price: treatment.ecomm_product.price,
      quantity: 1,
    });
  };

  return (
    <div
      className="rounded-lg w-full sm:w-72 h-[25rem] sm:h-[29rem] bg-evvy-white border border-evvy-dark-beige cursor-pointer"
      // onClick navigate to treatment PDP
      onClick={() => {
        navigate(`/care/shop/treatment/${treatment.slug}/`);
        // change scroll back to top of page
        window.scrollTo(0, 0);
        // fire analytics event
        sendShopTreatmentsClickedTreatment({
          ...baseAnalyticsArgs,
          treatmentSlug: treatment.slug,
        });
      }}
    >
      <div className="bg-evvy-dark-beige rounded-t-lg justify-center items-center h-1/2 flex relative">
        <img
          src={image}
          loading="lazy"
          alt="Care packaging"
          className={`object-cover ${imageSizeClassName} mx-auto my-auto`}
        />
        {/* add treatment.use_tag text rectangle to bottom left of dark cream div */}
        {treatment.purpose_tag && (
          <div
            className={`absolute bottom-2 left-2 rounded-md ${purposeTagBGColor} p-2`}
          >
            <span className="text-sm uppercase tracking-wider semibold">
              {treatment.purpose_tag}
            </span>
          </div>
        )}
      </div>
      <div className="p-3.5 relative h-1/2">
        <div className="t3">{treatment.title_short_display}</div>
        <div className="b1 regular">
          {treatment.subtitle_text
            ? treatment.subtitle_text
            : treatment.quantity_text}
        </div>
        {mayBePregnant && treatment.do_not_take_if_pregnant ? (
          <div className="pt-4">
            <AlertTriangle className="stroke-evvy-black h-5 inline-block my-auto" />
            <span className="b2 medium">
              Not safe to take if you are pregnant
            </span>
          </div>
        ) : (
          treatment.use_tag && (
            <TreatmentUseTag treatment={treatment} className="pt-4" />
          )
        )}
        {/* add button */}
        <button
          disabled={disabled}
          className={classNames(
            "rounded-md mx-auto border px-4 py-3.5 flex justify-between absolute bottom-3.5 right-3.5 left-3.5 hover:bg-evvy-blue transition-colors duration-100",
            {
              "border-evvy-black": inCart,
              "border-evvy-dark-beige": !inCart,
            }
          )}
          onClick={inCart ? handleRemoveFromCartClick : handleAddToCartClick}
        >
          <div className="t4">
            {inCart ? (
              <span className="flex my-auto">
                <Checkmark className="h-5 w-5 mr-1" />
                Added
              </span>
            ) : (
              "Add"
            )}
          </div>
          <div className="b2 semibold text-right">
            ${treatment.ecomm_product.price.toString().split(".")[0]}
          </div>
        </button>
      </div>
    </div>
  );
};

const TREATMENT_PROGRAM_VALUE_PROPS = {
  probioticsOnly: [
    "Can include probiotics to build a healthy microbiome, plus treatments to soothe symptoms",
    "7-week treatment program developed for you by an Evvy-affiliated provider, personalized to your results, symptoms, & health history",
    "Personalized treatment calendar and 1:1 onboarding call with a health coach",
  ],
  treatmentProgram: [
    "Can include antibiotics & antifungals to treat infections, plus Rx products to soothe symptoms and rebuild your microbiome",
    "2 month treatment program developed for you by an Evvy-affiliated provider, personalized to your results, symptoms, & health history",
    "Personalized treatment calendar and 1:1 onboarding call with a health coach",
  ],
  maintenanceProgram: [
    "Can include probiotics to build a healthy microbiome, plus treatments to soothe symptoms",
    "3 month treatment program developed for you by an Evvy-affiliated provider, personalized to your results, symptoms, & health history",
    "Continuous, async support included",
  ],
};

const OnRampToTreatmentProgram = ({
  eligibleTreatmentPathways,
}: {
  eligibleTreatmentPathways: TreatmentPathway[];
}) => {
  const hasTreatmentProgram = eligibleTreatmentPathways.some(
    (pathway) =>
      pathway.type === "treatment" && pathway.primary_treatment !== "probiotic"
  );
  const hasProbioticsOnly = eligibleTreatmentPathways.some(
    (pathway) => pathway.slug === "probiotics-only"
  );

  const header = hasTreatmentProgram
    ? "Evvy Complete Treatment Program"
    : hasProbioticsOnly
    ? "Evvy Probiotics Program"
    : "Evvy Maintenance Program";

  const valueProps = hasTreatmentProgram
    ? TREATMENT_PROGRAM_VALUE_PROPS.treatmentProgram
    : hasProbioticsOnly
    ? TREATMENT_PROGRAM_VALUE_PROPS.probioticsOnly
    : TREATMENT_PROGRAM_VALUE_PROPS.maintenanceProgram;

  const imageTagCopy = hasTreatmentProgram
    ? "Recommended if you’re fighting an infection"
    : "Custom tailored to you";

  const navigate = useNavigate();

  const baseAnalyticsArgs = useBaseAnalyticsArgs();

  return (
    <div
      className={`${PAGE_WIDTH} mx-auto py-10 px-4 sm:px-0 sm:py-14 grid grid-cols-1 sm:grid-cols-7`}
    >
      <div className="sm:col-span-3 relative">
        <div className="absolute top-2 left-2 bg-evvy-white px-1 py-0.5 rounded-full flex">
          <CircleCheckmark
            className="h-4 w-4 mr-1"
            circleClassName="stroke-evvy-black stoke-3"
            checkmarkClassName="stroke-evvy-black"
          />
          <span className="b3 medium">{imageTagCopy}</span>
        </div>
        <img
          src={"/images/graphics/care-packaging-tile.png"}
          loading="lazy"
          alt="Care Packaging"
          className="rounded-t-xl sm:rounded-tr-none sm:rounded-l-xl"
        />
      </div>
      <div
        className="sm:col-span-4 bg-cover bg-no-repeat rounded-b-xl sm:rounded-bl-none sm:rounded-r-xl px-5 sm:px-14 py-7 sm:py-10 space-y-5 sm:space-y-7"
        style={{
          backgroundImage: "url('/images/graphics/blue-green-gradient.png')",
        }}
      >
        <h3>{header}</h3>
        <Bullets bullets={valueProps} />
        <RectangularButton
          text="Learn More"
          bgColorClass="bg-transparent"
          textColorClass="text-evvy-black"
          borderColor="border-evvy-black"
          verticalPadding="py-5"
          horizontalPadding="px-16 sm:px-28"
          onClick={() => {
            sendShopTreatmentsClickedTreatmentProgramOnramp(baseAnalyticsArgs);
            navigate("/care/");
          }}
        />
      </div>
    </div>
  );
};

const RETEST_VALUE_PROPS = [
  "Our at-home vaginal microbiome test uncovers 700+ bacteria & fungi with one swab",
  "Free results call with a health coach",
  "If eligible, get a personalized treatment program designed for you by an Evvy-affiliated provider",
];

const OnRampToRetest = ({
  isMember,
  subscription,
  openMoveNextOrderModal,
}: {
  isMember: boolean;
  subscription?: Subscription;
  openMoveNextOrderModal: () => void;
}) => {
  const header = isMember ? "Your Evvy Membership" : "Re-test with Evvy";
  const baseAnalyticsArgs = useBaseAnalyticsArgs();

  return (
    <div className={`${PAGE_WIDTH} mx-auto py-14 px-4 sm:px-0`}>
      <div
        className="grid grid-cols-1 sm:grid-cols-2 bg-cover bg-no-repeat gap-8 rounded-xl pt-6 sm:pt-0"
        style={{
          backgroundImage: "url('/images/graphics/blue-green-gradient.png')",
        }}
      >
        <div className="px-4 sm:pr-0 sm:pl-10 flex">
          <div className="my-auto">
            <h3 className="sm:text-5xl">{header}</h3>
            <div className="b1 medium">
              Re-test with Evvy to ensure you’re in control of your vaginal
              health, with access to antifungals and antibiotics when
              appropriate.
            </div>
          </div>
        </div>
        <div className="py-5 px-5">
          <div className="bg-evvy-white rounded-lg">
            {isMember && subscription && subscription.next_charge_date ? (
              <NextSubscriptionOrderDateCard
                openModal={openMoveNextOrderModal}
                subscription={subscription}
              />
            ) : (
              <div className="p-7 space-y-7">
                <h3 className="text-dv-pond sm:text-5xl">From $99</h3>
                <Bullets bullets={RETEST_VALUE_PROPS} />
                <RectangularButton
                  text="Shop the test ->"
                  textColorClass="text-evvy-black"
                  verticalPadding="py-6"
                  fullWidth
                  onClick={() => {
                    sendShopTreatmentsClickedRetestOnramp(baseAnalyticsArgs);
                    window.open(PDP_URL, "_blank");
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const NextSubscriptionOrderDateCard = ({
  openModal,
  subscription,
}: {
  openModal: () => void;
  subscription: Subscription;
}) => {
  const baseAnalyticsArgs = useBaseAnalyticsArgs();

  const handleClick = () => {
    openModal();
    // fire analytics event
    sendShopTreatmentsClickedRetestOnramp(baseAnalyticsArgs);
  };

  return (
    <div className="p-7">
      <div className="t4">Next order date:</div>
      <h2 className="text-dv-pond pt-1 pb-3">
        {formatDate(strDateToTimestamp(subscription.next_charge_date))}
      </h2>
      <RectangularButton
        text="Order now instead ->"
        textColorClass="text-evvy-black"
        verticalPadding="py-6"
        fullWidth
        onClick={handleClick}
      />
    </div>
  );
};

const A_LA_CARE_VALUE_TITLE =
  "Innovative prescription products designed for your unique microbiome";

const A_LA_CARE_VALUE_BULLETS = [
  "Prescription-only and pharmaceutical-grade products that are designed to soothe symptoms and regrow protective bacteria strains",
  "Compounded with supplements like lactoferrin and Vitamin D, proven to further support the regrowth of protective bacteria",
  "Where possible, products are administered vaginally, ensuring fast and effective delivery of the right medications",
];

export const ALaCareValueProps = () => {
  return (
    <div className="p-8 bg-evvy-dark-cream">
      <div className="flex md:p-8 mx-auto max-w-7xl space-x-32  min-h-[670px]">
        <div className="md:mt-20">
          <h4 className="md:hidden mt-4 text-[22px]">
            {A_LA_CARE_VALUE_TITLE}
          </h4>
          <h3 className="hidden md:block md:max-w-lg md:mx-auto pb-4">
            {A_LA_CARE_VALUE_TITLE}
          </h3>
          <img
            src={CareFullTreatmentDiagram}
            alt="Care probiotics diagram"
            className=" md:col-span-2 md:max-w-2xl object-contain md:m-auto md:hidden pb-4"
          />
          <Bullets bullets={A_LA_CARE_VALUE_BULLETS} />
        </div>
        <img
          src={CareFullTreatmentDiagram}
          alt="Care probiotics diagram"
          className="md:col-span-2 md:max-w-2xl object-contain md:m-auto h-full hidden md:block"
        />
      </div>
    </div>
  );
};
