import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { ReactComponent as Heart } from "../../../components/care/icons/heart.svg";
import { ReactComponent as FirstAidKit } from "../../../components/care/icons/first-aid-kit.svg";
import { ReactComponent as Pills } from "../../../components/care/icons/pills.svg";
import { sendConsultIntakeViewSection } from "../../../utils/analytics/customEventTracking";
import { facebookSupportGroupLink } from "../../constants";

const IntakeDone = ({ consultIntake }) => {
  /* Effects */
  // First render
  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consultIntake.consult.uid,
      testHash: consultIntake?.consult?.test_hash,
      section: "thank you",
    });
  }, []);

  return (
    <div className="block w-full mt-8 sm:mt-10 mb-4">
      <Helmet>
        <title>Care | Consult | Intake | Done</title>
      </Helmet>
      <div className="max-w-2xl px-4">
        <h3 className="flex-1 mr-2 text-center">You’re done!</h3>
        <div className="flex-1 pt-8 pb-4 px-6 sm:px-8 bg-evvy-white rounded-t-lg">
          <div className="uppercase mb-5 inline-block font-semibold tracking-wider text-sm">
            What’s next
          </div>

          <div className="flex flex-row items-center mb-4">
            <div className="w-6 h-6 ml-1 mr-4 flex-shrink-0 items-center justify-center">
              <FirstAidKit />
            </div>
            <span className="leading-relaxed">
              An Evvy-affiliated provider will review your request, typically
              within 4-8 business hours
            </span>
          </div>

          <div className="flex flex-row items-center mb-4">
            <div className="w-8 h-8 mr-3 flex-shrink-0 items-center justify-center">
              <Pills />
            </div>
            <span>
              Any treatments prescribed will be shipped to you directly
            </span>
          </div>

          <div className="flex flex-row items-center">
            <div className="w-8 h-8 mr-3 flex items-center justify-center">
              <Heart />
            </div>
            <span>
              In the meantime,{" "}
              <a
                href={facebookSupportGroupLink}
                target="_blank"
                className="underline persistSize"
                rel="noreferrer"
              >
                join the Evvy Support Community here
              </a>
              .
            </span>
          </div>
        </div>

        <div>
          <img
            className="w-full"
            src="/images/graphics/triple-science-blue.png"
            loading="lazy"
            alt="Evvy Microbiome Test"
          />
        </div>

        <Link to="/care/" className="flex pt-3 items-center mt-3">
          <button className="tracking-wider w-full sm:w-auto sm:mx-auto py-7 px-16 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none">
            Return to care →
          </button>
        </Link>
      </div>
    </div>
  );
};
export default IntakeDone;
