import { NavigateFunction } from "react-router-dom";
import { careService } from "../../services/care";
import {
  Consult,
  Prescription,
  PrescriptionTag,
  TreatmentFormFactor,
  TreatmentPhase,
  TreatmentPurposeTag,
} from "../../types/care";
import { Test } from "../../types/test";
import { GENERAL_COACHING_CALL_LINK } from "../../pages/constants";
import {
  sendClickedCareCTA,
  sendClickedSTIIntakeCTA,
} from "../../utils/analytics/customEventTracking";
import SuppositoriesImg from "../common/images/treatments/evvy-suppositories.png";
import PillsImg from "../common/images/treatments/evvy-pill-bottle.png";
import ApplicatorCreamImg from "../common/images/treatments/evvy-cream-applicator.png";
import CreamImg from "../common/images/treatments/evvy-cream.png";
import axios from "axios";
import { CartItem } from "../../contexts/aLaCareCartContext";
import { CARE_INTAKE_STATIC_PATH, VIEW_RX_PRODUCTS } from "./constants";

const DISRUPTIVE_DESCRIPTION = "Reduce disruptive bacteria";
const PROTECTIVE_DESCRIPTION = "Promote protective bacteria";
const MAINTENANCE_DESCRIPTION = "Maintain & soothe symptoms";

const SHOP_TREATMENTS_URL = `/care/shop/treatment`;

export const getButtonCTATextForConsultation = (
  consult: Consult,
  showRecommendedPlan: boolean = false
): string => {
  if (!consult) return `Get started →`;
  if (consult.status === "CP")
    return consult.purchase_type === "a-la-care"
      ? VIEW_RX_PRODUCTS
      : "View treatment program →";
  if (consult.consult_pending) return "View consultation status →";
  if (consult.consult_paid) return "Complete intake →";

  if (showRecommendedPlan && consult?.has_recommended_treatment_plan) {
    return "View sample program →";
  }
  // catch all
  return `Get started →`;
};

const openCoachingLink = () => {
  window.location.href = GENERAL_COACHING_CALL_LINK;
};

// This is meant to handle v0-bundle and care ineligible care intro clicks.
// Not meant for use for a la care consults
export const handleCareIntroCTAClick = (
  test: Test,
  consult: Consult,
  removeAntibioticsSelected: boolean,
  navigate: NavigateFunction,
  ctaText: string,
  location: string,
  selectedPathwaySlug?: string
) => {
  // if ineligible for care, then take them to coaching
  sendClickedCareCTA({
    location,
    ctaText,
    testHash: test.hash,
    testOrder: test.test_order,
    carePrice: test.eligible_for_care
      ? test.care_pricing?.discounted_total
      : null,
    careEligible: test.eligible_for_care,
    valenciaType: test.valencia_type,
    treatmentPathway: selectedPathwaySlug,
  });

  if (!test.eligible_for_care) {
    return openCoachingLink();
  }

  // If they don't have a consult, then create one
  if (!consult) {
    careService.createConsult(
      {
        hash: test.hash,
        remove_antibiotics_selected: removeAntibioticsSelected,
        treatment_pathway_slug: selectedPathwaySlug,
        type: "vaginitis",
        purchase_type: "v0-bundle",
      },
      (response: any) => {
        const { uid } = response.data || {};
        navigate(`/care/consults/${uid}/intake/intro/`, {
          state: { removeAntibioticsSelected },
        });
      },
      (error: any) => {
        console.error(error);
        // TODO: display error message to user that error creating consult
      }
    );
  } else {
    // If the consult is complete or pending, navigate appropriately
    if (consult.status === "CP") {
      navigate(getTreatmentPlanUrlFromConsult(consult));
    } else if (consult.consult_pending) {
      navigate("/care/");
    } else if (consult.consult_paid) {
      // If they have an incomplete consult, then take them to the intake
      navigate(CARE_INTAKE_STATIC_PATH);
    } else if (consult.has_recommended_treatment_plan) {
      // if they have a recommended treatment plan, take them to it
      navigate(`/care/consults/${consult.uid}/recommended-treatment-plan/`);
    } else {
      // if treatment pathway slug is specified and it's different, then update it
      if (
        selectedPathwaySlug &&
        selectedPathwaySlug !== consult.treatment_pathway?.slug
      ) {
        careService.updateConsultTreatmentPathway(
          consult.uid,
          selectedPathwaySlug
        );
      }
      // Otherise, make sure the purchase type is set correctly to v0-bundle
      careService
        .updateConsultPurchaseType(consult.uid, "v0-bundle")
        .then(() => {
          navigate(`/care/consults/${consult.uid}/intake/intro/`);
        })
        .catch((error: any) => {
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 404) {
              console.error(
                "Consult exists but no intake, will create intake on next page"
              );
              // 404 means we don't have an intake, will be created on intake page, can continue
              navigate(`/care/consults/${consult.uid}/intake/intro/`);
            }
          }
        });
    }
  }
};

export const getPhasesFromPrescriptions = (
  prescriptions: Prescription[],
  includeMaintenance: boolean = false
) => {
  const phases: TreatmentPhase[] = [];

  prescriptions.forEach((prescription) => {
    // skip if no tag
    if (!prescription.tag) {
      return;
    }

    // skip this prescription if we already have this phase
    if (phases.map((p) => p.tag).includes(prescription.tag)) {
      return;
    }

    // new phase, let's put it in
    phases.push({
      number: phases.length + 1,
      tag: prescription.tag,
      goal:
        prescription.tag === PrescriptionTag.REDUCE_DISRUPTIVE
          ? DISRUPTIVE_DESCRIPTION
          : prescription.tag === PrescriptionTag.PROMOTE_PROTECTIVE
          ? PROTECTIVE_DESCRIPTION
          : MAINTENANCE_DESCRIPTION,
      bgClass: getTagBackgroundClass(prescription.tag),
    });
  });

  // if we want to include maintenance, then add it if it's not already there
  const maintenanceTag = PrescriptionTag.MAINTENANCE;
  if (includeMaintenance) {
    if (!phases.map((p) => p.tag).includes(maintenanceTag)) {
      phases.push({
        number: phases.length + 1,
        tag: maintenanceTag,
        goal: MAINTENANCE_DESCRIPTION,
        bgClass: getTagBackgroundClass(maintenanceTag),
      });
    }
  }

  return phases;
};

export const getTagBackgroundClass = (tag: string) => {
  if (tag === "Reduce Disruptive") {
    return "from-BA-gradientLight to-BA-oldFill";
  } else if (tag === "Promote Protective") {
    return "from-GO-gradientLight to-GO-oldFill";
  } else if (tag === "Maintenance") {
    return "from-NE-gradientLight to-NE-oldFill";
  } else {
    return "";
  }
};

export const createAndGoToSTIConsult = async (testHash: string) => {
  const consultResponse = await careService.asyncCreateConsult({
    hash: testHash,
    type: "sti",
  });

  const consult = consultResponse.data;
  window.location.href = `/care/sti/consults/${consult.uid}/intake/`;
};

export const handleSTIConsultCTAClick = async (
  testHash: string,
  analyticsEventArgs: any,
  latestSTIConsult?: Consult
) => {
  sendClickedSTIIntakeCTA(analyticsEventArgs);

  if (latestSTIConsult) {
    window.location.href = `/care/sti/consults/${latestSTIConsult.uid}/intake/`;
  } else {
    createAndGoToSTIConsult(testHash);
  }
};

export const getTreatmentImageFromFormFactor = (
  formFactor?: TreatmentFormFactor
) => {
  const FORM_FACTOR_TO_IMAGE = {
    pill: PillsImg,
    cream: CreamImg,
    "applicator-cream": ApplicatorCreamImg,
    suppository: SuppositoriesImg,
  };

  if (formFactor) {
    return FORM_FACTOR_TO_IMAGE[formFactor];
  } else {
    return PillsImg;
  }
};

export const getTreatmentPurposeTagColor = (
  purposeTag?: TreatmentPurposeTag
) => {
  const PURPOSE_TAG_TO_BG_COLOR = {
    rebuild: "bg-evvy-blue",
    relief: "bg-highlighter-mint",
    treat: "bg-dv-apple",
  };
  return purposeTag ? PURPOSE_TAG_TO_BG_COLOR[purposeTag] : "bg-evvy-blue";
};

export const sumAndFormatCartItemsToPriceString = (
  items: { price: number; quantity: number }[]
) => {
  return formatNumberToPriceString(
    items.reduce((acc, item) => acc + Number(item.price) * item.quantity, 0)
  );
};

export const formatNumberToPriceString = (price: number) => {
  return price.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const formatNumberStringToPriceString = (price: string) => {
  return formatNumberToPriceString(Number(price));
};

export const getShopUrlFromCareProduct = (product: Prescription) => {
  return `${SHOP_TREATMENTS_URL}/${product.slug}`;
};

export const getTreatmentPlanUrlFromConsult = (consult?: Consult) => {
  return `/care/consults/${consult?.uid}/treatment/`;
};

type CareIntakeStep = "intro" | "demographics" | "identity-verification/redo";
export const getCareIntakeUrl = (
  intakeStep: CareIntakeStep,
  consult: Consult
) => {
  return `/care/consults/${consult?.uid}/intake/${intakeStep}/`;
};
