import { useState, useEffect, ReactNode } from "react";
import { useJwt } from "react-jwt";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { AuthContext, AuthContextType } from "../contexts/authContext";

import { authService } from "../services/auth";
import { toUser } from "../types/user";

// high level component, wraps most of app
// passes auth params to children components
// upon state changes, re-assesses expiration of access token
const Authentication = ({
  children,
}: {
  children: (authState: AuthContextType, location: Location) => ReactNode; // TODO: remove authState render prop pattern in favor of new context
}) => {
  // state
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );

  const { isExpired } = useJwt(localStorage.getItem("accessToken") || "");
  const navigate = useNavigate();
  const location = useLocation();

  // effects
  useEffect(() => {
    // HACK! TODO: fix
    if (location?.pathname?.includes("/link/dynamic")) {
      return;
    }

    if (accessToken && isExpired) {
      authService.refreshToken(
        (newToken: string) => {
          setAccessToken(newToken);
        },
        () => {
          // this means the refresh token is expired. take user to /login/ to re-authenticate
          console.error("couldnt refresh token");
          setAccessToken(null);
          if (location.pathname !== "/login/") {
            // if we're not already on the login page, go there
            // also pass the current
            navigate(`/login/?next=${location.pathname}`);
          }
        }
      );
    }
  }, [accessToken, isExpired, navigate, location]); // If current token is expired, go refresh it

  useEffect(() => {
    if (location?.pathname?.includes("/link/dynamic")) {
      return;
    }

    if (accessToken && !isExpired) {
      authService.fetchUser();
    }
  }, [accessToken, isExpired]); // Refetches user config when a new access token is set

  // clean slate of props we return to all components
  let authState: AuthContextType = {
    authenticated: false,
  };

  // determine returning properties based on state of current token
  if (accessToken && !isExpired) {
    // we're ready to rock!
    authState = Object.assign(authState, {
      authenticated: true,
      accessToken: localStorage.getItem("accessToken"),
      refreshToken: localStorage.getItem("refreshToken"),
      currentUser: toUser(
        JSON.parse(localStorage.getItem("currentUser") || "{}")
      ),
    });
  } else if (accessToken && isExpired) {
    // we are refreshing the token
    return <></>; // show a blank screen temporarily
  } else {
    // not authenticated. this is an OK state depending on which route it is
    // screens like /account/ + /tests/ will kick out to login,
    // while screens like /login/ + /register/ are fine
  }

  return (
    <AuthContext.Provider value={authState}>
      {children(authState, location)}
    </AuthContext.Provider>
  );
};

export default Authentication;
