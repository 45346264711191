import { useState, useEffect, useMemo, useRef, Fragment } from "react";
import axios from "axios";
import { Link, Route, Routes, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Scrollama, Step } from "react-scrollama"; //Intersection Observer handler library

// imported components
import Markdown from "markdown-to-jsx";

/* Local Imports */
// data
import { resultsService } from "../../services/results";
import {
  updateHover,
  updateScoreGradientHasTransitioned,
  updateSelectedType,
} from "../results_v2/resultsSlice";
import { testsService } from "../../services/tests";
import { trendsService } from "../../services/trends";
import { planService } from "../../services/plan";
import { compareService } from "../../services/compare";

// components
import Layout from "../../components/layout";
import Screen from "../../components/results_v2/screen";
import ErrorBoundary from "../../components/common/errorBoundary";
import ErrorModal from "../../components/results_v2/error";
import ResultsViz from "./viz";
import PdfModal from "./pdfModal";
import LinkedText from "../../components/common/linkedText";
import Modal from "../../components/common/modal";
import TrendsTableContainer from "../../components/results_v2/Trends/trendsTableContainer";
import Carousel from "../../components/plan_unfurled/carousel";
import InsightsCard from "../../components/plan_unfurled/insightsCard";

// results v2
import ScoreCard from "../../components/results_v2/ScoreCard/scoreCard";
import PlusButtonPill from "../../components/results_v2/plusButtonPill";
import ListItem from "../../components/results_v2/listItem";
import BacteriaTableRow from "../../components/results_v2/bacteriaTableRow";
import FilterPill from "../../components/results_v2/filterPill";
import TypeResultsDetail from "../../components/results_v2/TypeResultsSection/typeResultsDetail";
import StickyHeader from "../../components/results_v2/stickyHeader";
import ResultsVizLegend from "../../components/results_v2/resultsVizLegend";
import { OverallSampleLoad } from "../../components/results_v2/overallSampleLoad";

// plan v2
import {
  insightsCopy,
  planItemKeys,
  insightsCardsColors,
  modalTypes as PLAN_MTYPES,
  PLAN_VERSION,
} from "../plan_unfurled/constants";

//icons
import { ReactComponent as ArrowDown } from "../../components/common/icons/arrow-down-no-tail.svg";
import { ReactComponent as ArrowUp } from "../../components/common/icons/arrow-up-no-tail.svg";
import { ReactComponent as DownloadTray } from "../../components/results_v2/icons/download-tray.svg";
import { ReactComponent as Info } from "../../components/results_v2/icons/info.svg";
import { ReactComponent as UpperRightArrow } from "../../components/results_v2/icons/upper-right-arrow.svg";

//modals
import HealthAssociationContentTemplate from "../../components/results_v2/modalTemplates/healthAssociations";
import AssociationDisclaimerTemplate from "../../components/results_v2/modalTemplates/associationsDisclaimer";
import LearnMoreContentTemplate from "../../components/plan_unfurled/modalTemplates/learnMore";

// lib
import {
  DISCLAIMER_COPY,
  screens,
  screens_mobile,
} from "../results_v2/constants";
import {
  modalTypes as MTYPES,
  NEXT_COPY,
  WHATS_UP_COPY,
  SECTION_TYPES,
  TRENDS_COPY,
  CATEGORY_KEYS,
  DEFAULT_COMPARE_TABLE_DATA,
} from "../results_v2/constants";
import {
  MENU_ITEMS,
  CST_COPY,
  BACTERIA_COPY,
  STI_RESULTS_COPY,
  DATA_TYPES,
  PAGE_SECTION_TITLES,
  GOOD_HEALTH_NAME,
} from "./constants";
import { getTrackerData, getAggregatedPercentages } from "./helpers";
import {
  formatters,
  breakpoints,
  gradientClasses,
  secondaryColors,
} from "../../utils/viz";
import { useResponsiveSize } from "../../utils/customHooks";
import {
  eventNames,
  sendViewedMyResultsPage,
} from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { BaseAnalyticsArgsContext } from "../../contexts/baseAnalyticsArgsContext";
import ResearchTemplate from "../../components/plan_unfurled/modalTemplates/research";
import { setResearchModalOpen } from "../plan/planSlice";
import { STIResults } from "../../components/results_v2/stiResults";
import { ResistanceResults } from "../../components/results_v2/resistanceResults";
import classNames from "classnames";

/* Modal Handler
Modal wrapper renders child template within it
Switch statement handles which modal to render and passes data along to template
*/
const renderModal = ({ type, data }, closeModal) => {
  switch (type) {
    case PLAN_MTYPES.LEARN_MORE: {
      return <LearnMoreContentTemplate data={data} handleClose={closeModal} />;
    }
    case MTYPES.HEALTH_ASSOCIATIONS: {
      return (
        <HealthAssociationContentTemplate
          data={data}
          handleClose={closeModal}
        />
      );
    }
    case MTYPES.ASSOCIATIONS_DISCLAIMER: {
      return <AssociationDisclaimerTemplate handleClose={closeModal} />;
    }
    case PLAN_MTYPES.RESEARCH: {
      return <ResearchTemplate handleClose={closeModal} />;
    }
  }
};

const ResultsV2 = () => {
  /* Redux */
  // redux dispatch method for updating global store
  const dispatch = useDispatch();

  /* Refs */
  const vizContainerRef = useRef(null);
  const downloadTextRef = useRef(null);

  /* Route Params */
  const { id } = useParams();

  /* Custom Hooks */
  // Retrieves window width on resize
  const [[width, height, isMobile]] = useResponsiveSize(window.innerWidth, 700);

  const stepTriggerOffset = 0;

  /* state management */
  const filteredAssociation = useSelector(
    (state) => state.results.value.filtered
  );
  const scoreGradientHasTransitioned = useSelector(
    (state) => state.results.value.scoreGradientHasTransitioned
  );
  // get data from store about whether research modal should be open
  const researchModalOpen = useSelector(
    (state) => state.plan.value.researchModalOpen
  );

  const [loadingData, setLoadingData] = useState(true);
  const [loadingCycle, setLoadingCycle] = useState(true);
  const [error, setError] = useState(null);
  const [microbiome, setMicrobiome] = useState(null);
  const [communityType, setCommunityType] = useState(null);
  const [healthContext, setHealthContext] = useState(null); // OK if is null, will create for first timers
  const [test, setTest] = useState({}); // current test
  const [scores, setScores] = useState([]); // array of data on protective and disruptive scores
  const [tests, setTests] = useState([]); // trends data - array of past and current tests
  const [goal, setGoal] = useState(null); // microbial goal from plan api
  const [insights, setInsights] = useState(null); // educational insights from plan api
  const [bacteriaHealthAssociations, setBacteriaHealthAssociations] = useState(
    []
  );
  const [vpcrResults, setVPCRResults] = useState(null); // vpcr results from results api
  const [planProfileInfo, setPlanProfileInfo] = useState({}); // plan profile info from plan api
  const [careEligible, setCareEligible] = useState(null); // whether user is eligible for care
  const [resultPDF, setResultPDF] = useState(null); // presignedurl for results pdf

  // show AMR module if has vpcr results and has detected resistances
  const showAMRModule =
    vpcrResults?.has_results && vpcrResults.pcrresultresistance_set?.length > 0
      ? vpcrResults.pcrresultresistance_set.some(
          (resistanceResult) => resistanceResult.detected
        )
      : false;

  // Scroll state
  // state controller for viz config
  const [screen, setScreen] = useState(
    isMobile ? screens_mobile[0] : screens[0]
  );
  // state which score card has drop shadow
  const [scoreCardIndex, setScoreCardIndex] = useState(null);
  // state controller for whether all bacteria table rows should be visible
  const [showAllRows, setShowAllRows] = useState(false);

  // state controller for whether more type information should be visible
  const [showTypeInfo, setShowTypeInfo] = useState(false);

  // state controller for modal data
  const [modalData, setModalData] = useState(null);

  // state controllers for compare data
  const [baseLoading, setBaseLoading] = useState(true);
  const [compareLoading, setCompareLoading] = useState(false);
  const [base, setBase] = useState({
    selectedOption: null,
    selectedDatatype: null,
    selectedQueryParams: { hash: null, compare_type: null },
    testIdHash: null,
  });
  const [compare, setCompare] = useState({
    selectedOption: DATA_TYPES.DEFAULT,
    selectedDatatype: DATA_TYPES.TEST,
    selectedQueryParams: { hash: null, compare_type: null },
    testIdHash: null,
  });
  const [baseData, setBaseData] = useState(null);
  const [compareData, setCompareData] = useState(null);

  const [checkEarliestTest, setCheckEarliestTest] = useState(true);

  const [stickyFooterVisible, setStickyFooterVisible] = useState(true);
  const myPlanCTARef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setStickyFooterVisible(!entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (myPlanCTARef.current) {
      observer.observe(myPlanCTARef.current);
    }

    return () => {
      if (myPlanCTARef.current) {
        observer.unobserve(myPlanCTARef.current);
      }
    };
  }, []);

  /* Config */
  const config = {
    test: {
      options: tests
        .filter((d) => d.status === "RD")
        .sort((a, b) => {
          return (
            new Date(b.healthcontext.sample_taken_at) -
            new Date(a.healthcontext.sample_taken_at)
          );
        }), // only show results ready tests
      labelAcc: (d) => {
        return test?.hash === d?.hash
          ? `${formatters.abbrDate(
              new Date(d.healthcontext.sample_taken_at)
            )} - This test`
          : formatters.abbrDate(new Date(d.healthcontext.sample_taken_at));
      },
      valAcc: (d) => d.hash,
      compare_type: "previous",
    },
    type: {},
  };

  /* effects */
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchResults((resultsResp) => {
      fetchTest((testResp) => {
        sendViewedMyResultsPage({
          resultsReleased: resultsResp.data.released_at,
          testHash: id,
          testOrder: testResp.data.test_order,
          careEligible: testResp.data?.eligible_for_care,
          aLaCareEligible: testResp.data?.eligible_for_a_la_care,
          experimentGroup: "results_to_plan",
        });
      });
      fetchTrends();
      fetchPlan();
      dispatch(updateScoreGradientHasTransitioned([]));
    });
    // disable loading timer for mobile
    const loadingTime = window.innerWidth <= 768 ? 0 : 5000;
    // let one cycle of the load complete
    // ref: https://stackoverflow.com/questions/53090432/react-hooks-right-way-to-clear-timeouts-and-intervals
    let loadingTimer = setTimeout(() => {
      setLoadingCycle(false);
    }, loadingTime); // 2 second grow + 3 second cycle
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []); // Fetches tests upon page load

  // get all tests data after initial test loaded
  useEffect(() => {
    if (test.hash) fetchTests();
  }, [test]);

  useEffect(() => {
    // call fetch tests passing query params
    queryHandler(base, setBaseData, setBaseLoading);
  }, [JSON.stringify(base.selectedQueryParams)]);

  useEffect(() => {
    // call fetch tests passing query params
    queryHandler(compare, setCompareData, setCompareLoading);
  }, [JSON.stringify(compare.selectedQueryParams)]);

  // watch store change to see if research item should open modal
  // NOTE: might want to move all modal actions into store at some point
  useEffect(() => {
    researchModalOpen
      ? setModalData({ type: PLAN_MTYPES.RESEARCH })
      : setModalData(null);
  }, [researchModalOpen]);

  /* actions */
  async function fetchResults(callback) {
    try {
      const response = await resultsService.fetchResults(id);
      if (response.data.bacteria_results.length) {
        setMicrobiome(response.data.bacteria_results);
        setCommunityType(response.data.community_type);
        setBacteriaHealthAssociations(response.data.bacteria_association_info);
        setHealthContext(response.data.health_context);
        setScores(Object.entries(response.data.scores));
        setResultPDF(response.data.result_pdf);
        if (response.data.vpcr_results?.has_results) {
          setVPCRResults(response.data.vpcr_results);
        }
        if (callback) {
          callback(response);
        }
      } else {
        setError({ ERROR: "bacteria results array is empty", response });
        setLoadingData(false);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error.message);
      }
    }
    setLoadingData(false);
  }

  const fetchTrends = (callback) => {
    trendsService.fetchTrends(
      (response) => {
        if (response.data?.tests?.length) {
          setTests(response.data.tests);
          if (callback) {
            callback(response);
          }
        } else {
          setError({ ERROR: "trends tests array is empty", response });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const fetchTest = (callback) => {
    testsService.fetchTest(
      id,
      (response) => {
        setTest(response.data);
        setLoadingData(false);

        const { hash, pretty_hash, is_ldt } = response.data;

        const selectedDatatype = DATA_TYPES.TEST;
        const selectedOption = response.data;
        const selectedQueryParams = {
          compare_type: config[DATA_TYPES.TEST].compare_type,
          hash: hash,
        };
        const testIdHash = pretty_hash;
        // if is ldt, then just show all rows!
        setShowAllRows(is_ldt);

        setBase((prevBase) => ({
          ...prevBase,
          selectedDatatype,
          selectedOption,
          selectedQueryParams,
          testIdHash,
        }));

        if (callback) {
          callback(response);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const fetchTests = () => {
    testsService.fetchTests(
      (response) => {
        const allTests = response.data
          .filter((d) => d.status === "RD")
          .sort((a, b) => {
            return (
              new Date(b.healthcontext.sample_taken_at) -
              new Date(a.healthcontext.sample_taken_at)
            );
          });

        const currentHash = test.hash;

        const currentTestIndex = allTests.findIndex(
          (el) => el.hash === currentHash
        );

        // set previous test to next item in array OR last item in array
        const previousTest = allTests[currentTestIndex + 1] || null;

        const selectedDatatype = DATA_TYPES.TEST;
        const selectedOption = previousTest;
        const selectedQueryParams = {
          compare_type: config[DATA_TYPES.TEST].compare_type,
          hash: previousTest?.hash || "",
        };
        const testIdHash = previousTest?.pretty_hash || "";

        if (previousTest) {
          setCheckEarliestTest(false);

          setCompare((prevCompare) => ({
            ...prevCompare,
            selectedDatatype,
            selectedOption,
            selectedQueryParams,
            testIdHash,
          }));
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const fetchPlan = () => {
    planService.fetchPlan(
      (response) => {
        const { goal, plan_items, plan_profile, care_eligible } =
          response.data || {};
        // set goal and note
        setGoal(goal);
        setPlanProfileInfo(plan_profile);
        setCareEligible(care_eligible);
        const insights = plan_items.filter(
          ({ group }) => group === planItemKeys.INSIGHTS
        );
        setInsights(insights);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const fetchCompareData = (params, setData, setLoading) => {
    const { compare_type, hash } = params;
    if (compare_type && hash) {
      compareService.fetchData(
        params,
        (response) => {
          setData(response.data);
          setLoading(false);
        },
        (error) => {
          setData(null);
          setLoading(false);
          console.log(error);
        }
      );
    }
  };

  const queryHandler = (state, setData, setLoading) => {
    const { selectedQueryParams } = state;
    if (selectedQueryParams) {
      const { hash, compare_type } = selectedQueryParams;
      if (hash && compare_type) {
        fetchCompareData(selectedQueryParams, setData, setLoading);
      } else {
        setData(null);
      }
    }
  };

  // memoized values
  const username = useMemo(() => {
    // fetch user info from storage
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      const { first_name } = currentUser.identity;
      return first_name;
    }
  }, []);

  const date = useMemo(
    () =>
      healthContext &&
      healthContext.sample_taken_at &&
      new Date(healthContext.sample_taken_at),
    [healthContext]
  );

  // TODO: possibly move into helper
  // stores full length of bacteria rows to be visible in table
  const [filteredRows, onlySmall, noMatch] = useMemo(() => {
    if (microbiome) {
      // initialize set of bacteria to display in bacteria composition table as copy of all bacteria
      let filteredRows = [...microbiome];
      // initialize indicator that only insignificant amounts of this bacteria were found
      let onlySmall = false;
      // initialize indicator that only significant amounts of this bacteria were found
      let onlyBig = false;
      // initialize indicator that none of this bacteria wwas found
      let noMatch = false;
      // if a filter is selected, filter the bacteria rows accordingly
      const getLargePercentRows = (list) =>
        list &&
        list.filter(({ normalized_percent }) => normalized_percent >= 1);
      const getSmallPercentRows = (list) =>
        list && list.filter(({ normalized_percent }) => normalized_percent < 1);

      if (filteredAssociation) {
        // return an array of bacteria that have that association
        filteredRows = filteredRows.filter(({ bacteria }) =>
          bacteria.bacteriaassociation_set.find(
            ({ name }) => name === filteredAssociation.name
          )
        );
      }
      // get number of small vs large percentage bacteria that match this filter
      const largePercentRows = getLargePercentRows(filteredRows);
      const smallPercentRows = getSmallPercentRows(filteredRows);

      // there are no significant amounts of bacteria that match this filter
      onlySmall = !largePercentRows.length;
      // there are only significant amounts of bacteria that match this filter
      onlyBig = !smallPercentRows.length;
      // there are no bacteria that match this filter
      noMatch = !filteredRows.length || onlyBig;

      if (!showAllRows) {
        // if collapsed view, only show bacteria of significant percentage
        filteredRows = largePercentRows;
      }
      // truncate number of rows at 6 if  show all rows button has not been clicked
      return [filteredRows, onlySmall, noMatch];
    } else {
      return [];
    }
  }, [showAllRows, microbiome, filteredAssociation]);

  const symptomTrackerData = useMemo(() => {
    if (tests && tests.length) {
      // select one for now
      return getTrackerData(tests);
    } else {
      return [];
    }
  }, [tests]);
  /* Note - the following is built using the React Scrollama library: https://github.com/jsonkao/react-scrollama */

  // This callback fires when a Step hits the offset threshold. It receives the
  // data prop of the step, which in this demo stores the index of the step.
  const onStepEnter = ({ data }) => {
    setScreen(isMobile ? screens_mobile[data] : screens[data]);
  };

  const onStepProgress = ({ data, progress }) => {
    // for score section, once half of screen is scrolled, switch which card has shadow
    if (data === 1) {
      if (progress > 0 && progress < 0.3) {
        // TODO: confirm trigger point
        setScoreCardIndex(0);
        dispatch(
          updateScoreGradientHasTransitioned(
            scoreGradientHasTransitioned.includes(1) ? [0, 1] : [0]
          )
        );
        dispatch(updateSelectedType(CATEGORY_KEYS.protective));
      } else if (progress >= 0.3 && progress < 0.6) {
        setScoreCardIndex(1);
        dispatch(updateScoreGradientHasTransitioned([0, 1]));
        dispatch(updateSelectedType(CATEGORY_KEYS.disruptive));
      } else {
        dispatch(updateScoreGradientHasTransitioned([0, 1]));
        dispatch(updateSelectedType(null));
      }
    } else if (data === 2 && communityType) {
      // for CST section, set the selected bacteria type in the data viz
      dispatch(
        updateSelectedType(
          CATEGORY_KEYS[communityType.dominant_bacteria_category]
        )
      );
    } else {
      dispatch(updateSelectedType(null));
    }
  };

  const SCREEN_MOBILE_MARGIN_BOTTOM = "mb-20";

  const handleCloseModal = (modalType) => {
    setModalData(null);
    if (modalType === PLAN_MTYPES.RESEARCH) {
      dispatch(setResearchModalOpen(false));
    }
  };

  const myPlanStickyFooterCTA = "What's next for your microbiome?";

  return (
    <BaseAnalyticsArgsContext.Provider
      value={{
        baseAnalyticsArgs: {
          cst: communityType?.name,
          testOrder: test?.test_order,
          testHash: test?.hash,
        },
      }}
    >
      <Layout
        title="Results"
        header={
          <ErrorBoundary>
            <StickyHeader
              onClickBack={analyticsClickHandler({
                eventName: eventNames.RESULTS_CLICKED_BACK,
                eventArgs: {
                  testOrder: test?.test_order,
                  testHash: test?.hash,
                },
              })}
              buttonBackGroundClass="bg-evvy-cream"
              backgroundColorClass={isMobile ? "bg-evvy-cream" : ""}
            />
          </ErrorBoundary>
        }
        full
        bgClass="bg-evvy-cream"
        centered
      >
        {error && <ErrorModal />}
        {/* modal */}
        {modalData !== null && (
          <ErrorBoundary>
            {/* parent modal wrapper  (handles modal functionality) */}
            <Modal
              closeModal={() => handleCloseModal(modalData.type)}
              widthClass={
                [PLAN_MTYPES.RESEARCH].includes(modalData.type)
                  ? "w-plan-column-sm"
                  : "w-plan-column-md"
              }
            >
              {/* specific template for modal */}
              {renderModal(modalData, () => handleCloseModal(modalData.type))}
            </Modal>
          </ErrorBoundary>
        )}
        {/* grid layout */}
        <div className="grid md:grid-cols-2 max-w-7xl mx-auto overscroll-none relative">
          {/* viz for mobile */}
          {isMobile && (
            <div
              className="md:hidden w-full align-start z-10 items-center md:pl-6 pointer-events-none"
              ref={vizContainerRef}
            >
              <ErrorBoundary>
                <ResultsViz
                  data={microbiome}
                  vizState={screen}
                  loading={loadingCycle || loadingData} // pass down loading state to determine whether to show loading spinner
                />
              </ErrorBoundary>
            </div>
          )}
          <ErrorBoundary>
            <div className="z-0 text-left mx-5 space-y-24 pointer-events-auto md:text-left md:space-y-0 md:w-[502px]">
              <ErrorBoundary>
                <Scrollama
                  onStepEnter={onStepEnter}
                  onStepProgress={onStepProgress}
                  offset={stepTriggerOffset}
                >
                  {/* intro screen */}
                  <Step data={0} key="step-0">
                    {/* note: screens are wrapped in a parent div to accomodate react-scrollama api */}
                    <div
                      tabIndex={0}
                      className={
                        "flex flex-col md:relative md:h-[calc(100vh-161.5px)]" // 88px is height of header 73.5 is height of sticky footer so total height is 161.5
                      }
                    >
                      <div className="my-auto">
                        {/* welcome message */}
                        <ErrorBoundary>
                          <div className="text-left">
                            <div className="animate-fadein text-left">
                              <div className="text-base uppercase tracking-wider font-semibold h-8">
                                {date && `Test from ${formatters.date(date)}`}
                              </div>
                              <h1 className="text-[38px] md:text-[46px]">
                                <div className="italic serif">
                                  {`Hi ${username ? username : "there"}`},
                                </div>
                                <div>your results are ready</div>
                              </h1>
                              <div className="t1 mt-4 md:mt-10">
                                Here's what you can expect in your results:
                              </div>
                            </div>
                          </div>
                          {/* contents nav */}
                          <div className="w-full animate-fadein mt-6">
                            <div className="w-full lg:w-max mx-auto md:mx-0 opacity-90">
                              {/* note: these components link to different scrolly-telling sections */}
                              {MENU_ITEMS &&
                                MENU_ITEMS.map(({ number, label, href }) => (
                                  <ListItem
                                    key={`menuItem-${number}`}
                                    bgColorClass="bg-highlighter-mint"
                                    number={number}
                                    label={label}
                                    href={href}
                                    onClick={analyticsClickHandler({
                                      eventName:
                                        eventNames.RESULTS_CLICKED_LEGEND,
                                      eventArgs: {
                                        testOrder: test?.test_order,
                                        testHash: test?.hash,
                                        sectionTitle: label,
                                      },
                                    })}
                                  />
                                ))}
                            </div>
                          </div>
                        </ErrorBoundary>
                      </div>
                      <h4
                        className={classNames(
                          `md:block w-full font-bold animate-fadein justify-center md:justify-end md:absolute md:bottom-0 mb-0 sm:mb-2`,
                          {
                            flex: !loadingData && !loadingCycle,
                            hidden: loadingData || loadingCycle,
                          }
                        )}
                      >
                        <div className="text-center md:translate-x-1/2 lg:translate-x -mr-4">
                          <div className="t4 mb-4">Scroll</div>
                          <div className="animate-bounce">↓</div>
                        </div>
                      </h4>
                    </div>
                  </Step>
                  <Step data={1} key="step-1">
                    {/* your microbiome at a glance screen */}
                    <div id="ataglance" tabIndex={0} className="!mt-20">
                      <Screen
                        customMarginClass={
                          isMobile ? SCREEN_MOBILE_MARGIN_BOTTOM : ""
                        }
                      >
                        <div className="text-left pt-4">
                          <h2 className="text-[38px] md:text-[46px]">
                            Your Microbiome{" "}
                            <span className="whitespace-nowrap">
                              At-a-Glance
                            </span>
                          </h2>
                        </div>
                        {scores &&
                          scores.map(([scoreType, scoreData], i) => (
                            <ScoreCard
                              key={`scoreCard-${scoreType}`}
                              scoreType={scoreType}
                              scoreData={scoreData}
                              initial={
                                !scoreGradientHasTransitioned.includes(i)
                              }
                              boxShadowClass={`${
                                i === scoreCardIndex
                                  ? "shadow-lg"
                                  : "shadow-none"
                              }`}
                            />
                          ))}
                      </Screen>
                    </div>
                  </Step>
                  <Step data={2} key="step-2">
                    {/* results and your community type screen */}
                    <div
                      id="microbiometype"
                      tabIndex={0}
                      className={isMobile ? "scroll-mt-16" : ""}
                    >
                      <Screen
                        customMarginClass={
                          isMobile ? SCREEN_MOBILE_MARGIN_BOTTOM : ""
                        }
                      >
                        {/* intro education card */}
                        <ErrorBoundary>
                          <div className="text-left pt-4">
                            <h1 className="text-[38px] md:text-[46px]">
                              {CST_COPY.TITLE}
                            </h1>
                            <div
                              className={`${
                                isMobile ? "text-base font-medium" : "t3"
                              } mt-6 mb-4`}
                            >
                              {CST_COPY.SUBTITLE}
                            </div>
                            {/* just visual elements - hiding from screen readers*/}
                            <LinkedText
                              onClick={analyticsClickHandler({
                                eventName: eventNames.RESULTS_CLICKED_BLOG_LINK,
                                eventArgs: {
                                  ctaName: CST_COPY.LINK,
                                  articleUrl: CST_COPY.LINK_ADDRESS,
                                  testOrder: test?.test_order,
                                  testHash: test?.hash,
                                  pageSection: PAGE_SECTION_TITLES.CST,
                                },
                              })}
                              noTopMargin
                              noIcon
                              isLink
                              href={CST_COPY.LINK_ADDRESS}
                            >
                              {CST_COPY.LINK}
                              <span className="ml-2 inline-block">
                                <UpperRightArrow
                                  fill={secondaryColors["evvy-black"]}
                                />
                              </span>
                            </LinkedText>
                          </div>
                        </ErrorBoundary>
                        {/* Your type card */}
                        <ErrorBoundary>
                          <div>
                            <div className="bg-white p-7 rounded-tl-[10px] rounded-tr-[10px] border-evvy-black border-opacity-30 border-b">
                              <div
                                className={
                                  isMobile
                                    ? "t3 semibold"
                                    : "title tracking-wider"
                                }
                              >
                                {CST_COPY.CARD_TITLE}
                              </div>
                              {communityType && (
                                <>
                                  <h2 className="my-4 md:my-6 text-[38px] md:text-[46px]">
                                    {communityType.name_user_facing}
                                  </h2>
                                  {/* if user is in expanded view, show all information */}
                                  {showTypeInfo
                                    ? communityType.info &&
                                      communityType.info.map((point, i) => (
                                        <Fragment key={`point-${i}`}>
                                          <div
                                            className={`${
                                              isMobile
                                                ? "text-base font-medium"
                                                : "t3"
                                            } my-4`}
                                          >
                                            <Markdown>{point}</Markdown>
                                          </div>
                                          {/*  do not display bordered line after last point and hide from screen readers */}
                                          {i !==
                                            communityType.info.length - 1 && (
                                            <div
                                              className="border-evvy-black border-opacity-30 border-t border-dashed w-full"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </Fragment>
                                      ))
                                    : // else just show first point
                                      communityType.info && (
                                        <div
                                          className={
                                            isMobile
                                              ? "text-base font-medium"
                                              : "t3"
                                          }
                                        >
                                          <Markdown>
                                            {communityType.info[0]}
                                          </Markdown>
                                        </div>
                                      )}
                                </>
                              )}
                              {/* button to expand or collapse all type information */}
                              <LinkedText
                                onClick={
                                  showTypeInfo
                                    ? () => setShowTypeInfo(false)
                                    : analyticsClickHandler({
                                        eventName:
                                          eventNames.EXPANDED_RESULTS_CARD,
                                        eventArgs: {
                                          cst: communityType?.name,
                                          testOrder: test?.test_order,
                                          testHash: test?.hash,
                                          cardType: "CST",
                                        },
                                        clickHandler: () =>
                                          setShowTypeInfo(true),
                                      })
                                }
                                noIcon
                              >
                                {showTypeInfo ? (
                                  <>
                                    {CST_COPY.CARD_COLLAPSE}
                                    <span className="ml-2 inline-block">
                                      <ArrowUp
                                        fill={secondaryColors["evvy-black"]}
                                      />
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {CST_COPY.CARD_EXPAND}
                                    <span className="ml-2 inline-block">
                                      <ArrowDown
                                        fill={secondaryColors["evvy-black"]}
                                      />
                                    </span>
                                  </>
                                )}
                              </LinkedText>
                            </div>
                            <div className="bg-white p-7 rounded-bl-[10px] rounded-br-[10px]">
                              {communityType && (
                                <>
                                  <TypeResultsDetail
                                    typeData={communityType}
                                    sectionType={SECTION_TYPES.circlePack}
                                    mobileSize={width < breakpoints.md}
                                  />
                                  <TypeResultsDetail
                                    typeData={communityType}
                                    sectionType={SECTION_TYPES.donut}
                                    mobileSize={width < breakpoints.md}
                                  />
                                  <div className="t4 pt-4 mb-2">
                                    <span className="mr-2 align-middle">{`${communityType.name_user_facing} health associations`}</span>
                                    <button
                                      className="align-middle"
                                      type="button"
                                      onClick={analyticsClickHandler({
                                        eventName:
                                          eventNames.RESULTS_CLICKED_ASSOCIATION_DISCLAIMER,
                                        eventArgs: {
                                          cst: communityType?.name,
                                          testOrder: test?.test_order,
                                          testHash: test?.hash,
                                        },
                                        clickHandler: () =>
                                          setModalData({
                                            type: MTYPES.ASSOCIATIONS_DISCLAIMER,
                                            data: {},
                                          }),
                                      })}
                                    >
                                      <Info
                                        stroke={secondaryColors["dv-pond"]}
                                        fill={secondaryColors["dv-pond"]}
                                      />
                                    </button>
                                  </div>
                                  {/* if user is in expanded view, show all information */}
                                  <div className="flex flex-wrap">
                                    {communityType.health_associations &&
                                      communityType.health_associations.map(
                                        (data) => (
                                          <PlusButtonPill
                                            key={`button-${data.name}`}
                                            text={data.name}
                                            onClick={analyticsClickHandler({
                                              eventName:
                                                eventNames.RESULTS_CLICKED_CST_ASSOCIATION,
                                              eventArgs: {
                                                cst: communityType?.name,
                                                testOrder: test?.test_order,
                                                testHash: test?.hash,
                                                associationTitle: data.name,
                                              },
                                              clickHandler: () =>
                                                setModalData({
                                                  type: MTYPES.HEALTH_ASSOCIATIONS,
                                                  data,
                                                }),
                                            })}
                                          />
                                        )
                                      )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </ErrorBoundary>
                      </Screen>
                    </div>
                  </Step>
                  <Step data={3} key="step-3">
                    <div
                      id="detailedresults"
                      tabIndex={0}
                      className={isMobile ? "scroll-mt-16" : ""}
                    >
                      {/* detailed bacteria table results screen */}
                      <Screen
                        customMarginClass={
                          isMobile ? SCREEN_MOBILE_MARGIN_BOTTOM : ""
                        }
                      >
                        {/* table intro card */}
                        <ErrorBoundary>
                          {/* z-index high in order to be clickable above hidden margin elements from bacteria table.
                        these elements allow the screen to scroll table rows up into view on click but cover the below div*/}
                          <div
                            className="text-left pt-4"
                            style={{ zIndex: 100 }}
                          >
                            <h1 className="text-[38px] md:text-[46px]">
                              {BACTERIA_COPY.TITLE}
                            </h1>
                            {vpcrResults && (
                              <OverallSampleLoad
                                className="bg-white p-6 rounded-lg mb-4"
                                level={vpcrResults.sample_qualitative_load}
                              />
                            )}
                            {showAMRModule && (
                              <ResistanceResults
                                className="bg-white p-6 rounded-lg mb-4"
                                resistanceResults={
                                  vpcrResults.pcrresultresistance_set
                                }
                              />
                            )}
                            <div className="px-6 py-8 sm:px-8 border-evvy-black border-opacity-30 border-b bg-white rounded-t-lg">
                              <div className="t3 mb-2">
                                {BACTERIA_COPY.SUBTITLE}
                              </div>
                              {/* filter selection pills */}
                              <div className="flex flex-wrap">
                                {bacteriaHealthAssociations.map(
                                  (association) => (
                                    <FilterPill
                                      onClick={analyticsClickHandler({
                                        eventName:
                                          eventNames.RESULTS_CLICKED_FILTER_TAGS,
                                        eventArgs: {
                                          testOrder: test?.test_order,
                                          testHash: test?.hash,
                                          cst: communityType?.name,
                                          tagTitle: association.name,
                                        },
                                      })}
                                      key={`filterPill-${association.name}`}
                                      text={association.name}
                                      data={association}
                                    />
                                  )
                                )}
                              </div>
                              {/* information about the selected health condition - only append if filter selected */}
                              {filteredAssociation && (
                                <div className="pt-7">
                                  <div className="b1 regular mb-4">
                                    {filteredAssociation.description}
                                  </div>
                                  <LinkedText
                                    onClick={analyticsClickHandler({
                                      eventName:
                                        eventNames.RESULTS_CLICKED_BLOG_LINK,
                                      eventArgs: {
                                        ctaName: filteredAssociation.link?.text,
                                        articleUrl:
                                          filteredAssociation.link?.url,
                                        testOrder: test?.test_order,
                                        testHash: test?.hash,
                                        pageSection:
                                          PAGE_SECTION_TITLES.BACTERIA_COMPOSITION,
                                      },
                                    })}
                                    noIcon
                                    isLink
                                    href={filteredAssociation.link?.url}
                                  >
                                    {filteredAssociation.link?.text}
                                    <span className="ml-2 inline-block">
                                      <UpperRightArrow
                                        fill={secondaryColors["evvy-black"]}
                                      />
                                    </span>
                                  </LinkedText>
                                  {/* only show insignificant amount disclaimer copy if relevant */}
                                  {onlySmall && (
                                    <div class="b2 bg-evvy-silverfish/40 rounded-[10px] p-4 my-8">
                                      <div className="mb-2">
                                        {filteredAssociation.name ===
                                        GOOD_HEALTH_NAME
                                          ? BACTERIA_COPY.EMPTY_A_GOOD_HEALTH
                                          : noMatch
                                          ? BACTERIA_COPY.EMPTY_A_NO_MICROBIES
                                          : BACTERIA_COPY.EMPTY_A}
                                      </div>
                                      <div>
                                        {filteredAssociation.name ===
                                        GOOD_HEALTH_NAME
                                          ? BACTERIA_COPY.EMPTY_B_GOOD_HEALTH
                                          : BACTERIA_COPY.EMPTY_B}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                            {/* bacteria table wrapper card */}
                            <div
                              className="bg-white opacity-90"
                              onMouseLeave={() => dispatch(updateHover(null))}
                            >
                              <div>
                                {/* explicity make z-index lower than the above div */}
                                <div className="pointer-events-auto z-10">
                                  {/* append a row for each bacteria that matches the filter and or the view selection */}
                                  {filteredRows &&
                                    filteredRows.map(
                                      (
                                        {
                                          bacteria,
                                          normalized_percent: percent,
                                          qualitative_load,
                                        },
                                        i
                                      ) => (
                                        <BacteriaTableRow
                                          isMobile={isMobile}
                                          key={bacteria.id}
                                          analyticsOnClick={(
                                            clickHandler,
                                            eventName
                                          ) => {
                                            if (
                                              eventName ===
                                              eventNames.EXPANDED_RESULTS_CARD
                                            ) {
                                              return analyticsClickHandler({
                                                eventName:
                                                  eventNames.EXPANDED_RESULTS_CARD,
                                                eventArgs: {
                                                  cst: communityType?.name,
                                                  testOrder: test?.test_order,
                                                  testHash: test?.hash,
                                                  cardType: "bacteria",
                                                  bacteriaName:
                                                    bacteria?.short_name,
                                                  bacteriaId: bacteria?.id,
                                                  microbeCategory:
                                                    bacteria.get_type_display?.toLowerCase(),
                                                },
                                                clickHandler,
                                              });
                                            } else if (
                                              eventName ===
                                              eventNames.RESULTS_CLICKED_BACTERIA_ASSOCIATION
                                            ) {
                                              return (associationTitle) =>
                                                analyticsClickHandler({
                                                  eventName:
                                                    eventNames.RESULTS_CLICKED_BACTERIA_ASSOCIATION,
                                                  eventArgs: {
                                                    cst: communityType?.name,
                                                    testOrder: test?.test_order,
                                                    testHash: test?.hash,
                                                    bacteriaName:
                                                      bacteria?.short_name,
                                                    bacteriaId: bacteria?.id,
                                                    associationTitle,
                                                  },
                                                  clickHandler,
                                                });
                                            }
                                          }}
                                          bgColorClass={`bg-${[
                                            bacteria.type,
                                          ]}-fill`}
                                          bacteria={bacteria}
                                          percent={percent}
                                          microbialLoad={qualitative_load}
                                          gradientTo={
                                            gradientClasses[bacteria.type].to
                                          }
                                          gradientFrom={
                                            gradientClasses[bacteria.type].from
                                          }
                                          borderRadiusClass={
                                            !filteredAssociation && i === 0
                                              ? "rounded-t-lg"
                                              : "rounded-none"
                                          } // round border radius at top of div to prevent gradient overflow on hover
                                          noBorder={
                                            i === filteredRows.length - 1
                                          } // do not append border to last row to avoid double dashes at card edge
                                        />
                                      )
                                    )}
                                </div>
                              </div>
                            </div>
                            {/* show all rows button */}
                            {!test?.is_ldt && (
                              <div
                                className={`${
                                  noMatch ? "hidden" : "flex"
                                } border-evvy-black/30 border-t justify-center items-center pb-4 pointer-events-auto bg-white rounded-b-lg`}
                              >
                                <button
                                  className="unset t1 mt-6 mb-4 flex items-center"
                                  onClick={
                                    showAllRows
                                      ? () => setShowAllRows(!showAllRows)
                                      : analyticsClickHandler({
                                          eventName:
                                            eventNames.RESULTS_EXPAND_ALL_MICROBES,
                                          eventArgs: {
                                            cst: communityType?.name,
                                            testOrder: test?.test_order,
                                            testHash: test?.hash,
                                          },
                                          clickHandler: () =>
                                            setShowAllRows(!showAllRows),
                                        })
                                  }
                                >
                                  {showAllRows ? (
                                    <>
                                      {BACTERIA_COPY.CARD_COLLAPSE}
                                      <ArrowUp
                                        fill={secondaryColors["evvy-blue"]}
                                        className="ml-3 mt-1"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {BACTERIA_COPY.CARD_EXPAND}
                                      <ArrowDown
                                        fill={secondaryColors["evvy-blue"]}
                                        className="ml-3 mt-1"
                                      />
                                    </>
                                  )}
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="flex mx-auto">
                            {microbiome && communityType && (
                              <ErrorBoundary>
                                <Link to={`download/`}>
                                  <button className="t4 flex items-end">
                                    <div className="t2 flex flex-col items-center mr-4">
                                      ↓
                                      <DownloadTray className="-mt-[6px]" />
                                    </div>
                                    Download your results
                                  </button>
                                </Link>
                              </ErrorBoundary>
                            )}
                          </div>
                          <div className={`italic text-sm`}>
                            {test?.is_ldt
                              ? vpcrResults
                                ? DISCLAIMER_COPY.EXPANDED_LDT
                                : DISCLAIMER_COPY.STANDARD_LDT
                              : DISCLAIMER_COPY.WELLNESS}
                          </div>
                        </ErrorBoundary>
                      </Screen>
                      {vpcrResults &&
                        vpcrResults.pcrresultsti_set.length > 0 && (
                          <Screen
                            customMarginClass={
                              isMobile ? SCREEN_MOBILE_MARGIN_BOTTOM : ""
                            }
                          >
                            <ErrorBoundary>
                              <div>
                                <h1 className="text-[38px] md:text-[46px]">
                                  {STI_RESULTS_COPY.TITLE}
                                </h1>
                                <STIResults
                                  stiResults={vpcrResults.pcrresultsti_set}
                                />
                              </div>
                            </ErrorBoundary>
                          </Screen>
                        )}
                    </div>
                  </Step>
                  <Step data={4} key="step-4">
                    <div tabIndex={0}>
                      <Screen customMarginClass={isMobile ? "mb-12" : ""}>
                        <ErrorBoundary>
                          {loadingData || !planProfileInfo ? (
                            ""
                          ) : (
                            <div className="p-10 bg-marine text-white rounded-[10px]">
                              <h3 className="text-[40px] mb-4">
                                {NEXT_COPY.TITLE}
                              </h3>
                              <div className="t3 pb-8">
                                {NEXT_COPY.SUBTITLE}
                              </div>
                              {goal && (
                                <div>
                                  <div className="t4 mt-4 text-highlighter-yellow">
                                    {NEXT_COPY.GOAL}
                                  </div>
                                  <div className="t2 mt-4 mb-8">
                                    <Markdown>{goal ? goal : ""}</Markdown>
                                  </div>
                                </div>
                              )}
                              <Link
                                to="/plan/"
                                onClick={analyticsClickHandler({
                                  eventName:
                                    eventNames.CLICKED_RESULTS_VIEW_PLAN,
                                  eventArgs: {
                                    cst: communityType?.name,
                                    testOrder: test?.test_order,
                                    testHash: test?.hash,
                                    pageSection:
                                      PAGE_SECTION_TITLES.BACTERIA_COMPOSITION,
                                    experimentGroup: "results_to_plan",
                                    careEligible,
                                  },
                                })}
                              >
                                <div className="w-full p-8 mt-8 t4 text-evvy-black text-center bg-evvy-blue">
                                  {NEXT_COPY.LINK} →
                                </div>
                              </Link>
                            </div>
                          )}
                        </ErrorBoundary>
                      </Screen>
                      <h4
                        className={`flex w-full pb-6 font-bold animate-fadein justify-center md:justify-end mb-10`}
                      >
                        <div className="text-center md:translate-x-1/2 md:-mr-4">
                          <div className="t4 mb-4 w-80">
                            {NEXT_COPY.SCROLL_TEXT}
                          </div>
                          <div className="animate-bounce">↓</div>
                        </div>
                      </h4>
                    </div>
                  </Step>
                </Scrollama>
              </ErrorBoundary>
            </div>
            {/* graphic sticky container - viz for desktop */}
            <div
              className="hidden md:block md:sticky align-start md:top-0 z-0 items-center md:pl-6 pb-24"
              ref={vizContainerRef}
            >
              <ErrorBoundary>
                <ResultsViz
                  data={microbiome}
                  vizState={screen}
                  loading={loadingCycle || loadingData} // pass down loading state to determine whether to show loading spinner
                />
                {/* translate legend to appear below viz, using view-height */}
                {microbiome && (
                  <div
                    className={`${
                      screen.groupExplosionBasis > 0 ? "block" : "hidden"
                    } w-fit mx-auto`}
                    style={{ transform: "translate(0, -8vh)" }}
                  >
                    <ResultsVizLegend
                      data={getAggregatedPercentages(microbiome)}
                    />
                  </div>
                )}
              </ErrorBoundary>
            </div>
          </ErrorBoundary>
        </div>
        {/* trends */}
        <div
          className={`z-10 px-5 md:px-20 w-full xl:w-[1260px] overflow-x-hidden ${
            isMobile ? "scroll-mt-20" : ""
          }`}
          id="trends"
        >
          <ErrorBoundary>
            <Screen customMarginClass={SCREEN_MOBILE_MARGIN_BOTTOM}>
              <div className="w-full text-center">
                <h1 className="text-[38px] md:text-[46px]">
                  {TRENDS_COPY.TITLE}
                </h1>
                <div
                  className={`${
                    isMobile ? "text-base font-medium" : "t3"
                  } mt-6 mb-4 xl:max-w-1/2 mx-auto`}
                >
                  {tests?.length > 1
                    ? TRENDS_COPY.SUBTITLE
                    : TRENDS_COPY.SUBTITLE_ONE_TEST}
                </div>
                <TrendsTableContainer
                  baseTestsData={baseData}
                  compareTestData={
                    tests?.length > 1 ? compareData : DEFAULT_COMPARE_TABLE_DATA
                  }
                  config={config}
                  base={base}
                  setBase={setBase}
                  setBaseLoading={setBaseLoading}
                  compare={compare}
                  setCompare={setCompare}
                  setCompareLoading={setCompareLoading}
                  currentTestHash={test?.hash}
                  earliestTest={checkEarliestTest}
                />
              </div>
            </Screen>
          </ErrorBoundary>
        </div>
        <ErrorBoundary>
          {/* insights section - only if there are education items returned from db */}
          {insights && !!insights.length && (
            <div
              className="flex flex-col items-center mb-8 sm:m-auto pb-12 pt-4 px-8 sm:px-0 mx-6 md:m-auto relative w-full max-w-plan-column-lg"
              id="insights"
            >
              <div className="text-center">
                <h1 className="text-[38px] md:text-[46px]">
                  {insightsCopy.TITLE}
                </h1>
                <div className="t3 mt-6 xl:max-w-1/2 mx-auto">
                  {insightsCopy.DESC}
                </div>
              </div>
              <Carousel
                space={335 + 14} // insights card width + 1rem
              >
                {insights.map((data, i) => (
                  <InsightsCard
                    key={`insights-${i}`}
                    // three options for card colors, get color from array at index i if i is 0-2, or remainder of i divided by 3 if i is 3 or greater
                    color={insightsCardsColors[i] || insightsCardsColors[i % 3]}
                    data={data}
                    openModalWith={setModalData}
                    eventArgs={{
                      slug: data.slug,
                      groupName: data.get_group_display,
                      planVersion: PLAN_VERSION,
                      planProfileId: planProfileInfo?.id,
                      careEligible,
                      location: "results",
                    }}
                  />
                ))}
              </Carousel>
            </div>
          )}
        </ErrorBoundary>
        {/* whats next page - links to my plan and compare */}
        <div
          className={`z-10 ${isMobile ? "scroll-mt-16" : ""}`}
          id="nextsteps"
        >
          <ErrorBoundary>
            <Screen
              customMarginClass={
                isMobile ? SCREEN_MOBILE_MARGIN_BOTTOM : "mb-16"
              }
            >
              <div
                ref={myPlanCTARef}
                className="flex flex-col justify-center items-center max-w-6xl rounded-lg space-y-6 min-h-1/2-screen bg-cover bg-no-repeat md:mt-28 mx-5 md:mx-10 md:mx-auto md:px-20 px-2 pb-4 pt-8 md:py-28"
                id="tomyplan"
                tabIndex={0}
                style={{
                  backgroundImage: `url('/images/graphics/plan-bg-gradient.png')`,
                }}
              >
                <h1 className="m-0 text-[30px] md:text-[46px] text-center md:w-[826px]">
                  <Markdown>{WHATS_UP_COPY.TITLE}</Markdown>
                </h1>
                <div
                  className={`${
                    goal ? "bg-white shadow-[0_0_20px_white]" : ""
                  } rounded-md p-7 mx-2 text-center w-full md:w-[510px]`}
                >
                  <div className="t4 mb-4 md:mb-8">
                    {goal ? WHATS_UP_COPY.SUBTITLE : ""}
                  </div>
                  <div className="t2 medium mb-7">
                    <Markdown>{goal ? goal : ""}</Markdown>
                  </div>
                  <Link
                    to="/plan/"
                    onClick={analyticsClickHandler({
                      eventName: eventNames.CLICKED_RESULTS_VIEW_PLAN,
                      eventArgs: {
                        testOrder: test?.test_order,
                        testHash: test?.hash,
                        cst: communityType?.name,
                        pageSection: PAGE_SECTION_TITLES.PLAN,
                        experimentGroup: "results_to_plan",
                        careEligible,
                      },
                    })}
                  >
                    <div className="w-full p-8 mt-8 t4 text-evvy-black text-center bg-evvy-blue">
                      {WHATS_UP_COPY.LINK} →
                    </div>
                  </Link>
                </div>
              </div>
            </Screen>
          </ErrorBoundary>
        </div>
        <div>
          {microbiome && communityType && (
            <ErrorBoundary>
              <Routes>
                <Route
                  path={`download/`}
                  element={
                    <PdfModal
                      closeUrl={`/results/${test?.hash}/`}
                      microbiome={microbiome}
                      fileName={`evvy_results_${formatters.pdfDate(date)}.pdf`}
                      date={date}
                      test={test}
                      communityType={communityType}
                      resultPDF={resultPDF}
                    />
                  }
                />
              </Routes>
            </ErrorBoundary>
          )}
        </div>
        {stickyFooterVisible && (
          <Layout.StickyFooter>
            <h5 className="hidden sm:block mb-0">{myPlanStickyFooterCTA}</h5>
            <h4 className="block sm:hidden mb-2.5">{myPlanStickyFooterCTA}</h4>
            <Link
              to="/plan/"
              onClick={analyticsClickHandler({
                eventName: eventNames.CLICKED_RESULTS_VIEW_PLAN,
                eventArgs: {
                  cst: communityType?.name,
                  testOrder: test?.test_order,
                  testHash: test?.hash,
                  pageSection: PAGE_SECTION_TITLES.MY_PLAN_STICKY_FOOTER,
                  experimentGroup: "myplan_cta_experiment",
                  careEligible,
                },
              })}
              className="w-full sm:w-fit"
            >
              <div className="cursor-pointer uppercase text-xs text-center justify-center tracking-wider py-2.5 sm:py-5 whitespace-break-spaces sm:px-14 font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none">
                View Your Plan
              </div>
            </Link>
          </Layout.StickyFooter>
        )}
      </Layout>
    </BaseAnalyticsArgsContext.Provider>
  );
};

export default ResultsV2;
