import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// components
import LoadingSpinner from "../common/loadingSpinner";

// services
import { testsService } from "../../services/tests";

// lib
import { Consult, Prescription } from "../../types/care";
import { Test } from "../../types/test";
import Modal from "../common/modal";
import CloseX from "../common/closeX";
import Markdown from "markdown-to-jsx";
import { sendSTIPastTreatmentCTAClicked } from "../../utils/analytics/customEventTracking";

enum ModalType {
  PROVIDER_NOTE = "provider_note",
  LEARN_MORE = "learn_more",
}

const StiTreatmentCard = ({
  consult,
  index,
  numConsults,
}: {
  consult: Consult;
  index: number;
  numConsults: number;
}) => {
  const isFirstCard = index === 0;
  const isLastCard = index === numConsults - 1;

  const [test, setTest] = useState<Test>();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{
    type: ModalType;
    content: React.ReactNode;
  }>();

  useEffect(() => {
    const getTest = async () => {
      setLoading(true);
      const result = await testsService.fetchTestAsync(consult.test_hash);
      setTest(result.data);
      setLoading(false);
    };

    getTest();
  }, [consult.test_hash]);

  const formatDiagnosticText = (diagnosisText: string) => {
    const parts = diagnosisText.split("|");
    // Remove the "unspecified" part of the diagnosis text if it exists
    return parts.map((part) => part.replace(", unspecified", "")).join(", ");
  };

  return (
    <div
      className={`${isFirstCard ? "rounded-t-lg" : ""} ${
        isLastCard ? "rounded-b-lg" : ""
      } border border-gray-300 p-6 sm:p-10`}
    >
      {modalOpen && modalData && (
        <Modal
          widthClass="w-full md:w-8/12"
          preventBodyScroll
          closeModal={() => {
            setModalOpen(false);
          }}
        >
          <div>{modalData.content}</div>
        </Modal>
      )}
      {loading || !test ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="bg-evvy-pine px-2 py-1 text-xs tracking-wider font-medium uppercase rounded-full mb-4 ml-0 w-fit text-white">
            STI Treatment
          </div>
          <div className="grid grid-cols-2 gap-8 mb-2">
            <div className="col-span-1">
              <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500">
                Test ID
              </div>
              <div className="font-semibold">
                {test?.pretty_hash?.toUpperCase()}
              </div>
            </div>
            <div className="col-span-1">
              <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500">
                Test Date
              </div>
              <div className="font-semibold">
                {test?.healthcontext?.sample_taken_at}
              </div>
            </div>
            <div className="col-span-1">
              <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500">
                Clinical Intake
              </div>
              <Link
                className="underline cursor-pointer inline-block font-semibold persistSize underline-offset-4"
                to={`/care/sti/consults/${consult.uid}/intake/demographics/`}
                target="_blank"
                onClick={() => {
                  sendSTIPastTreatmentCTAClicked({
                    testHash: test?.hash,
                    consultId: consult.uid,
                    ctaType: "View Consult Intake",
                    productName: undefined,
                  });
                }}
              >
                View
              </Link>
            </div>
            <div className="col-span-1">
              <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500">
                Your Provider
              </div>
              <div className="font-semibold">
                {consult.clinician_display_name}
              </div>
            </div>
            {consult.diagnosis_text && (
              <div className="col-span-1">
                <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500">
                  Diagnosis
                </div>
                <div className="font-semibold">
                  {formatDiagnosticText(consult.diagnosis_text)}
                </div>
              </div>
            )}
            <div className="col-span-1">
              <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500">
                Provider's Note
              </div>
              <div
                className="font-semibold cursor-pointer underline persistSize underline-offset-4"
                onClick={() => {
                  sendSTIPastTreatmentCTAClicked({
                    testHash: test?.hash,
                    consultId: consult.uid,
                    ctaType: "View Provider Note",
                    productName: undefined,
                  });
                  setModalData({
                    type: ModalType.PROVIDER_NOTE,
                    content: (
                      <ProvidersNoteModal
                        note={consult.physician_note}
                        handleClose={() => {
                          setModalOpen(false);
                        }}
                      />
                    ),
                  });
                  setModalOpen(true);
                }}
              >
                View
              </div>
            </div>

            <div className="col-span-2">
              <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500">
                Your Treatment
              </div>
              <ul className="list-none mx-auto mt-4 mb-0 grid grid-cols-2">
                {consult?.prescriptions?.map((prescription) => (
                  <li
                    key={prescription.slug}
                    className="flex col-span-2 h-9 mb-4 justify-between rounded-md p-4 py-8 bg-evvy-cream"
                  >
                    <div
                      className={`b2 self-center font-semibold text-evvy-pine uppercase`}
                    >
                      {prescription?.title_short_display}
                    </div>

                    <div
                      className="self-center"
                      onClick={() => {
                        sendSTIPastTreatmentCTAClicked({
                          testHash: test?.hash,
                          consultId: consult.uid,
                          ctaType: "Clicked Product  Learn More",
                          productName: prescription?.title_short_display,
                        });
                        setModalData({
                          type: ModalType.LEARN_MORE,
                          content: (
                            <LearnMoreModal
                              prescription={prescription}
                              handleClose={() => {
                                setModalOpen(false);
                              }}
                            />
                          ),
                        });
                        setModalOpen(true);
                      }}
                    >
                      <p className="underline cursor-pointer inline-block  persistSize underline-offset-4">
                        Learn More +
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Link
            to={`/care/consults/${consult?.uid}/messages/`}
            target="_blank"
            onClick={() => {
              sendSTIPastTreatmentCTAClicked({
                testHash: test?.hash,
                consultId: consult.uid,
                ctaType: "View Past Messages",
                productName: undefined,
              });
            }}
          >
            <div className="w-full p-8 mt-4 t4 text-evvy-black text-center bg-evvy-white border border-evvy-black">
              View Past Messages →
            </div>
          </Link>
        </>
      )}
    </div>
  );
};

export default StiTreatmentCard;

const LearnMoreModal = ({
  prescription,
  handleClose,
}: {
  prescription: Prescription;
  handleClose: () => void;
}) => {
  const {
    instructions,
    side_effects,
    dose_text,
    sti_management_of_partners,
    sti_preventing_future_infections,
  } = prescription;

  return (
    <div className="w-full bg-evvy-white px-4 py-8 md:p-8">
      <div className="flex justify-end items-center">
        <CloseX handleClose={handleClose} textColorClass="text-evvy-black" />
      </div>

      <div className="flex items-center my-4">
        <div>
          <div className="t1">
            {prescription.title.split("|").map((t) => (
              <div key={t}>{t}</div>
            ))}
          </div>
        </div>
      </div>

      <hr className="mt-8" />

      {prescription.description && (
        <div className="t1 medium mt-8 leading-5">
          <STIMarkdown>{prescription.description}</STIMarkdown>
        </div>
      )}

      <div className="sm:flex sm:space-x-12 mt-12">
        <div className="flex-1">
          <div className="t3 text-evvy-pine mb-4">Your Dose</div>
          <div className="leading-5">
            {dose_text && <div className="pb-1">{dose_text}</div>}
          </div>
        </div>
        <div className="flex-1 mt-12 sm:mt-0">
          <div className="t3 text-evvy-pine mb-4">Your Quantity</div>
          <div className="leading-5">{prescription.quantity_text}</div>
        </div>
      </div>

      {instructions && (
        <div>
          <hr className="mt-8" />

          <div className="t3 text-evvy-pine mb-4">How to use</div>
          <div className="space-y-4">
            <div className={`leading-5`}>
              {instructions.split("|").map((instruction, index) => (
                <STIMarkdown key={`instruction-desc-${index}`}>
                  {instruction}
                </STIMarkdown>
              ))}
            </div>
          </div>
        </div>
      )}
      {side_effects && (
        <div>
          <hr className="mt-8" />

          <div className="t3 text-evvy-pine mb-4">Side Effects</div>
          <div className="space-y-4">
            <div className={`leading-5`}>
              {side_effects.split("|").map((side_effect, index) => (
                <STIMarkdown key={`side-effect-desc-${index}`}>
                  {side_effect}
                </STIMarkdown>
              ))}
            </div>
          </div>
        </div>
      )}
      {sti_management_of_partners && (
        <div>
          <hr className="mt-8" />

          <div className="t3 text-evvy-pine mb-4">Managing Partners</div>
          <div className="space-y-4">
            <div className={`leading-5`}>
              {sti_management_of_partners.split("|").map((partner, index) => (
                <STIMarkdown key={`partner-desc-${index}`}>
                  {partner}
                </STIMarkdown>
              ))}
            </div>
          </div>
        </div>
      )}
      {sti_preventing_future_infections && (
        <div>
          <hr className="mt-8" />

          <div className="t3 text-evvy-pine mb-4">
            Preventing Future Infections
          </div>
          <div className="space-y-4">
            <div className={`leading-5`}>
              {sti_preventing_future_infections
                .split("|")
                .map((prevention, index) => (
                  <STIMarkdown key={`prevention-desc-${index}`}>
                    {prevention}
                  </STIMarkdown>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ProvidersNoteModal = ({
  note,
  handleClose,
}: {
  note: string;
  handleClose: () => void;
}) => {
  return (
    <div className="w-full bg-evvy-white px-4 py-8 md:p-8">
      <div className="flex justify-end items-center">
        <CloseX handleClose={handleClose} textColorClass="text-evvy-black" />
      </div>

      <div className="flex flex-col my-4">
        <div>
          <div className="t1">Provider's Note</div>
        </div>
        <hr className="mt-8" />

        <div className="leading-5 pb-4">
          <STIMarkdown>{note}</STIMarkdown>
        </div>
      </div>
    </div>
  );
};

const STIMarkdown = ({ children }: { children: string }) => {
  return (
    <Markdown
      options={{
        overrides: {
          li: {
            props: {
              className: "list-disc",
            },
          },
          ul: {
            props: {
              className: "my-0",
            },
          },
          p: {
            props: {
              className: "mb-2",
            },
          },
        },
      }}
    >
      {children}
    </Markdown>
  );
};
