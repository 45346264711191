import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import {
  eventNames,
  sendClickedTestCardShowProgress,
} from "../../utils/analytics/customEventTracking";
import LinkedText from "../common/linkedText";
import { ReactComponent as CaratDownIcon } from "../common/icons/carat-down.svg";
import { ReactComponent as CaratUpIcon } from "../common/icons/carat-up.svg";
import { ReactComponent as AlertTriangle } from "../common/icons/alert-triangle.svg";
import { ReactComponent as Info } from "../plan_unfurled/icons/info.svg";
import { testsService } from "../../services/tests";
import { StartStiConsultIntakeButton } from "../../pages/care/startStiConsultIntakeButton";
import Tippy from "@tippyjs/react";
import Markdown from "markdown-to-jsx";
import { getHealthHistoryURL } from "../../utils/healthHistory";

const FULL_RESULTS_SLA = "7-10 business days";
const INITIAL_RESULTS_SLA = "1-3 business days";
const ATTENTION_REQUIRED = "Attention Required";
const ESTIMATED_RESULTS_DATE_TOOLTIP_COPY = {
  preliminary:
    "Preliminary results will not impact the processing time of your final results (standard processing time for final results is 7-10 business days from arrival at the lab)",
  final:
    "This is the latest date we'd expect to return your final results, but often results come back earlier",
};

const DOWNLOAD_PDF_HELPER_TEXT =
  "**Note**: Please check your device Downloads for your preliminary results PDF.";

// represents a test with status of AC/TA/RC/PR/RD
// AC: Activated, TA: Taken, RC: Received, PR: Processing, RD: Ready
const TestCard = ({ test, testIndex, refetch }) => {
  const [showSteps, setShowSteps] = useState(false);
  const [initialReportPDFUrl, setInitialReportPDFUrl] = useState(null);
  const [stiPositive, setStiPositive] = useState(false);

  // state for whether they just clicked the download button
  const [downloadedPDF, setDownloadedPDF] = useState(false);

  // complete if submitted sample and has saved last screen of health history
  const finishedHealthHistory =
    test.healthcontext && test.healthcontext.is_complete;
  const receiveDate =
    test.sample_received_at_lab_date &&
    new Date(test.sample_received_at_lab_date);
  const estimateDate =
    test.estimated_results_ready_dates?.vngs_results &&
    new Date(test.estimated_results_ready_dates.vngs_results);
  const initialResultsEstimateDate =
    test.has_vpcr_test &&
    test.estimated_results_ready_dates?.vpcr_results &&
    new Date(test.estimated_results_ready_dates.vpcr_results);

  // should show the progress steps
  const showProgress = !["DA"].includes(test.status);

  // colors & cards used
  const orangeCard = "/images/graphics/card-header-orange.jpg";
  const purpleCard = "/images/graphics/card-header-purple.jpg";
  const greenCard = "/images/graphics/card-header-green.jpg";
  const grayCard = "/images/graphics/card-header-gray.png";
  const coralColor = "bg-coral";
  const purpleColor = "bg-highlighter-purple";
  const mintColor = "bg-highlighter-mint";
  const dullColor = "bg-evvy-black-dull";

  // local storage key for tracking if already clicked
  const clickedViewFullResultsKey = `test-results-clicked-${test.hash}`;
  const clickedViewInitialResultsKey = `test-initial-results-clicked-${test.hash}`;

  // Use Effects
  useEffect(() => {
    async function fetchInitialResults() {
      try {
        const response = await testsService.fetchTestInitialResults(test.hash);
        setInitialReportPDFUrl(response.data.report_url);
        setStiPositive(response.data.sti_positive);
      } catch (error) {
        console.error(error);
        console.error("Error fetching initial results");
      }
    }

    // fetch initial results if test is in IR status
    if (test.status === "IR") {
      fetchInitialResults();
    }
  }, [test]);

  // construct steps (most but not all are statuses)
  var steps = [
    { key: "AC", title: "Test activated", card: orangeCard, color: coralColor },
    // {'key': 'AP', 'title': 'Test Approved', 'card': purpleCard, 'color': purpleColor}, // show as taken
    { key: "TA", title: "Sample taken", card: purpleCard, color: purpleColor },
    {
      key: "TA-H",
      title: "Health forms complete",
      card: purpleCard,
      color: purpleColor,
    },
    {
      key: "TR",
      title: "Sample in transit",
      card: purpleCard,
      color: purpleColor,
    },
    {
      key: "RC",
      title: "Sample received by lab",
      card: purpleCard,
      color: purpleColor,
    },
  ];
  if (test.has_vpcr_test) {
    steps.push(
      {
        key: "IR", // "IR" = "Preliminary Results"
        title: `Preliminary results in ${INITIAL_RESULTS_SLA}`,
        card: purpleCard,
        color: purpleColor,
      },
      {
        key: "RD",
        title: `Final results in ${FULL_RESULTS_SLA}`,
        card: greenCard,
        color: mintColor,
      }
    );
  } else {
    steps.push({
      key: "RD",
      title: `Results in ${FULL_RESULTS_SLA}`,
      card: greenCard,
      color: mintColor,
    });
  }

  steps = steps.map((s) => {
    // fill out the "current step" based on status
    s.currentStep = s.key === test.status; // default if we're on the step

    // treat AP same as TA, and override to TA-H if they filled out health history
    if (
      ["TA", "AP"].includes(test.status) &&
      s.key === "TA" &&
      finishedHealthHistory
    ) {
      s.currentStep = false; // If finished health history, current step should be TA-H, not TA
    } else if (
      ["TA", "AP"].includes(test.status) &&
      s.key === "TA-H" &&
      finishedHealthHistory
    ) {
      s.currentStep = true; // If finished health history, current step should be TA-H, not TA
    } else if (["AP"].includes(test.status) && s.key === "TA") {
      s.currentStep = true; // treat "AP" same as "TA"
    }

    // if status is processing/sequencing, just make same as received
    if (["PR", "SQ"].includes(test.status) && s.key === "RC") {
      s.currentStep = true;
    }
    return s;
  });
  steps = steps.map((s, i) => {
    // determine if a step is complete based on it being before/after current step
    const lastCompleteStepKey =
      steps.filter((s) => s.currentStep)[0] &&
      steps.filter((s) => s.currentStep)[0].key;
    const currentStepIndex = steps
      .map((s) => s.key)
      .indexOf(lastCompleteStepKey);
    s.complete = i <= currentStepIndex;
    return s;
  });
  steps = steps.map((s, i) => {
    // update title with specifics if that step requires it
    if (s.key === "RC" && test.sample_received_at_lab_date) {
      s.title = `Sample received by lab on ${
        receiveDate.getMonth() + 1
      }/${receiveDate.getDate()}/${receiveDate.getFullYear()}`;
    } else if (
      s.key === "TA" &&
      s.complete &&
      test.healthcontext &&
      test.healthcontext.sample_taken_at
    ) {
      s.title = `Sample taken on ${test.healthcontext.sample_taken_at}`;
    } else if (s.key === "IR" && s.complete) {
      s.title = "Preliminary results ready";
    } else if (s.key === "RD" && s.complete) {
      if (test.has_vpcr_test) {
        s.title = "Final results ready";
      } else {
        s.title = "Results ready";
      }
    } else if (s.key === "TA-H" && !finishedHealthHistory && s.complete) {
      s.title = `Health forms incomplete`;
    }
    return s;
  });
  steps = steps.map((s, i) => {
    // determine if there's a skipped step
    if (s.key === "TA-H" && !finishedHealthHistory && s.complete) {
      // highlight it red if we've progressed past health history step but it's not complete
      s.skippedStep = true;
    }
    return s;
  });
  var currentStep = steps.filter((s) => s.currentStep)[0];
  if (!currentStep) {
    // most likely scenario is that it's deactivated (or we added a new status that is not mapped to a step)
    currentStep = {
      key: test.status,
      title: test.get_status_display,
      card: grayCard,
      color: dullColor,
    };
  }

  const getActionButtonStyles = (test) => {
    // some action buttons are primary, some are secondary.
    var isPrimary = false;
    if (!finishedHealthHistory && !["DA"].includes(test.status)) {
      isPrimary = true; // if havent filled out, always make it pri
    }

    // if sample is in transit and there is tracking info, make it primary
    if (test.status === "TR" && test.sample_tracking_link) {
      isPrimary = true;
    }

    //  if test is in initial results status and we haven't clicked on the initial results, then make it primary
    if (
      test.status === "IR" &&
      !localStorage.getItem(clickedViewInitialResultsKey)
    ) {
      isPrimary = true;
    }

    if (test.status === "RD") {
      if (testIndex === 0 && !localStorage.getItem(clickedViewFullResultsKey)) {
        isPrimary = true; // if top test is results ready, and we haven't clicked on it, it's primary
      }
    }

    // style button accordingly
    if (isPrimary) {
      return "w-full bg-evvy-blue text-black hover:bg-evvy-pine hover:text-white mt-auto p-6 font-semibold tracking-wider focus:outline-none";
    } else {
      return "w-full bg-white border border-black hover:bg-evvy-black hover:text-white mt-auto p-6 font-semibold tracking-wider focus:outline-none";
    }
  };

  const getHealthHistoryLink = () => {
    // V2: No health context yet and sample not taken, navigate to V2 intro page
    if (
      !test.healthcontext ||
      (test.healthcontext &&
        test.healthcontext.is_version_2 &&
        !test.healthcontext.sample_taken_at)
    ) {
      return getHealthHistoryURL(test.hash, "intro");
    } else if (
      // V2: health context exists and is V2, sample taken already, navigate to health profile
      test.healthcontext &&
      test.healthcontext.sample_taken_at &&
      test.healthcontext.is_version_2
    ) {
      return `/tests/${test.hash}/health-history/`;
      // V1: health context exists and sample taken, navigate to health context start
    } else if (test.healthcontext && test.healthcontext.sample_taken_at) {
      return `/tests/${test.hash}/history/continue/`;
    } else {
      // V1: should not hit this condition, but navigate to health context start
      return `/tests/${test.hash}/context`;
    }
  };

  return (
    <div key={test.hash} className="px-6 py-6 sm:px-8">
      {!finishedHealthHistory && !["AC", "DA"].includes(test.status) ? (
        <div className="p-2 text-center font-medium text-sm rounded-md bg-coral mb-6">
          Your health forms must be completed to process your results
        </div>
      ) : (
        ""
      )}

      {stiPositive && (
        <div className="flex">
          <div className="bg-coral px-2 py-1 text-xs tracking-wider font-medium uppercase rounded-full mb-4 ml-0">
            <div className="flex my-auto items-center">
              <AlertTriangle className="stroke-evvy-black h-4 w-5 mr-1" />
              <div>{ATTENTION_REQUIRED}</div>
            </div>
          </div>
        </div>
      )}

      {/* state display */}
      <div className="">
        <div className="flex">
          <div className="order-2 sm:order-1">
            {currentStep.card ? (
              <div className="rounded-lg mr-0 sm:mr-6 ml-6 sm:ml-0">
                <img
                  src={currentStep.card}
                  alt="Evvy Test"
                  className="rounded-lg h-14 w-14 sm:h-14 sm:w-14 object-cover"
                />
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="order-1 sm:order-2 flex-grow sm:grow-0 space-y-3 my-auto">
            <label className="uppercase text-sm font-semibold tracking-wider text-evvy-black opacity-75">
              Test ID
            </label>
            <div className="whitespace-nowrap font-semibold uppercase">
              {test.pretty_hash}
            </div>
          </div>
          {test.healthcontext &&
            test.healthcontext.health_history_submitted_at && (
              <div className="hidden sm:block order-3 ml-20 space-y-3 my-auto">
                <label className="uppercase text-sm font-semibold tracking-wider text-evvy-black opacity-75">
                  Health History
                </label>
                <div>
                  <Link
                    to={
                      test.healthcontext.is_version_2
                        ? `/tests/${test.hash}/answers/`
                        : `/tests/${test.hash}/responses/`
                    }
                    className="underline medium persistSize cursor-pointer inline-block"
                    onClick={analyticsClickHandler({
                      eventName: eventNames.CLICKED_TEST_CARD_VIEW_CONTEXT,
                      eventArgs: {
                        testOrder: test.test_order,
                        testHash: test.hash,
                        testStatus: test.get_status_display,
                      },
                    })}
                  >
                    View
                  </Link>
                </div>
              </div>
            )}
        </div>
        <div className="flex justify-between mt-5 sm:mt-7">
          <div>
            <label className="uppercase text-sm font-semibold tracking-wider text-evvy-black opacity-75">
              Test Status
            </label>
            <div className="mt-3 block sm:flex">
              <div className="text-[15px]">
                {showSteps ? (
                  <div className="space-y-6">
                    {steps.map((s, i) => (
                      <div key={s.key} className="flex items-center">
                        {/* Each of the steps, colored based on where they are in the flow & their completeless state */}
                        <div className="mr-2 relative">
                          {i !== 0 ? (
                            <div
                              className={`absolute z-0 h-12 w-1 left-1 bottom-0 ${
                                s.complete ? currentStep.color : "bg-dust"
                              }`}
                            ></div>
                          ) : (
                            ""
                          )}

                          {s.complete && !s.skippedStep ? (
                            <div
                              className={`z-10 relative w-3 h-3 rounded-full ${currentStep.color}`}
                            ></div>
                          ) : s.complete && s.skippedStep ? (
                            <div
                              className={`z-10 relative w-3 h-3 border-2 border-coral bg-white rounded-full`}
                            />
                          ) : (
                            <div
                              className={`z-10 relative w-3 h-3 border-2 bg-white rounded-full`}
                            />
                          )}
                        </div>

                        <div
                          className={`font-semibold ${
                            s.complete ? "" : "opacity-50"
                          }`}
                        >
                          {s.title}
                          {s.key == "TA-H" && finishedHealthHistory && (
                            <span className="text-xs sm:hidden">
                              {" ("}
                              <Link
                                to={
                                  test.healthcontext.is_version_2
                                    ? `/tests/${test.hash}/answers/`
                                    : `/tests/${test.hash}/responses/`
                                }
                                className="sm:hidden underline underline-offset-1 cursor-pointer inline-block my-auto"
                                onClick={analyticsClickHandler({
                                  eventName:
                                    eventNames.CLICKED_TEST_CARD_VIEW_CONTEXT,
                                  eventArgs: {
                                    testOrder: test.test_order,
                                    testHash: test.hash,
                                    testStatus: test.get_status_display,
                                  },
                                })}
                              >
                                {"view"}
                              </Link>
                              {")"}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex space-x-2 items-center">
                    <span
                      className={`w-3 h-3 rounded-full ${
                        currentStep.color || "bg-evvy-black-dull"
                      }`}
                    ></span>
                    <span className="font-semibold">{currentStep.title}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Show steps if not deactivated/ready */}
          {showProgress ? (
            <div
              className="mt-4 sm:mt-2 cursor-pointer"
              onClick={() => {
                setShowSteps(!showSteps);
                if (!showSteps) {
                  // fire event only on open, not close
                  sendClickedTestCardShowProgress({
                    testOrder: test.test_order,
                    testHash: test.hash,
                    testStatus: test.get_status_display,
                  });
                }
              }}
            >
              {showSteps ? (
                <CaratUpIcon className="h-6 w-6 sm:h-8 sm:w-8" />
              ) : (
                <CaratDownIcon className="h-6 w-6 sm:h-8 sm:w-8" />
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* CTA button section */}
      <div className="mt-6">
        {test.status === "AC" ? (
          <>
            <Link
              to={getHealthHistoryLink()}
              onClick={analyticsClickHandler({
                eventName: eventNames.CLICKED_TEST_CARD_CTA,
                eventArgs: {
                  testOrder: test.test_order,
                  testHash: test.hash,
                  testStatus: test.get_status_display,
                  ctaText: "Take sample",
                },
              })}
            >
              <button className={getActionButtonStyles(test)}>
                Take sample →
              </button>
            </Link>
          </>
        ) : !finishedHealthHistory ? (
          <>
            <Link
              to={getHealthHistoryLink()}
              onClick={analyticsClickHandler({
                eventName: eventNames.CLICKED_TEST_CARD_CTA,
                eventArgs: {
                  testOrder: test.test_order,
                  testHash: test.hash,
                  testStatus: test.get_status_display,
                  ctaText: "Complete health forms",
                },
              })}
            >
              <button className={getActionButtonStyles(test)}>
                Complete health forms →
              </button>
            </Link>
          </>
        ) : test.status === "IR" && initialReportPDFUrl ? (
          <div className="flex flex-col gap-4">
            {test.can_open_sti_treatment_consult && (
              <StartStiConsultIntakeButton
                ctaPrimary
                testHash={test.hash}
                location="testCard"
              />
            )}
            <a
              href={initialReportPDFUrl}
              className="w-full"
              onClick={analyticsClickHandler({
                eventName: eventNames.CLICKED_TEST_CARD_CTA,
                eventArgs: {
                  testOrder: test.test_order,
                  testHash: test.hash,
                  testStatus: test.get_status_display,
                  ctaText: "View Initial Results",
                },
                clickHandler: () => {
                  localStorage.setItem(clickedViewInitialResultsKey, true);
                  setDownloadedPDF(true);
                },
              })}
              rel="noreferrer"
            >
              <button className={getActionButtonStyles(test)}>
                View Preliminary Results
                <span className="text-[17px] serif semibold"> ↗</span>
              </button>
            </a>
            {downloadedPDF && (
              <div className="text-center text-sm text-evvy-black">
                <Markdown>{DOWNLOAD_PDF_HELPER_TEXT}</Markdown>
              </div>
            )}
          </div>
        ) : test.status === "RD" ? (
          <div className="space-y-3">
            <Link
              to={`/results/${test.hash}/`}
              onClick={() => {
                // fire event
                analyticsClickHandler({
                  eventName: eventNames.CLICKED_TEST_CARD_CTA,
                  eventArgs: {
                    testOrder: test.test_order,
                    testHash: test.hash,
                    testStatus: test.get_status_display,
                    ctaText: "View Results",
                  },
                })();
                // mark results as clicked for this test (to make action button not primary styled)
                localStorage.setItem(clickedViewFullResultsKey, true);
              }}
            >
              <button className={getActionButtonStyles(test)}>
                View Results →
              </button>
            </Link>
            {test.eligible_for_care && (
              <div className="text-center">
                <LinkedText
                  onClick={analyticsClickHandler({
                    eventName: eventNames.CLICKED_TEST_CARD_CTA,
                    eventArgs: {
                      testOrder: test.test_order,
                      testHash: test.hash,
                      testStatus: test.get_status_display,
                      ctaText: "get clinical care",
                    },
                  })}
                  overrideTextClass="text-sm font-semibold tracking-wider uppercase"
                  isLink
                  href={"/care/"}
                  noIcon
                  target="_self"
                >
                  Get clinical care
                </LinkedText>
              </div>
            )}
          </div>
        ) : test.status === "TR" && test.sample_tracking_link ? (
          <>
            <a
              href={test.sample_tracking_link}
              target="_blank"
              className="w-full"
              onClick={analyticsClickHandler({
                eventName: eventNames.CLICKED_TEST_SAMPLE_TRACKING_LINK,
                eventArgs: {
                  testOrder: test.test_order,
                  testHash: test.hash,
                  testStatus: test.get_status_display,
                },
              })}
            >
              <button className={getActionButtonStyles(test)}>
                Track Sample
                <span className="text-[17px] serif semibold"> ↗</span>
              </button>
            </a>
          </>
        ) : (
          ""
        )}

        {/* additional links & messages section after sample is taken */}
        {test.status !== "AC" ? (
          <>
            {["RC", "IR", "SQ", "PR"].includes(test.status) &&
            (estimateDate || initialResultsEstimateDate) ? (
              <div className="mt-4">
                <div className="mb-4 bg-evvy-cream rounded-md p-2 font-semibold tracking-wider uppercase text-sm text-center flex justify-center">
                  {["RC", "SQ"].includes(test.status) &&
                  initialResultsEstimateDate ? (
                    <>
                      Preliminary results ready by{" "}
                      {initialResultsEstimateDate.getMonth() + 1}/
                      {initialResultsEstimateDate.getDate()}{" "}
                      <EstimatedResultsDateTooltip
                        tooltipCopy={
                          ESTIMATED_RESULTS_DATE_TOOLTIP_COPY.preliminary
                        }
                      />
                    </>
                  ) : (
                    <>
                      Final results ready by {estimateDate.getMonth() + 1}/
                      {estimateDate.getDate()}{" "}
                      <EstimatedResultsDateTooltip
                        tooltipCopy={ESTIMATED_RESULTS_DATE_TOOLTIP_COPY.final}
                      />
                    </>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const EstimatedResultsDateTooltip = ({ tooltipCopy }) => {
  return (
    <span className="my-auto">
      <Tippy
        arrow
        render={() => (
          <div
            id="tooltip"
            role="tooltip"
            className="flex bg-evvy-pine p-3 rounded-md max-w-sm"
            tabIndex={-1}
          >
            <div className="text-evvy-white">{tooltipCopy}</div>
          </div>
        )}
        animation={false}
      >
        <Info
          stroke="currentColor"
          fill="currentColor"
          className="ml-1.5 w-3.5 h-3.5 text-evvy-black-dull/75"
        />
      </Tippy>
    </span>
  );
};

export default TestCard;
