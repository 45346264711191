const freebies = [
  {
    imgSrc: "/images/graphics/phone-treatment-plan.png",
    altText: "1:1 Call with an Expert Health Coach",
    desktopText: "Daily Treatment Plan Designed by a Provider",
    mobileText: "Provider-Designed Treatment Calendar",
    price: "$30",
  },
  {
    imgSrc: "/images/graphics/health-coach-call-square.png",
    altText: "1:1 Call with an Expert Health Coach",
    desktopText: "1:1 Call with an Expert Health Coach",
    mobileText: "1:1 Coaching Call",
    price: "$30",
  },
  {
    imgSrc: "/images/graphics/evvy-travel-bag.png",
    altText: "Exclusive Evvy Travel Bag",
    desktopText: "Exclusive Evvy Travel Bag",
    mobileText: "Versatile Travel Bag",
    price: "$25",
  },
];

const BundleFreebies = () => (
  <div className="flex flex-wrap gap-y-4  sm:gap-x-4">
    {freebies.map((item, index) => (
      <div
        key={index}
        className="text-xs font-medium flex flex-row items-center max-w-[238px]"
      >
        <img
          className="w-[90px] h-[90px] rounded-lg flex-shrink-0"
          loading="lazy"
          src={item.imgSrc}
          alt={item.altText}
        />
        <div className="ml-4">
          <span className="hidden md:block">{item.desktopText}</span>
          <span className="block md:hidden">{item.mobileText}</span>
          <div className="opacity-50">
            Free
            <span className="ml-1 line-through">{item.price}</span>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default BundleFreebies;
