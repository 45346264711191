import { useState } from "react";
import { useNavigate } from "react-router-dom";

// hooks
import { useLatestReadyTest } from "../../hooks/test/useLatestReadyTest";
import usePersistedCart from "../../hooks/cart/usePersistedCart";

// types
import {
  AddCartItemPayload,
  Cart,
  CART_ITEM_TYPE,
  CartItem,
} from "../../types/cart";
import { Test } from "../../types/test";
import { Prescription } from "../../types/care";

// images
import CarePackagingImg from "../care/images/care-packaging-no-bg.png";
import RainingPrescriptionsImg from "../care/images/raining-prescriptions.png";

// components
import { EvvyMarkdown } from "../common/evvyMarkdown";
import RectangularButton from "../common/rectangularButton";
import Panel from "../common/panel";
import LoadingSpinner from "../common/loadingSpinner";
import BlueRectangularButton from "../common/blueRectangularButton";
import { ReactComponent as XIcon } from "../common/icons/X.svg";
import LinkedText from "../common/linkedText";

// services
import { cartService } from "../../services/cart";

// utils
import {
  getTreatmentImageFromFormFactor,
  sumAndFormatCartItemsToPriceString,
} from "../care/utils";
import { cn } from "../../utils/cn";
import ErrorBanner from "../common/errorBanner";
import { Link } from "react-router-dom";
import { A_LA_CARE_SHOP_TREATMENTS_PAGE_PATH } from "../care/constants";

const UserCart = ({
  cart,
  closeCart,
  addToCart,
  removeFromCart,
}: {
  cart?: Cart;
  closeCart: () => void;
  addToCart: (items: AddCartItemPayload[]) => void;
  removeFromCart: (items: CartItem[]) => void;
}) => {
  const { test } = useLatestReadyTest();
  const cartIsEmpty = !cart || cart.line_items.length === 0;

  if (cartIsEmpty) {
    return (
      <EmptyState
        cart={cart}
        test={test}
        closeCart={closeCart}
        addToCart={addToCart}
      />
    );
  }
  // TODO: non-empty states!
  return (
    <FullCartState
      cart={cart}
      test={test}
      closeCart={closeCart}
      addToCart={addToCart}
      removeFromCart={removeFromCart}
    />
  );
};

const FullCartState = ({
  cart,
  test,
  closeCart,
  addToCart,
  removeFromCart,
}: {
  cart: Cart;
  test?: Test;
  closeCart: () => void;
  addToCart: (items: AddCartItemPayload[]) => void;
  removeFromCart: (items: CartItem[]) => void;
}) => {
  // state
  const [submitting, setSubmitting] = useState<boolean>(false);

  const CART_DISCLAIMER =
    "*Your specific prescriptions will be determined at the sole discretion of an Evvy-affiliated provider.";

  const hasVisitedShopTreatmentsPage = localStorage.getItem(
    `visited-shop-treatments-${test?.hash}`
  )
    ? true
    : false;

  const bundleItems = cart.line_items.filter(
    (item) => item.item_type === "bundle-treatment"
  );

  const individualItems = cart.line_items.filter(
    (item) => item.item_type === "individual-treatment"
  );

  const showEligibleForMoreWarningBanner =
    bundleItems.length === 0 && !hasVisitedShopTreatmentsPage;

  //   TODO: checkout functionality

  return (
    <div className="">
      {/* cart items */}
      <div className="px-4 md:px-8 pb-8">
        {/* show bundle items if exist */}
        {bundleItems.length > 0 && (
          <BundleCartItem
            items={bundleItems}
            removeFromCart={removeFromCart}
            disabled={submitting}
          />
        )}
        {individualItems.length > 0 && (
          <div className={cn({ "mt-7": bundleItems.length > 0 })}>
            {/* show individual items if exist */}
            {individualItems.map((item) => (
              <ALaCareCartItem
                key={`alc-${item.id}`}
                item={item}
                removeFromCart={removeFromCart}
                disabled={submitting}
              />
            ))}
          </div>
        )}
        {showEligibleForMoreWarningBanner && (
          <ErrorBanner parentAdditionalClassName="mt-6 md:mt-4">
            <span className="b2 medium">
              You’re eligible for{" "}
              <Link
                to={A_LA_CARE_SHOP_TREATMENTS_PAGE_PATH}
                // TODO: analytics
                onClick={
                  () => {}
                  //   sendTreatmentCartClickedViewMoreProducts({})
                }
                className="underline persistSize"
              >
                more Rx products
              </Link>
              .
            </span>
          </ErrorBanner>
        )}
      </div>
      {/* checkout button pinned to bottom of parent container*/}
      <div className="fixed bottom-0 left-0 right-0 px-4 md:px-8 py-5 border-t border-evvy-dark-beige">
        {/* subtotal */}
        <div className="flex justify-between text-sm md:text-base medium mb-2.5">
          <div className="">Subtotal</div>
          {/* format number as price */}
          <div>{sumAndFormatCartItemsToPriceString(cart.line_items)}</div>
        </div>
        <div className="text-sm md:text-base mb-5">
          Shipping & taxes calculated at checkout
        </div>
        <BlueRectangularButton
          text="complete order ->"
          fullWidth
          paddingXClass="px-5"
          paddingYClass="py-4"
          // TODO: analytics + functionality
          //   onClick={addBundleToCart}
        />
        <div className="text-sm mt-2 font-medium">{CART_DISCLAIMER}</div>
      </div>
    </div>
  );
};

// TODO: more styling
const ALaCareCartItem = ({
  item,
  removeFromCart,
  disabled,
}: {
  item: CartItem;
  removeFromCart: (items: CartItem[]) => void;
  disabled?: boolean;
}) => {
  const treatment = item.treatment_product;
  return (
    <div className="flex justify-between">
      <div className="flex space-x-4">
        <div className="bg-evvy-dark-cream rounded-lg h-14 w-14 md:h-16 md:w-16 relative">
          <img
            className={cn("max-w-14 max-h-14 object-cover mx-auto absolute", {
              "bottom-0 inset-x-0": treatment.form_factor === "cream",
              "bottom-4": treatment.form_factor !== "cream",
            })}
            src={getTreatmentImageFromFormFactor(treatment.form_factor)}
            alt="care packaging"
          />
        </div>
        <div className="my-auto">
          <div className="b2 my-auto">{treatment.preview_title}</div>
        </div>
      </div>
      <div className="flex my-auto">
        <div className="my-auto">
          {`$${Number(treatment.bundle_variant.price)}`}
        </div>
        <button onClick={() => removeFromCart([item])} disabled={disabled}>
          <XIcon />
        </button>
      </div>
    </div>
  );
};

// TODO: more styling
const BundleCartItem = ({
  items,
  removeFromCart,
  disabled,
}: {
  items: CartItem[];
  removeFromCart: (items: CartItem[]) => void;
  disabled?: boolean;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const fullPrice = items.reduce(
    (acc, item) =>
      acc + Number(item.treatment_product.ecomm_product?.price || 0),
    0
  );
  const bundlePrice = items.reduce(
    (acc, item) =>
      acc + Number(item.treatment_product.bundle_variant?.price || 0),
    0
  );

  return (
    <div className="rounded-2xl bg-evvy-white p-4 md:p-5">
      <div className="flex justify-between">
        <div className="flex space-x-4">
          <div className="bg-evvy-dark-cream rounded-lg h-14 w-14 md:h-24 md:w-24 flex">
            <img
              className="h-8 md:h-14 my-auto mx-auto object-cover"
              src={CarePackagingImg}
              alt="care packaging"
            />
          </div>
          <div className="medium my-auto pr-3">Complete Treatment Program</div>
        </div>
        {/* price and remove button */}
        <div className="flex justify-between my-auto">
          <div className="my-auto">{`$${bundlePrice}`}</div>
          <div className=" text-evvy-black/50 ml-1 my-auto">
            <EvvyMarkdown>{`~~$${fullPrice}~~`}</EvvyMarkdown>
          </div>
          <button onClick={() => removeFromCart(items)} disabled={disabled}>
            <XIcon />
          </button>
        </div>
      </div>
      <div className="border-t border-dashed border-evvy-black/20 my-5" />
      {/* show expanded items if expanded */}
      {expanded && (
        <div className="space-y-4">
          {items.map((item) => (
            <IndividualBundleItem
              key={item.id}
              treatment={item.treatment_product}
            />
          ))}
        </div>
      )}
      <LinkedText
        onClick={() => setExpanded(!expanded)}
        noIcon
        noTopMargin
        href=""
      >
        {expanded ? "Hide what's included*" : "Show what's included*"}
      </LinkedText>
    </div>
  );
};

const IndividualBundleItem = ({ treatment }: { treatment: Prescription }) => {
  return (
    <div className="flex justify-between">
      <div className="flex space-x-4">
        <div className="bg-evvy-dark-cream rounded-lg h-14 w-14 md:h-16 md:w-16 relative">
          <img
            className={cn("max-w-14 max-h-14 object-cover mx-auto absolute", {
              "bottom-0 inset-x-0": treatment.form_factor === "cream",
              "bottom-4": treatment.form_factor !== "cream",
            })}
            src={getTreatmentImageFromFormFactor(treatment.form_factor)}
            alt="care packaging"
          />
        </div>
        <div className="my-auto">
          <div className="b2 my-auto">{treatment.preview_title}</div>
          <div className="text-evvy-black/50 text-sm">
            <EvvyMarkdown>{`$${Number(
              treatment.bundle_variant.price
            )} ~~$${Number(treatment.ecomm_product.price)}~~`}</EvvyMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

const EmptyState = ({
  cart,
  test,
  closeCart,
  addToCart,
}: {
  cart?: Cart;
  test?: Test;
  closeCart: () => void;
  addToCart: (items: AddCartItemPayload[]) => void;
}) => {
  const navigate = useNavigate();
  const eligibleForProgramCare = test?.eligible_for_care;
  const closeCartAndGoToCare = () => {
    closeCart();
    // navigate to care
    navigate("/care/");
  };

  // TODO: replace with true add bundle to cart functionality
  const addBundleToCart = () => {
    const itemsToAdd = [
      {
        item_type: "bundle-treatment" as CART_ITEM_TYPE,
        quantity: 1,
        treatment_product_id: 1,
      },
      {
        item_type: "bundle-treatment" as CART_ITEM_TYPE,
        quantity: 1,
        treatment_product_id: 2,
      },
      {
        item_type: "bundle-treatment" as CART_ITEM_TYPE,
        quantity: 1,
        treatment_product_id: 3,
      },
    ];
    addToCart(itemsToAdd);
  };

  return eligibleForProgramCare ? (
    <EmptyStateProgramCare
      closeCartAndGoToCare={closeCartAndGoToCare}
      addBundleToCart={addBundleToCart}
    />
  ) : (
    <EmptyStateALaCare closeCartAndGoToCare={closeCartAndGoToCare} />
  );
};

const EmptyStateALaCare = ({
  closeCartAndGoToCare,
}: {
  closeCartAndGoToCare: () => void;
}) => {
  return (
    <>
      <div className="space-y-6 md:space-y-8 pb-5 md:pb-7 px-4 md:px-7">
        <div className="b1 medium md:text-lg/[24px] text-center">
          Looking kind of empty in here.<br></br>We recommend:
        </div>
        <div className="rounded-2xl md:mx-3">
          <div className="h-52 bg-evvy-silverfish rounded-t-2xl">
            <div className="relative h-full">
              <img
                className="h-44 mx-auto object-fill cursor-pointer rounded-t-2xl"
                src={RainingPrescriptionsImg}
                onClick={closeCartAndGoToCare}
              />
              <div className="absolute right-0 left-0 top-20 bottom-0 bg-gradient-to-b from-transparent to-evvy-white pointer-events-none"></div>
            </div>
          </div>
          <div className="bg-evvy-white px-4 md:px-7 space-y-6 pb-4 md:pb-7 rounded-b-2xl">
            <div>
              <h4 className="md:text-2xl mb-2">Individual Rx Treatments</h4>
              <div className="text-evvy-black/50">
                Customized plan for your unique symptoms and needs
              </div>
            </div>
            <RectangularButton
              //   TODO: analytics
              onClick={() => {
                // close panel
                closeCartAndGoToCare();
              }}
              text={"Browse all care ->"}
              textColorClass="text-evvy-black"
              fullWidth
              bgColorClass="bg-transparent"
              borderColor="border-evvy-black"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const EmptyStateProgramCare = ({
  closeCartAndGoToCare,
  addBundleToCart,
}: {
  closeCartAndGoToCare: () => void;
  addBundleToCart: () => void;
}) => {
  return (
    <>
      <div className="space-y-6 md:space-y-8 pb-5 md:pb-7 px-4 md:px-7">
        <div className="b1 medium md:text-lg/[24px] text-center">
          Looking kind of empty in here.<br></br>We recommend:
        </div>
        <div className="rounded-2xl md:mx-3">
          <div className="h-60 overflow-hidden bg-evvy-dark-cream rounded-t-2x pt-6">
            <img
              className="mx-auto object-cover h-44 cursor-pointer"
              src={CarePackagingImg}
              onClick={closeCartAndGoToCare}
            />
          </div>
          <div className="-mt-14 h-14 bg-gradient-to-b from-transparent to-evvy-white" />
          <div className="bg-evvy-white px-4 md:px-7 space-y-6 pb-4 md:pb-7 rounded-b-2xl">
            <div>
              <h4 className="md:text-2xl mb-2">
                The Complete Treatment Program
              </h4>
              <div>Customized plan for your unique symptoms and needs</div>
            </div>
            <BlueRectangularButton
              text=""
              fullWidth
              paddingXClass="px-5"
              paddingYClass="py-4"
              // TODO: analytics + functionality
              onClick={addBundleToCart}
            >
              <div className="flex justify-between">
                <div>Add to cart</div>
                {/* TODO: replace with actual dynamic price from bundle */}
                <div>
                  $249
                  <span className="ml-1 text-evvy-black/50">
                    <EvvyMarkdown>~~$299~~</EvvyMarkdown>
                  </span>
                </div>
              </div>
            </BlueRectangularButton>
          </div>
        </div>
      </div>
      <div className="border-t border-evvy-dark-beige py-5">
        <RectangularButton
          //   TODO: analytics
          onClick={() => {
            // close panel
            closeCartAndGoToCare();
          }}
          text={"Browse all care ->"}
          textColorClass="text-evvy-black"
          fullWidth
          bgColorClass="bg-transparent"
        />
      </div>
    </>
  );
};

export const PanelCartComponent = ({
  cart,
  showCart,
  setShowCart,
  isLoading,
  addToCart,
  removeFromCart,
}: {
  cart?: Cart;
  showCart: boolean;
  setShowCart: (show: boolean) => void;
  isLoading: boolean;
  addToCart: (items: AddCartItemPayload[]) => void;
  removeFromCart: (items: CartItem[]) => void;
}) => {
  return (
    <Panel handleClose={() => setShowCart(false)} isOpen={showCart}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <UserCart
          cart={cart}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
          closeCart={() => setShowCart(false)}
        />
      )}
    </Panel>
  );
};
