import React, { useEffect, useState } from "react";

// services
import { careService } from "../../services/care";

// components
import LoadingSpinner from "../common/loadingSpinner";

const TreatmentPreview = ({ consult, isMobile }) => {
  /*
   * State
   */
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const isALaCare = consult.purchase_type === "a-la-care";

  return (
    <div className="mt-8">
      <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500">
        Your treatment
      </div>
      <div className={`${isMobile ? "b2" : "b1"} regular mb-4`}>
        {isALaCare
          ? "An Evvy-affiliated provider has reviewed your treatment request."
          : `Your ${consult.plan_type} program was designed by an Evvy-affiliated
        provider based on your results and health history.`}
      </div>
      <div className={`${isMobile ? "b2" : "b1"} regular`}>
        Treatments will be shipped directly to you and should arrive within 3-5
        days.{" "}
        {consult?.prescription_tracking_link ? (
          <span>
            Click{" "}
            <a
              className="underline persistSize"
              target="_blank"
              href={consult.prescription_tracking_link}
            >
              here
            </a>{" "}
            to track your order!
          </span>
        ) : (
          <span>
            Reach out to{" "}
            <a className="underline persistSize" href="mailto:support@evvy.com">
              support@evvy.com
            </a>{" "}
            if you have any questions!
          </span>
        )}
      </div>
    </div>
  );
};

export default TreatmentPreview;
