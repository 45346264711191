import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Scrollama, Step } from "react-scrollama";
import Markdown from "markdown-to-jsx";
import { AxiosError } from "axios";

import Layout from "../../components/layout";
import StickyHeader from "../../components/common/stickyHeader";
import {
  SamplePlanIntroDesktop,
  SamplePlanTreatmentsDesktop,
  SamplePlanCalendarDesktop,
  SamplePlanSupportDesktop,
  SamplePlanIntroMobile,
  SamplePlanTreatmentsMobile,
  SamplePlanCalendarMobile,
  SamplePlanSupportMobile,
} from "../../components/care/images/";
import LightBlueGreenGradient from "../../components/common/images/light-blue-green-gradient.png";
import { ReactComponent as DoctorIcon } from "../../components/common/icons/doctor.svg";
import RectangularButton from "../../components/common/rectangularButton";

import { getIsMobile } from "../../utils/general";
import { Test } from "../../types/test";
import { Consult } from "../../types/care";
import { careService } from "../../services/care";
import { testsService } from "../../services/tests";
import {
  sendSamplePlanViewPage,
  sendSamplePlanClickedBack,
  sendSamplePlanClickedGetCare,
  sendSamplePlanViewedSection,
} from "../../utils/analytics/customEventTracking";
import { BaseAnalyticsArgsContext } from "../../contexts/baseAnalyticsArgsContext";
import { useBaseAnalyticsArgs } from "../../hooks/useBaseAnalyticsArgs";

const STEPS = [
  {
    title: "At-a-glance",
    href: "#glance",
    description:
      "A note from your provider gives you an overview of the treatment program.",
  },
  {
    title: "Treatment List",
    href: "#treatments",
    description:
      "This is just a sample treatment list — yours will be custom to your unique microbiome and may include different things. Thorough instructions and reasons each one was prescribed are included!",
  },
  {
    title: "Treatment Calendar",
    href: "#calendar",
    description:
      "A dynamic calendar tells you when to take your treatments each day. You can download it and customize as needed!",
  },
  {
    title: "Continuous Support",
    href: "#support",
    description:
      "Most of your questions can be answered within your treatment program. If you still need help, our certified health coaches are there with you every step of the way.",
  },
];
const SUBTITLE = "Clinical care with Evvy";
const TITLE_MD = "Preview a sample<br>treatment program";
const PRIMARY_CTA_COPY = "Get clinical care";

export const SampleTreatmentPlan = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [test, setTest] = useState<Test>();
  const [consult, setConsult] = useState<Consult>();
  const [baseAnalyticsArgs, setBaseAnalyticsArgs] = useState({} as any);

  const isMobile = getIsMobile();

  // nav params
  const navigate = useNavigate();

  /* Effects */
  useEffect(() => {
    const fetchLatestReadyTest = () => {
      setLoading(true);
      testsService.fetchLatestReadyTest(
        (response: { data: any }) => {
          const testData = response.data as Test;
          setTest(testData);
          let consultData;
          if (response.data.latest_vaginitis_consult) {
            consultData = response.data.latest_vaginitis_consult as Consult;
            setConsult(consultData);
          }
          setLoading(false);
          const analyticsArgs = {
            consultId: consultData?.uid,
            consultStatus: consultData?.get_status_display,
            testHash: testData.hash,
            testOrder: testData.test_order,
            carePrice: testData.care_pricing?.discounted_total,
          };
          sendSamplePlanViewPage(analyticsArgs);
          setBaseAnalyticsArgs(analyticsArgs);
        },
        (error: AxiosError) => {
          console.error(error);
          setError(
            error && error.response && error.response.data
              ? error.response.data
              : "Error fetching test data."
          );
          setLoading(false);
        }
      );
    };
    fetchLatestReadyTest();
    window.scrollTo(0, 0); // start from top!
  }, []);

  /* Actions */
  async function getCare() {
    // if no consult, then create a consult and redirect to intake
    if (!consult) {
      setLoading(true);
      try {
        const response = await careService.asyncCreateConsult({
          hash: test?.hash,
          type: "vaginitis",
        });
        const consultData = response.data as Consult;
        setConsult(consultData);
        //   update analytics args
        const analyticsArgs = {
          consultId: consultData.uid,
          consultStatus: consultData.get_status_display,
        };
        setBaseAnalyticsArgs({ ...baseAnalyticsArgs, ...analyticsArgs });
        setLoading(false);
        navigate(`/care/consults/${response.data.uid}/intake/intro/`);
      } catch (error) {
        // cast error to AxiosError
        const axiosError = error as AxiosError;
        setError(
          axiosError && axiosError.response && axiosError.response.data
            ? axiosError.response.data
            : "Error creating consult."
        );
        setLoading(false);
      }
    } else {
      if (consult.consult_paid) {
        // just go to care home page
        navigate(`/care/`);
      } else {
        // if consult is not yet paid, then take them to intake intro page
        navigate(`/care/consults/${consult.uid}/intake/intro/`);
      }
    }

    // analytics
    sendSamplePlanClickedGetCare(baseAnalyticsArgs);
  }

  return (
    <BaseAnalyticsArgsContext.Provider value={{ baseAnalyticsArgs }}>
      {isMobile ? (
        <MobileSamplePlan getCare={getCare} loading={loading} error={error} />
      ) : (
        <DesktopSamplePlan getCare={getCare} loading={loading} error={error} />
      )}
    </BaseAnalyticsArgsContext.Provider>
  );
};

const DesktopSamplePlan: React.FC<{
  getCare: () => void;
  loading: boolean;
  error?: string;
}> = ({ getCare, loading, error }) => {
  const [currentStep, setCurrentStep] = useState(1);

  // get analytics args
  const baseAnalyticsArgs = useBaseAnalyticsArgs();

  // Scroll actions
  const onStepEnter: (input: { data: number }) => void = ({ data }) => {
    setCurrentStep(data);
    sendSamplePlanViewedSection({
      ...baseAnalyticsArgs,
      sectionTitle: STEPS[data - 1].title,
    });
  };
  return (
    <Layout
      title="Care | Sample Plan"
      bgClass="bg-evvy-cream"
      full
      centered
      header={
        <StickyHeader
          backLink="/care/"
          backText="back to care"
          className="bg-evvy-cream"
          onClickBack={() => sendSamplePlanClickedBack(baseAnalyticsArgs)}
        />
      }
    >
      <div className="grid grid-cols-3 overscroll-none w-full">
        <div className="col-span-1 sticky top-24 h-screen py-28 px-8 lg:px-20">
          <div>
            <div className="t4">{SUBTITLE}</div>
            <h3 className="py-5 text-4xl">
              <Markdown>{TITLE_MD}</Markdown>
            </h3>
            <div>
              {STEPS.map((step, index) => (
                <a
                  key={index}
                  className={`ml-auto mb-5 flex items-start group ${
                    currentStep !== index + 1 ? "opacity-50" : ""
                  } transition-all duration-300`}
                  href={step.href}
                  aria-label="link to this section"
                  aria-describedby={step.href}
                >
                  <div
                    className={`rounded-full bg-highlighter-mint text-evvy-black hover:bg-evvy-pine hover:text-white transition-colors`}
                  >
                    <span className="px-1 block w-[25px] h-[25px] text-center flex items-center justify-center t4">
                      {index + 1}
                    </span>
                  </div>
                  <div className={`ml-4 text-left`}>
                    <div
                      className={`t2 ${
                        currentStep === index + 1 ? "medium" : ""
                      }`}
                    >
                      {step.title}
                    </div>
                    {currentStep === index + 1 && (
                      <div className="b2 pt-2">{step.description}</div>
                    )}
                  </div>
                </a>
              ))}
            </div>
            <div className="pt-4">
              {error && <div className="text-red-500 text-center">{error}</div>}
              <RectangularButton
                textColorClass="text-black"
                bgColorClass="bg-evvy-blue"
                fullWidth={true}
                verticalPadding="py-6"
                text={`${PRIMARY_CTA_COPY} ->`}
                disabled={loading}
                onClick={getCare}
                horizontalPadding="px-4"
              />
            </div>
          </div>
        </div>
        <div
          className="col-span-2 bg-cover bg-no-repeat p-12"
          style={{
            backgroundImage: `url('/images/graphics/blue-green-gradient.png')`,
          }}
        >
          <Scrollama onStepEnter={onStepEnter} offset={0.4}>
            <Step data={1} key="step-1">
              <img id="glance" src={SamplePlanIntroDesktop} />
            </Step>
            <Step data={2} key="step-2">
              <img
                id="treatments"
                src={SamplePlanTreatmentsDesktop}
                className="bg-evvy-white"
              />
            </Step>

            <Step data={3} key="step-3">
              <img id="calendar" src={SamplePlanCalendarDesktop} />
            </Step>

            <Step data={4} key="step-4">
              <img
                id="support"
                src={SamplePlanSupportDesktop}
                className="bg-evvy-white"
              />
            </Step>
          </Scrollama>
        </div>
      </div>
    </Layout>
  );
};

const MobileSamplePlan: React.FC<{
  getCare: () => void;
  loading: boolean;
  error?: string;
}> = ({ getCare, loading, error }) => {
  const [currentStep, setCurrentStep] = useState(1);
  // get analytics args
  const baseAnalyticsArgs = useBaseAnalyticsArgs();
  // Scroll actions
  const onStepEnter: (input: { data: number }) => void = ({ data }) => {
    setCurrentStep(data);
    sendSamplePlanViewedSection({
      ...baseAnalyticsArgs,
      sectionTitle: STEPS[data - 1].title,
    });
  };
  return (
    <Layout
      title="Care | Sample Plan"
      bgClass="bg-evvy-white"
      full
      centered
      noHeader
    >
      {/* intro page */}
      <div
        className="w-full bg-cover bg-no-repeat py-8 px-5 min-h-screen"
        style={{
          backgroundImage: `url(${LightBlueGreenGradient})`,
        }}
      >
        <div className="pb-5">
          <BackCircleArrow />
        </div>
        <div className="py-5">
          <div className="uppercase tracking-wider font-semibold">
            {SUBTITLE}
          </div>
          <h2 className="pt-5 pb-8">
            <Markdown>{TITLE_MD}</Markdown>
          </h2>
          <div>
            {STEPS.map((step, index) => (
              <a
                className={`ml-auto mb-6 flex items-start group`}
                href={step.href}
                aria-label="link to this section"
                aria-describedby={step.href}
              >
                <div
                  className={`rounded-full bg-highlighter-mint text-evvy-black`}
                >
                  <span className="px-1 block w-[20px] h-[20px] text-center flex items-center justify-center t4">
                    {index + 1}
                  </span>
                </div>
                <div className={`ml-4 text-left`}>
                  <div className={`font-medium underline underline-offset-4`}>
                    {step.title}
                  </div>
                  <div className="text-[12px] leading-[18px] regular pt-3">
                    {step.description}
                  </div>
                </div>
              </a>
            ))}
          </div>
          <div className="pt-4">
            {error && <div className="text-red-500 text-center">{error}</div>}
            <RectangularButton
              textColorClass="text-black"
              bgColorClass="bg-evvy-blue"
              fullWidth={true}
              verticalPadding="py-8"
              text={`${PRIMARY_CTA_COPY} ->`}
              disabled={loading}
              onClick={getCare}
            />
          </div>
        </div>
        <div className="text-center mt-4">
          <div className="t4 mb-1">Scroll</div>
          <div className="animate-bounce text-lg font-bold">↓</div>
        </div>
      </div>
      {/* scrollama */}
      <div className="bg-evvy-white">
        <StickyMobileSampleTreatmentHeader
          currentStep={currentStep}
          getCare={getCare}
        />
        <div
          className="px-4"
          style={{
            backgroundImage: `url('/images/graphics/blue-green-gradient.png')`,
          }}
        >
          <Scrollama onStepEnter={onStepEnter} offset={0.3}>
            <Step data={1} key="step-1">
              <img id="glance" src={SamplePlanIntroMobile} />
            </Step>
            <Step data={2} key="step-2">
              <div className="bg-evvy-white">
                <img
                  id="treatments"
                  src={SamplePlanTreatmentsMobile}
                  className="pb-12"
                />
              </div>
            </Step>
            <Step data={3} key="step-3">
              <img id="calendar" src={SamplePlanCalendarMobile} />
            </Step>
            <Step data={4} key="step-4">
              <div className="bg-evvy-white">
                <img
                  id="support"
                  src={SamplePlanSupportMobile}
                  className="pt-12 px-4"
                />
              </div>
            </Step>
          </Scrollama>
        </div>
      </div>
    </Layout>
  );
};

const BackCircleArrow = () => {
  // get analytics args
  const baseAnalyticsArgs = useBaseAnalyticsArgs();

  return (
    <Link
      to="/care/"
      onClick={() => sendSamplePlanClickedBack(baseAnalyticsArgs)}
    >
      {/* border  wrapper div*/}
      <button
        className={`p-2 rounded-full border-2 border-evvy-black text-evvy-black group flex items-center pointer-events-auto`}
        aria-label="Back to care"
      >
        {/* arrow button visible in desktop */}
        <span className={`uppercase w-7 h-7 text-center`}>
          <h4 className="font-bold leading-6">←</h4>
        </span>
      </button>
    </Link>
  );
};

const StickyMobileSampleTreatmentHeader: React.FC<{
  currentStep: number;
  getCare: () => void;
}> = ({ currentStep, getCare }) => {
  return (
    <div className="sticky z-20 top-0">
      <div className="bg-evvy-white pt-5 pb-2 px-5">
        <div className="flex items-center">
          <BackCircleArrow />
          <div className="t4 ml-6 transition-all duration-300">{`${currentStep}. ${
            STEPS[currentStep - 1].title
          }`}</div>
        </div>
        <div
          onClick={getCare}
          className="w-full block px-4 bg-evvy-blue-light rounded-lg my-4 cursor-pointer"
        >
          <div className="py-2 flex items-center cursor-pointer">
            <div className="bg-evvy-blue rounded-full text-white p-2 mr-4">
              <DoctorIcon className="h-6 w-6" />
            </div>
            <div className="b1 medium">{PRIMARY_CTA_COPY}</div>
            <svg
              className="w-5 h-5 ml-auto opacity-50"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </div>
        </div>
      </div>
      {/* progress bar */}
      <div className="flex w-full">
        {[1, 2, 3, 4].map((step) => (
          <div
            key={step}
            className={`border-b-2 ${
              currentStep >= step ? "border-evvy-blue" : "border-gray-200"
            } w-1/4`}
          />
        ))}
      </div>
    </div>
  );
};
