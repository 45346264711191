import { useNavigate } from "react-router-dom";
import { useBaseAnalyticsArgs } from "../../hooks/useBaseAnalyticsArgs";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { eventNames } from "../../utils/analytics/customEventTracking";
import RectangularButton from "../common/rectangularButton";

const TITLE_COPY = "Still not sure what to expect?";
const DESCRIPTION_COPY =
  "Take a look at a sample treatment plan to get a better sense of what to expect.";
const BUTTON_COPY = "view sample treatment program ->";

export const SampleTreatmentEntry = () => {
  const navigate = useNavigate();
  const baseAnalyticsArgs = useBaseAnalyticsArgs();

  return (
    <div className="max-w-6xl mx-auto md:flex md:justify-between">
      <div>
        <h3 className="text-4xl">{TITLE_COPY}</h3>
        <div className="t3 regular md:max-w-md mb-4">{DESCRIPTION_COPY}</div>
      </div>
      <div className="items-center my-auto">
        <RectangularButton
          text={BUTTON_COPY}
          onClick={analyticsClickHandler({
            eventName: eventNames.CARE_CLICKED_VIEW_SAMPLE_PLAN,
            eventArgs: baseAnalyticsArgs,
            clickHandler: () => navigate("/care/sample-plan/"),
          })}
          bgColorClass="bg-white"
          textColorClass="text-evvy-black"
          borderColor="border-evvy-black"
          verticalPadding="py-6"
          horizontalPadding="px-4 md:px-10"
        />
      </div>
    </div>
  );
};
