import React from "react";

import LinkedText from "../common/linkedText";
import Markdown from "markdown-to-jsx";

import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { eventNames } from "../../utils/analytics/customEventTracking";
import { WHITE_PAPER_LINK } from "../../pages/constants";

const TrialStats = ({ analyticsEventArgs }) => {
  return (
    <div className="block w-full lg:flex flex-wrap lg:mx-auto">
      <div className="flex-1 lg:flex-initial lg:w-1/2">
        {/* <img className="w-full" src="/images/graphics/clinical-trial-stats.png" alt="Clinical Trial" />
        <div className="t2 py-4">-> Fig 1. Preliminary results from our clinical trial </div> */}
        <div
          className="w-full h-full text-evvy-white py-11 px-6 lg:py-28 lg:px-32 bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url('/images/graphics/care-teal-splash.png')`,
          }}
        >
          <h2 className="hidden lg:block">
            <Markdown>
              Integrative, supportive, and effective *vaginal health care*
            </Markdown>
          </h2>
          <h3 className="block lg:hidden">
            <Markdown>
              Integrative, supportive, and effective *vaginal health care*
            </Markdown>
          </h3>
          <div className="t3 regular mb-6">
            Alongside our advisory board of board-certified OB-GYNs and
            researchers, we conducted an IRB-approved study to observe the
            effectiveness of our novel treatment programs on the vaginal
            microbiome and related symptoms.
          </div>
          <div className="t3 regular mb-6">
            Our treatment programs leverage prescription medication,
            evidence-backed supplements, personalized education, and holistic
            support to improve vaginal health outcomes.
          </div>
          <a
            className="hidden lg:block text-lg bold font-bold"
            target="_blank"
            href={WHITE_PAPER_LINK}
            onClick={analyticsClickHandler({
              eventName: eventNames.CARE_CLICKED_VIEW_WHITE_PAPER,
              eventArgs: { ...analyticsEventArgs, location: "intro" },
            })}
            rel="noreferrer"
          >
            Read the white paper →
          </a>
        </div>
      </div>
      <div className="flex-1 lg:flex-initial lg:w-1/2 py-14 pl-8 pr-8 lg:py-24 lg:pl-36 lg:pr-40">
        <div className="">
          <div className="hidden lg:flex mb-3.5">
            <div className="bg-highlighter-purple ml-0 mr-auto px-1 text-5xl serif">
              83%
            </div>
          </div>
          <div className="flex lg:hidden">
            <h3 className="bg-highlighter-purple ml-0 mr-auto px-1">83%</h3>
          </div>
          <div className="t2 mb-4">
            of participants went from dysbiosis to a protective microbiome*
            within 3 months.
          </div>
          <div className="b3 regular mb-8">
            *Defined as moving from CST 3 or 4 to CST 1, 2, or 5.
          </div>
        </div>
        <div className="pt-4 lg:pt-0">
          <div className="hidden lg:flex mb-3.5">
            <div className="bg-highlighter-yellow ml-0 mr-auto px-1 text-5xl serif">
              100%
            </div>
          </div>
          <div className="flex lg:hidden">
            <h3 className="bg-highlighter-yellow ml-0 mr-auto px-1">100%</h3>
          </div>
          <div className="t2 mb-4">
            of participants felt they had access to the care and resources
            needed to manage their vaginal health.
          </div>
          <div className="b3 regular mb-8">
            {"-> Compared to 6% in the existing standard of care."}
          </div>
        </div>
        <div className="pt-4 lg:pt-0">
          <div className="hidden lg:flex mb-3.5">
            <div className="bg-highlighter-mint ml-0 mr-auto px-1 text-5xl serif">
              100%
            </div>
          </div>
          <div className="flex lg:hidden">
            <h3 className="bg-highlighter-mint ml-0 mr-auto px-1">100%</h3>
          </div>
          <div className="t2 mb-4">
            felt in control, understood, and encouraged.
          </div>
          <div className="b3 regular mb-8">
            {
              "-> Compared to 83% feeling confused, frustrated, helpless, overwhelmed, or alone in the existing standard of care."
            }
          </div>
        </div>
        <div className="block lg:hidden">
          <LinkedText
            onClick={analyticsClickHandler({
              eventName: eventNames.CARE_CLICKED_VIEW_WHITE_PAPER,
              eventArgs: { ...analyticsEventArgs, location: "stats" },
            })}
            isLink
            href={WHITE_PAPER_LINK}
            noIcon
            noTopMargin
          >
            Read the white paper →
          </LinkedText>
        </div>
      </div>
    </div>
  );
};
export default TrialStats;
