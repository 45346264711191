import { isExpired } from "react-jwt";
import { postRequest, getRequest } from "../utils/axios";
// import axios from 'axios';

import { identifyUser } from "../utils/analytics/tracking";

function login(username, password, onSuccess, onError) {
  return postRequest(
    "/api/token/",
    { username: username, password: password },
    (response) => {
      const accessToken = response.data.access;
      const refreshToken = response.data.refresh;

      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);

      // fetch user config (moved to Login.jsx)
      // fetchUser()

      if (onSuccess) {
        return onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        return onError(error, response);
      }
    }
  );
}

function register(data, onSuccess, onError) {
  return postRequest(
    "/api/v1/register/",
    data,
    (response) => {
      const accessToken = response.data.access;
      const refreshToken = response.data.refresh;

      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);

      // fetch user config (moved to Register.jsx)
      // fetchUser()

      if (onSuccess) {
        return onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        console.log("error registering", error);
        console.log("error response", response);
        return onError(error, response);
      }
    }
  );
}

const registerProvider = async (data) => {
  const response = await postRequest(
    "/api/v1/provider/register",
    data,
    (response) => {
      const accessToken = response.data.access;
      const refreshToken = response.data.refresh;

      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);

      // fetch user config (moved to Register.jsx)
      // fetchUser()

      return response;
    },
    (error, response) => {
      console.log("error registering", error);
      console.log("error response", response);
      throw error;
    }
  );
  return response;
};

function tokenNeedsRefresh(token) {
  return isExpired(token);
}

function refreshToken(onSuccess, onError) {
  return postRequest(
    "/api/token/refresh/",
    { refresh: localStorage.getItem("refreshToken") },
    (response) => {
      const accessToken = response.data.access;
      localStorage.setItem("accessToken", accessToken);
      console.log("got a new token");
      if (onSuccess) {
        return onSuccess(accessToken);
      }
    },
    (error, response) => {
      // log the user out if their refresh failed
      authService.logout();
      if (onError) {
        console.log("returning onError");
        return onError(error, response);
      }
    }
  );
}

function blacklistTokens(onSuccess, onError) {
  return postRequest(
    "/api/token/logout/",
    {
      refresh: localStorage.getItem("refreshToken"),
      access: localStorage.getItem("accessToken"),
    },
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function fetchUser(onSuccess, onError) {
  return getRequest(
    "/api/v1/user-config/",
    (response) => {
      localStorage.setItem("currentUser", JSON.stringify(response.data));
      if (onSuccess) {
        onSuccess(response);
      }

      // identify user (FS)
      identifyUser(response.data);
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function forgotPassword(email, onSuccess, onError) {
  return postRequest(
    "/api/v1/password_reset/",
    { email: email },
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function forgotPasswordConfirm(token, password, onSuccess, onError) {
  return postRequest(
    "/api/v1/password_reset/confirm/",
    { token: token, password: password },
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function checkPasswordTokenValidity(token, onSuccess, onError) {
  return postRequest(
    "/api/v1/password_reset/validate_token/",
    { token: token },
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function verifyEmail(onSuccess, onError) {
  return postRequest(
    "/api/v1/email-verification/",
    {},
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function verifyEmailConfirm(hash, onSuccess, onError) {
  return postRequest(
    "/api/v1/email-verification-confirm/",
    { hash: hash },
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function logout() {
  blacklistTokens(clearTokens, clearTokens);
}

const clearTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("currentUser");

  const currentPath = window.location.pathname;
  // if location is already login, don't redirect
  if (currentPath.startsWith("/login/")) {
    return;
  }

  // if the navigation is not coming from an invalid 404 page, redirect to login with the current path as the next page param
  if (!currentPath.startsWith("/404/")) {
    window.location = `/login/?next=${currentPath}`;
  } else {
    window.location = "/login/";
  }
};

export const authService = {
  login,
  logout,
  register,
  registerProvider,
  fetchUser,
  tokenNeedsRefresh,
  refreshToken,
  blacklistTokens,
  forgotPassword,
  forgotPasswordConfirm,
  checkPasswordTokenValidity,
  verifyEmail,
  verifyEmailConfirm,
};
