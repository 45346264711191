import { useTransparentCareTreatments } from "../../../hooks/care/transparentCare/useTransparentCareTreatments";
import { transparentCarePhaseColorMap } from "../../../utils/colors";
import TransparentPhaseHeader from "../transparentCare/TransparentPhaseHeader";
import ALaCareTreatmentCard from "./ALaCareTreatmentCard";

const ALaCareSectionHeader = () => {
  const header = "Explore Individual Rx Treatments";
  const superTitle = "Want more flexibility?";

  return (
    <>
      <div className="text-sm font-semibold uppercase mb-3">{superTitle}</div>
      <h2 className="text-[40px]">{header}</h2>
      <div className="border-t border-dashed border-[#11161A33] mt-6 mb-14" />
    </>
  );
};

interface ALaCareProps {
  testHash: string;
}

const ALaCare: React.FC<ALaCareProps> = ({ testHash }) => {
  const { transparentCareTreatments } = useTransparentCareTreatments(testHash);

  if (!transparentCareTreatments) return null;

  return (
    <div className="bg-white px-3.5 sm:px-10 md:px-24 py-14">
      <ALaCareSectionHeader />
      {Object.entries(transparentCareTreatments?.a_la_care_treatments)
        .filter(([_, details]) => details.treatments.length > 0)
        .map(([phase, details]) => (
          <div className="flex flex-col mb-10 sm:mb-14 last:mb-0" key={phase}>
            <TransparentPhaseHeader
              headerText={details.header_text}
              phaseColor={
                transparentCarePhaseColorMap[
                  phase as keyof typeof transparentCarePhaseColorMap
                ].opacity70
              }
              headerClassName="mb-3 sm:mb-4 text-2xl sm:text-3xl"
            />
            {details.sub_header_text && (
              <div className="text-base text-gray-500 mb-6 sm:mb-10">
                {details.sub_header_text}
              </div>
            )}
            <div className="flex flex-wrap gap-3.5 md:gap-6 lg:gap-10">
              {details.treatments.map((treatment) => (
                <ALaCareTreatmentCard
                  key={treatment.slug}
                  treatment={treatment}
                  phase={phase}
                />
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default ALaCare;
