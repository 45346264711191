import React from "react";

import OutlinedButton from "../../components/plan_unfurled/outlinedButton";
import { TreatmentFAQButton } from "./treatmentFAQButton";
import { ReactComponent as Chat } from "../../components/plan_unfurled/icons/chat.svg";
import { ReactComponent as Coach } from "../../components/plan_unfurled/icons/coach.svg";

import { secondaryColors } from "../../utils/viz";
import { eventNames } from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { getWeekCalendlyURL, getWeekCTA } from "./constants";
import { useIntercom } from "react-use-intercom";

// FAQs for the treatment plan
const TreatmentFAQs = ({ treatmentPlan }) => {
  const isTreatmentPlan = treatmentPlan?.consult?.plan_type === "treatment";
  const { show } = useIntercom();

  // renders with method b/c we do this in different places on web & mobile
  const renderActionButtons = () => {
    return (
      <React.Fragment>
        <div className="max-w-xs inline-block space-y-4">
          <div>
            {/* Only if the current week has a CTA and it's a treatment plan type */}
            {isTreatmentPlan &&
            treatmentPlan &&
            treatmentPlan.calendar &&
            treatmentPlan.calendar.current_treatment_day &&
            getWeekCTA(
              treatmentPlan.calendar.current_treatment_day.week,
              treatmentPlan.calendar.last_treatment_week
            ) ? (
              <OutlinedButton
                href={getWeekCalendlyURL(
                  getWeekCTA(
                    treatmentPlan.calendar.current_treatment_day.week,
                    treatmentPlan.calendar.last_treatment_week
                  )
                )}
                icon={
                  <Coach
                    stroke={secondaryColors["evvy-pine"]}
                    width="100%"
                    height="100%"
                  />
                }
                text="Book a coaching call"
                color="evvy-pine"
                onClick={analyticsClickHandler({
                  eventName: eventNames.TREATMENT_CLICKED_COACHING,
                  eventArgs: {
                    consultId: treatmentPlan?.consult?.uid,
                    testHash: treatmentPlan?.test?.hash,
                    location: "faq",
                  },
                })}
              />
            ) : (
              ""
            )}
          </div>

          <OutlinedButton
            icon={
              <Chat
                stroke={secondaryColors["evvy-pine"]}
                width="100%"
                height="100%"
              />
            }
            text="Ask us a question"
            color="evvy-pine"
            onClick={analyticsClickHandler({
              eventName: eventNames.TREATMENT_ASK_QUESTION,
              eventArgs: {
                consultId: treatmentPlan?.consult?.uid,
                testHash: treatmentPlan?.test?.hash,
                location: "faq",
              },
              clickHandler: () => show(),
            })}
          />

          {/* Link to support site */}
          <TreatmentFAQButton
            analyticsArgs={{
              consultId: treatmentPlan?.consult?.uid,
              testHash: treatmentPlan?.test?.hash,
              location: "faq",
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  return (
    <div id="#treatment-faqs" className="bg-white py-20 px-4">
      <div className="max-w-4xl mx-auto sm:flex sm:space-x-12 sm:px-10">
        <div className="flex-1">
          <h2 className="">
            Questions? <br></br>We're here to help.
          </h2>
          <div className="t3 medium">
            If you can’t find what you’re looking for, please reach out to our
            support team and we’ll get back to you ASAP.
          </div>
        </div>

        <div className="flex mt-8 sm:mt-0 sm:justify-end">
          <div className="hidden sm:flex justify-end mx-auto">
            {renderActionButtons()}
          </div>
        </div>

        <div className="block sm:hidden">{renderActionButtons()}</div>
      </div>
    </div>
  );
};

export default TreatmentFAQs;
