import { useState, useEffect, useCallback } from "react";

import { Cart } from "../../types/cart";
import { cartService } from "../../services/cart";

const usePersistedCart = () => {
  const [cart, setCart] = useState<Cart>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [refetch, setRefetch] = useState(false);

  const getCartIfExists = useCallback(async () => {
    try {
      const response = await cartService.getLatestActiveCart();
      if (response) {
        setCart(response);
      }
    } catch (error) {
      console.error(error);
      setError("Error fetching cart");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getCartIfExists();
  }, [getCartIfExists, refetch]);

  const refetchCart = () => {
    setRefetch((prev) => !prev);
  };

  return {
    cart,
    isLoading,
    error,
    refetchCart,
    setCart,
  };
};

export default usePersistedCart;
