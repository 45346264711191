import { useState } from "react";

// hooks
import usePersistedCart from "../../hooks/cart/usePersistedCart";

// types
import { AddCartItemPayload, CartItem } from "../../types/cart";

// services
import { cartService } from "../../services/cart";

export const usePanelCart = () => {
  const [showCart, setShowCart] = useState<boolean>(false);
  const { cart, isLoading, setCart } = usePersistedCart();

  const addToCart = async (items: AddCartItemPayload[]) => {
    // if not cart, create cart
    let updatedCart = cart;
    if (!cart) {
      updatedCart = await cartService.getOrCreateCart();
    }

    if (!updatedCart) {
      console.error("Error creating cart");
      // TODO: error handling /error states here
      return;
    }
    // add items to cart
    updatedCart = await cartService.addItemsToCart(updatedCart.id, items);
    setCart(updatedCart);
    setShowCart(true);
  };

  const removeFromCart = async (items: CartItem[]) => {
    // if not cart, then there's nothing to remove
    if (!cart) {
      return;
    }
    const updatedCart = await cartService.removeItemsFromCart(
      cart.id,
      items.map((item) => item.id)
    );
    setCart(updatedCart);
  };

  const getIfBundleInCart = () => {
    return cart?.line_items.some(
      (item) => item.item_type === "bundle-treatment"
    );
  };

  const getIfALaCareTreatmentInCart = (treatmentSlug: string) => {
    return cart?.line_items.some(
      (item) =>
        item.item_type === "individual-treatment" &&
        item.treatment_product.slug === treatmentSlug
    );
  };

  return {
    cart,
    isLoading,
    addToCart, // function to add items to cart. Cart opens automatically after adding items
    removeFromCart, // function to remove items from cart
    getIfBundleInCart, // function to check if a bundle is in the cart
    getIfALaCareTreatmentInCart, // function to check if a specific treatment is in the cart
    showCart,
    setShowCart, // function to set whether cart is shown
  };
};
