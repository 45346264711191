// components
import { EligibilityCountdownBanner } from "./eligibilityCountdownBanner";
import { CareIntroDefault } from "./careIntroDefault";
import { CareIntroProblems } from "./careIntroProblems";
import { useExperiments } from "../../hooks/useExperiments";
import { Consult } from "../../types/care";
import { Test } from "../../types/test";

const CareIntroV2 = ({
  consult,
  test,
  completedConsults,
  analyticsEventArgs,
  selectedPathwaySlug,
  setSelectedPathwaySlug,
  openPriceModal,
}: {
  consult: Consult;
  test: Test;
  completedConsults: Consult[];
  analyticsEventArgs: any;
  selectedPathwaySlug: string;
  setSelectedPathwaySlug: (slug: string) => void;
  openPriceModal: () => void;
}) => {
  const experiments = useExperiments();

  return (
    <div className="max-w-7xl w-full mx-auto px-3 sm:px-0">
      {!consult?.consult_paid && <EligibilityCountdownBanner test={test} />}
      <div className="pt-4 md:pt-6">
        {experiments.problemsModule ? (
          <CareIntroProblems
            test={test}
            analyticsEventArgs={analyticsEventArgs}
            completedConsults={completedConsults}
            consult={consult}
            selectedPathwaySlug={selectedPathwaySlug}
            setSelectedPathwaySlug={setSelectedPathwaySlug}
          />
        ) : (
          <CareIntroDefault
            test={test}
            analyticsEventArgs={analyticsEventArgs}
            openPriceModal={openPriceModal}
            completedConsults={completedConsults}
            consult={consult}
            location="care"
            showCollapsibleDropdown
            selectedPathwaySlug={selectedPathwaySlug}
            setSelectedPathwaySlug={setSelectedPathwaySlug}
          />
        )}
      </div>
    </div>
  );
};

export default CareIntroV2;
