import { PrescriptionTag } from "./prescriptionTag";
import PrescriptionLearnMoreButton from "./PrescriptionLearnMoreButton";
import { Consult, Prescription, PrescriptionRecord } from "../../types/care";
import { PURPOSE_TAG_TO_BG_COLOR } from "../../pages/care/aLaCare/shopTreatments";
import classNames from "classnames";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

// renders a prescription card which when clicked opens up modal
const PrescriptionCard: React.FC<{
  prescription: Prescription;
  consult: Consult;
  openModal: () => void;
  refillablePrescriptions?: PrescriptionRecord[];
}> = ({ prescription, consult, openModal, refillablePrescriptions }) => {
  const isAlaCareTreatmentPlan = consult?.purchase_type === "a-la-care";
  const purposeTagBGColor = prescription.purpose_tag
    ? PURPOSE_TAG_TO_BG_COLOR[prescription.purpose_tag]
    : "bg-evvy-blue";
  const navigate = useNavigate();

  const showRefillCTA = useMemo(() => {
    const isRefillablePrescription = refillablePrescriptions?.find(
      (prescriptionRecord) =>
        prescriptionRecord.product.slug === prescription.slug
    );
    const consultReadyForRefills = consult.ready_for_refills;
    return isRefillablePrescription && consultReadyForRefills;
  }, [refillablePrescriptions, prescription.slug]);

  return (
    <div className="bg-evvy-cream px-5 py-6 sm:px-8 rounded-lg">
      <div className="mb-4 flex">
        <div className="mr-4">
          {isAlaCareTreatmentPlan ? (
            prescription.purpose_tag && (
              <div className={`rounded-md ${purposeTagBGColor} p-2`}>
                <span className="text-sm uppercase tracking-wider semibold">
                  {prescription.purpose_tag}
                </span>
              </div>
            )
          ) : (
            <PrescriptionTag tag={prescription?.tag} />
          )}
        </div>
        <div className="ml-auto my-auto">
          <PrescriptionLearnMoreButton
            consult={consult}
            location="treatment_plan"
            prescriptionSlug={prescription?.slug}
            openModal={openModal}
          />
        </div>
      </div>
      <div className="flex">
        {prescription?.image_url && (
          <div className="flex-shrink-0 bg-mud rounded-md h-16 w-16 p-2 pb-0 mr-4">
            <img
              src={prescription?.image_url}
              alt={prescription?.title_short_display}
              className="h-full w-full"
            />
          </div>
        )}
        <div>
          <div className="text-2xl medium mb-0">
            {prescription?.title_short_display}
          </div>
          <div className="mt-0 mb-1 text-lg">
            {consult.purchase_type === "a-la-care" &&
              prescription?.quantity_text?.replace("|", " ")}
            {consult.purchase_type === "v0-bundle" &&
              prescription?.dose_text?.replace("|", " ")}
          </div>
          {isAlaCareTreatmentPlan && prescription.use_tag && (
            <>
              <div
                className={`${purposeTagBGColor} rounded-full p-1.5 inline-block mr-2 my-auto`}
              />
              <span className="b2 medium">{prescription.use_tag}</span>
            </>
          )}
          {isAlaCareTreatmentPlan && showRefillCTA && (
            <div className="mt-4">
              <span
                onClick={() => {
                  // pass in query param for cart treatments
                  navigate(
                    `/care/consults/${consult?.uid}/refills/${prescription.slug}`
                  );
                }}
                className={classNames(
                  "text-evvy-black linkedText t4 border-b-2 border-black cursor-pointer pb-1"
                )}
              >
                Get a refill →
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrescriptionCard;
