import React from "react";

import { getIsMobile } from "../../utils/general";

// components
import { ReactComponent as Stethoscope } from "./icons/stethoscope-circle.svg";
import { ReactComponent as Pills } from "./icons/pills-circle.svg";
import { ReactComponent as Heart } from "./icons/heart-circle.svg";

const COPY_FOR_CARE_TYPE = {
  a_la_care: [
    {
      header: "An Evvy-affiliated provider will review your request",
      body: "Our selection of research-backed treatments are intended to soothe your symptoms and rebuild protective bacteria.",
    },
    {
      header: "Your products will be shipped straight to you",
      body: "Our partner pharmacy specially formulates each prescription with prescription-grade ingredients you can’t get over-the-counter.",
    },
    {
      header: "We’ll provide support every step of the way",
      body: "We’re here for any questions that come up.",
    },
  ],
  v0_bundle: [
    {
      header: "A provider will develop your custom treatment program.",
      body: "Your provider will look at your health history, clinical intake, and test results before prescribing treatments.",
    },
    {
      header: "Your treatment will be shipped straight to you.",
      body: "Our partner pharmacy specially formulates each treatment with pure, prescription-grade ingredients.",
    },
    {
      header: "We'll provide coaching and support every step of the way.",
      body: "All treatment programs include 1 call with a health coach and dedicated support to make sure care fits seamlessly into your life.",
    },
  ],
};

const HowItWorks = ({
  careType = "v0_bundle",
}: {
  careType?: "a_la_care" | "v0_bundle";
}) => {
  const isMobile = getIsMobile();
  const copy = COPY_FOR_CARE_TYPE[careType];

  return (
    <div className="justify-center">
      <div className="flex mb-3 sm:mb-12">
        <h2 className="hidden sm:block sm:mx-auto">How it works:</h2>
        <h3 className="block sm:hidden">How it works:</h3>
      </div>
      <div className="block max-w-5xl sm:flex flex-wrap sm:space-x-10 sm:mx-auto">
        <div
          className={`flex-1 pb-6 ${
            isMobile ? "border-b mb-6" : "border-r"
          } border-dashed border-black pr-8`}
        >
          <div className="flex mb-6">
            <Stethoscope />
          </div>
          <div className="t3 medium my-4">{copy[0].header}</div>
          <div className="t3 regular">{copy[0].body}</div>
        </div>
        <div
          className={`flex-1 pb-6 ${
            isMobile ? "border-b mb-6" : "border-r"
          } border-dashed border-black pr-8`}
        >
          <div className="flex mb-6">
            <Pills />
          </div>
          <div className="t3 medium my-4">{copy[1].header}</div>
          <div className="t3 regular">{copy[1].body}</div>
        </div>
        <div className="flex-1 pb-6">
          <div className="flex mb-6">
            <Heart />
          </div>
          <div className="t3 medium my-4">{copy[2].header}</div>
          <div className="t3 regular">{copy[2].body}</div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
