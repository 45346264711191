import { ReactComponent as Checkmark } from "../common/icons/checkmark.svg";
import { ReactComponent as XMark } from "../common/icons/xMark.svg";
import { Link } from "react-router-dom";
import { A_LA_CARE_SHOP_TREATMENTS_PAGE_PATH } from "./constants";
import { TreatmentPathway } from "../../types/care";
import { sendCareClickedALaCareOnRamp } from "../../utils/analytics/customEventTracking";
import { cn } from "../../utils/cn";
import { useExperiments } from "../../hooks/useExperiments";

const COPY_BY_TREATMENT_ELIBILITY: { [key: string]: any } = {
  treatmentProgram: {
    title: "Want individual Rx products without the complete program?",
    buttonCopy: "Browse individual RX products",
    bullets: [
      {
        icon: "available",
        copy: "Access Evvy’s prescription-grade probiotics, boric acid, soothing creams, and more",
      },
      {
        icon: "available",
        copy: "Relieve your symptoms and rebuild your vaginal microbiome, on your terms",
      },
      {
        icon: "notAvailable",
        copy: "Not available outside of the Evvy Complete Treatment Program: antibiotics, antifungals, and vaginal estrogen",
      },
    ],
  },
  probioticsOnly: {
    title: "Want individual Rx products without the program?",
    buttonCopy: "Browse individual RX products",
    bullets: [
      {
        icon: "available",
        copy: "Access Evvy’s prescription-grade probiotics, boric acid, soothing creams, and more",
      },
      {
        icon: "available",
        copy: "Relieve your symptoms and rebuild your vaginal microbiome, on your terms",
      },
      {
        icon: "notAvailable",
        copy: "Doesn’t include health coach support or personalized treatment calendar",
      },
    ],
  },
};

const ShopTreatmentsOnramp = ({
  selectedPathwaySlug,
  eligibleTreatmentPathways,
  analyticsEventArgs,
}: {
  selectedPathwaySlug?: string;
  eligibleTreatmentPathways?: TreatmentPathway[];
  analyticsEventArgs?: any;
}) => {
  const experiments = useExperiments();
  const clickHandler = () => {
    sendCareClickedALaCareOnRamp(analyticsEventArgs);
    window.scrollTo(0, 0);
  };

  const maintenancePathway = eligibleTreatmentPathways?.find(
    (pathway) => pathway.type === "maintenance"
  );

  const maintenanceSelected =
    maintenancePathway && maintenancePathway.slug === selectedPathwaySlug;
  const probioticsOnly =
    eligibleTreatmentPathways?.[0].slug === "probiotics-only";

  const treatmentPathway =
    !maintenanceSelected && !probioticsOnly
      ? "treatmentProgram"
      : "probioticsOnly";

  return (
    <div
      className={cn("justify-center flex", {
        "mx-1 sm:mx-2 md:mx-10 xl:mx-0 mt-6": experiments.prominentOnramp,
        "w-full": !experiments.prominentOnramp,
      })}
    >
      <div
        className={cn(
          "block sm:flex p-10 rounded-xl mx-4 sm:m-0 items-center",
          {
            "bg-evvy-cream sm:mb-12 max-w-5xl m-4":
              !experiments.prominentOnramp,
            "bg-white/80 sm:bg-white/50 max-w-[1050px] mx-4":
              experiments.prominentOnramp,
          }
        )}
      >
        <div className="w-full sm:w-1/2 md:mr-8">
          <h4 className="text-3xl sm:pr-10 lg:pr-16">
            {COPY_BY_TREATMENT_ELIBILITY[treatmentPathway].title}
          </h4>
          <Link onClick={clickHandler} to={A_LA_CARE_SHOP_TREATMENTS_PAGE_PATH}>
            <button
              className="hidden sm:block border-black border border-px py-6 sm:px-4 md:px-9 lg:px-[60px] font-semibold tracking-wider focus:outline-none"
              type="submit"
            >
              {COPY_BY_TREATMENT_ELIBILITY[treatmentPathway].buttonCopy}
            </button>
          </Link>
        </div>
        <div className="w-full sm:w-1/2 items-center">
          {COPY_BY_TREATMENT_ELIBILITY[treatmentPathway].bullets.map(
            (bullet: any) => {
              return (
                <div className="flex align-center justify-start mb-4">
                  <div className="mr-1">
                    {bullet.icon === "available" ? (
                      <Checkmark className="text-evvy-pine w-6 h-6" />
                    ) : (
                      <XMark className="text-evvy-pine w-6 h-6" />
                    )}
                  </div>
                  <p className="b2 mb-0">{bullet.copy}</p>
                </div>
              );
            }
          )}
          <Link onClick={clickHandler} to={A_LA_CARE_SHOP_TREATMENTS_PAGE_PATH}>
            <button className="sm:hidden min-w-full 2-96 block border-black border border-px py-6 px-4 sm:px-8 font-semibold tracking-wider focus:outline-none">
              {COPY_BY_TREATMENT_ELIBILITY[treatmentPathway].buttonCopy}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ShopTreatmentsOnramp;
