import React from "react";

// components
import CloseX from "../../common/closeX";

import { useLoggedInUser } from "../../../hooks/useLoggedInUser";

const PRICING_TITLE = "Transparent Pricing";
const PRICING_DESCRIPTION =
  "We’re committed to making Evvy as accessible as possible. Here’s what the cost of an Evvy test goes toward:";
const WHEEL_PROVIDER_GROUP = "To Wheel Provider Group";
const TELEHEALTH_SERVICE_FEE = "Telehealth service fee";
const PRESCRIPTION_SERVICES = "Prescription services";
const EARLY_ACCESS_DISCOUNT = "Early access discount";
const TOTAL = "Total";
const EARLY_ACCESS_DISCOUNT_AMOUNT = 50;

const PricingTemplate: React.FC<{
  handleClose: () => void;
  data: { telehealth_fee: number; prescription_services: number };
}> = ({ handleClose, data }) => {
  const { telehealth_fee, prescription_services } = data || {};
  const total = telehealth_fee + prescription_services;

  return (
    <div
      className={`w-full flex flex-col p-10 sm:p-12 bg-evvy-white text-left`}
    >
      {/* close button */}
      <div className="flex justify-end items-center">
        <CloseX handleClose={handleClose} textColorClass="text-evvy-black" />
      </div>
      {/* title */}
      <div className="w-full t1 text-evvy-pine">{PRICING_TITLE}</div>
      {/* description */}
      <div className="caption my-5">{PRICING_DESCRIPTION}</div>
      {/* pricing breakdown */}
      <div className="space-y-5">
        <div>
          <div className="caption flex justify-between">
            <div>{`${TELEHEALTH_SERVICE_FEE} —`}</div>
            <div>{`$${telehealth_fee}`}</div>
          </div>
          <div className="text-xs italic">{WHEEL_PROVIDER_GROUP}</div>
        </div>
        <div>
          <div className="caption flex justify-between">
            <div>{`${PRESCRIPTION_SERVICES} — `}</div>
            <div>
              {`$${prescription_services + EARLY_ACCESS_DISCOUNT_AMOUNT}`}
            </div>
          </div>
        </div>
        <div>
          <div className="caption flex justify-between">
            <div>{`${EARLY_ACCESS_DISCOUNT} — `}</div>
            <div className="text-dv-pond">{`-$${EARLY_ACCESS_DISCOUNT_AMOUNT}`}</div>
          </div>
        </div>
      </div>
      <hr className="my-6" />
      <div className="caption flex justify-between">
        <div>{`${TOTAL} — `}</div>
        <div className="semibold">{`$${total}`}</div>
      </div>
    </div>
  );
};

export default PricingTemplate;
