import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import FullStory from "react-fullstory";

import Authentication from "./components/authentication";

import Tests from "./pages/tests";
import ResultsV2 from "./pages/results_v2";
import Plan from "./pages/plan";
import UnfurledPlan from "./pages/plan_unfurled";

import Compare from "./pages/compare";
import Account from "./pages/account";
import Care from "./pages/care";

import NotFound404 from "./pages/404";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import PasswordReset from "./pages/auth/passwordReset";
import PasswordResetConfirm from "./pages/auth/passwordResetConfirm";
import VerifyEmail from "./pages/welcome/verifyEmail";
import VerifyEmailConfirm from "./pages/welcome/verifyEmailConfirm";
import WelcomeIntro from "./pages/welcome/intro";
import ResearchConsent from "./pages/welcome/researchConsent";
import { VerifiedRoute } from "./components/common/verifiedRoute";
import { ConsultsContextProvider } from "./contexts/consultsContextLoader";
import { ProviderHome, VerifiedProviderRoutes } from "./pages/providers/home";
import { RegisterProvider } from "./pages/auth/registerProvider";
import { ProviderOrder } from "./pages/providers/order";
import { ProviderIntake } from "./pages/providers/providerIntake";
import { ProviderResources } from "./pages/providers/resources";
import { ProviderTests } from "./pages/providers/tests";
import { WaitingForVerification } from "./pages/providers/waitingForVerification";
import { IntercomWidget } from "./components/common/intercom";
import { Helmet } from "react-helmet";
import { LinkRedirect } from "./pages/linkRedirect";
import { CartProvider } from "./contexts/aLaCareCartContext";

const App = () => {
  return (
    <Router>
      {<FullStory org={"14DYPF"} />}
      <Authentication>
        {(authState, location) => {
          return location.pathname.includes("/link/dynamic") ? (
            <Routes>
              <Route path="/link/dynamic/:hash" element={<LinkRedirect />} />
            </Routes>
          ) : (
            <IntercomWidget>
              <ConsultsContextProvider
                isAuthenticated={authState.authenticated}
              >
                <CartProvider>
                  <Routes>
                    {/* app routes (subroutes within main components) */}
                    <Route
                      path="/"
                      element={
                        <VerifiedRoute authRequired={true}>
                          <div>
                            <Helmet>
                              <title>Evvy - Your Account</title>
                              <meta
                                name="description"
                                content="Visit your Evvy Account."
                              />
                            </Helmet>
                            <Navigate
                              replace
                              to={
                                authState.currentUser &&
                                authState.currentUser.identity.isProvider
                                  ? "/provider/"
                                  : "/tests/"
                              }
                            />
                          </div>
                        </VerifiedRoute>
                      }
                    />
                    <Route
                      path="/tests/*"
                      element={
                        <VerifiedRoute
                          authRequired={true}
                          consentRequired={true}
                          verifyEmailRequired={true}
                        >
                          <Tests />
                        </VerifiedRoute>
                      }
                    />
                    <Route
                      path="/results/:id/*"
                      element={
                        <VerifiedRoute
                          authRequired={true}
                          consentRequired={true}
                          verifyEmailRequired={true}
                        >
                          <ResultsV2 />
                        </VerifiedRoute>
                      }
                    />
                    {/* hides new plan page from production users */}
                    <Route
                      path="/plan/*"
                      element={
                        <VerifiedRoute
                          authRequired={true}
                          consentRequired={true}
                          verifyEmailRequired={true}
                        >
                          {authState.currentUser?.features?.plan ? (
                            <UnfurledPlan />
                          ) : (
                            <Plan />
                          )}
                        </VerifiedRoute>
                      }
                    />
                    <Route
                      path="/compare/"
                      element={
                        <VerifiedRoute
                          authRequired={true}
                          consentRequired={true}
                          verifyEmailRequired={true}
                        >
                          <Compare />
                        </VerifiedRoute>
                      }
                    />
                    <Route
                      path="/account/*"
                      element={
                        <VerifiedRoute
                          authRequired={true}
                          consentRequired={true}
                          verifyEmailRequired={true}
                        >
                          <Account />
                        </VerifiedRoute>
                      }
                    />
                    <Route
                      path="/care/*"
                      element={
                        <VerifiedRoute
                          authRequired={true}
                          consentRequired={true}
                          verifyEmailRequired={true}
                        >
                          <Care />
                        </VerifiedRoute>
                      }
                    />
                    <Route
                      path="/treatment/"
                      element={
                        <VerifiedRoute
                          authRequired={true}
                          consentRequired={true}
                          verifyEmailRequired={true}
                        >
                          <Navigate to="/care/treatment/" />
                        </VerifiedRoute>
                      }
                    />
                    <Route
                      path="/verify-email/"
                      element={
                        <VerifiedRoute authRequired={true}>
                          <VerifyEmail />
                        </VerifiedRoute>
                      }
                    />
                    <Route
                      path="/verify-email-confirm/"
                      element={<VerifyEmailConfirm />}
                    />
                    <Route
                      path="/welcome/"
                      element={
                        <VerifiedRoute authRequired={true}>
                          <WelcomeIntro />
                        </VerifiedRoute>
                      }
                    />
                    <Route
                      path="/research-consent/"
                      element={
                        <VerifiedRoute authRequired={true}>
                          <ResearchConsent />
                        </VerifiedRoute>
                      }
                    />
                    {/* provider routes */}

                    <Route
                      path="provider/register/"
                      element={<RegisterProvider />}
                    />

                    <Route path="/provider/*" element={<ProviderHome />}>
                      <Route
                        path="intake/"
                        element={
                          <VerifiedRoute authRequired={true}>
                            <ProviderIntake />
                          </VerifiedRoute>
                        }
                      />
                      <Route
                        path="account/*"
                        element={
                          <VerifiedRoute authRequired={true}>
                            <Account />
                          </VerifiedRoute>
                        }
                      />
                      <Route
                        path="verify/"
                        element={
                          <VerifiedRoute authRequired={true}>
                            <WaitingForVerification />
                          </VerifiedRoute>
                        }
                      />
                      <Route path="*" element={<VerifiedProviderRoutes />}>
                        <Route
                          path="resources/"
                          element={
                            <VerifiedRoute authRequired={true}>
                              <ProviderResources />
                            </VerifiedRoute>
                          }
                        />
                        <Route
                          path="order/"
                          element={
                            <VerifiedRoute authRequired={true}>
                              <ProviderOrder />
                            </VerifiedRoute>
                          }
                        />
                        <Route
                          path="tests/"
                          element={
                            <VerifiedRoute authRequired={true}>
                              <ProviderTests />
                            </VerifiedRoute>
                          }
                        />
                      </Route>
                    </Route>
                    {/* auth routes */}
                    <Route path="/login/" element={<Login />} />
                    <Route path="/register/" element={<Register />} />

                    <Route
                      path="/password-reset/"
                      element={<PasswordReset />}
                    />
                    <Route
                      path="/password-reset-confirm/"
                      element={<PasswordResetConfirm />}
                    />
                    <Route path="/404/" element={<NotFound404 />} />

                    <Route
                      path="*"
                      element={
                        <Navigate to={`/404/?from=${location.pathname}`} />
                      }
                    />
                    {/* catchall */}
                  </Routes>
                </CartProvider>
              </ConsultsContextProvider>
            </IntercomWidget>
          );
        }}
      </Authentication>
    </Router>
  );
};

export default App;
