import React from "react";
import { Link } from "react-router-dom";
// lib
import { getIsMobile } from "../../../utils/general";

const StickyHeader = ({ testHash, onClickBack, isSTIConsult = false }) => {
  const isMobile = getIsMobile();

  return (
    <React.Fragment>
      <nav
        className={`w-full sticky top-0 px-6 md:px-12 pt-5 pb-5 justify-center grid grid-cols-3 align-center items-center z-20 pointer-events-none border-b-2 bg-evvy-white`}
      >
        {/* link to "care" */}
        <Link
          to={isSTIConsult ? "/" : "/care/"}
          onClick={onClickBack ? onClickBack : undefined}
          className="col-span-1"
        >
          {/* border  wrapper div*/}
          <button
            className={`p-2 bg-white md:bg-white rounded-full border-2 border-evvy-black text-evvy-black group flex items-center pointer-events-auto`}
            aria-label="Back to care"
          >
            {/* arrow button visible in desktop */}
            <span
              className={`uppercase ${
                !isMobile ? "group-hover:hidden" : ""
              } w-7 sm:w-8 h-7 sm:h-8 text-center`}
            >
              <h4 className="font-bold leading-6">←</h4>
            </span>
            {/* full text button visible on hover and mobile */}
            {!isMobile && (
              <span className="px-2 h-7 sm:h-8 hidden group-hover:inline uppercase tracking-wider font-semibold">
                <span className="pr-2">
                  <h4 className="font-bold leading-6 margin-0 inline">←</h4>
                </span>{" "}
                Back to {isSTIConsult ? "Home" : "Care"}
              </span>
            )}
          </button>
        </Link>
        <div className="col-span-1 justify-self-center">
          <div className="t4 hidden sm:block">{`Test ID: ${testHash}`}</div>
        </div>
        {/* evvy logo */}
        <div className="col-span-1 justify-self-end">
          <img
            className="h-8 sm:h-10"
            src="/images/evvy-logo-text.svg"
            loading="lazy"
            alt="Evvy Text Logo"
          />
        </div>
      </nav>
      <div className="t4 block sm:hidden text-center py-3 border-b-2">
        {`Test ID: ${testHash}`}
      </div>
    </React.Fragment>
  );
};

export default StickyHeader;
