import { Bullets } from "../../common/bullets";
import CareFullTreatmentDiagram from "../images/transparent-care-full-treatment-diagram.png";
import CareFullTreatmentDiagramMinimized from "../images/transparent-care-minimized-treatment-diagram.png";
import { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { cn } from "../../../utils/cn";

const CARE_VALUE_TITLE = "Innovative treatment for your unique microbiome";

const INCLUDED_IN_BUNDLE_BULLETS = [
  "A custom treatment program personalized to your unique microbiome and symptoms",
  "This program may include treatments like boric acid, targeted antibiotics / antifungals, vaginal + oral probiotics and supplements, and/or a soothing cream",
  "Dedicated support from our expert health coaches, versatile travel bag, and a provider-designed treatment calendar",
];

const HOW_IT_WORKS_BULLETS = [
  "Purchase selected Rx treatments",
  "Answer questions about your symptoms and preferences",
  "An Evvy-affiliated provider will review your treatments, results, and health profile within 4-8 business hours. We’ll reach out to you over email if there have any questions",
  "Prescribed treatments will be shipped straight to your door (and typically arrive within 6 business days of purchase)",
];

const WHY_IT_WORKS_BULLETS = [
  "Prescription-only and pharmaceutical-grade products that are designed to soothe symptoms and regrow protective bacteria strains",
  "Compounded with supplements like lactoferrin and Vitamin D, proven to further support the regrowth of protective bacteria",
  "Where possible, products are administered vaginally, ensuring fast and effective delivery of the right medications",
];

const INCLUDED_IN_SECTION_DETAILS = {
  title: "Included in the Evvy Complete Treatment Program",
  bullets: INCLUDED_IN_BUNDLE_BULLETS,
  iconType: "arrow" as const,
  disclaimer:
    "*A provider will review your results, preferences, and health intake to design the best treatment program for you. Your specific prescriptions will be determined at the sole discretion of an Evvy-affiliated provider.",
  hiddenIfNotEligibleForCare: true,
};

const HOW_IT_WORKS_SECTION_DETAILS = {
  title: "How it works",
  bullets: HOW_IT_WORKS_BULLETS,
  iconType: "arrow" as const,
};

const WHY_IT_WORKS_SECTION_DETAILS = {
  title: "Why it works",
  bullets: WHY_IT_WORKS_BULLETS,
  iconType: "checkmark" as const,
  disclaimer:
    "*Your specific prescriptions will be determined at the sole discretion of an Evvy-affiliated provider.",
};

interface Section {
  title: string;
  bullets: string[];
  iconType?: "arrow" | "checkmark";
  disclaimer?: string;
  hiddenIfNotEligibleForCare?: boolean;
}

interface CareValuePropsProps {
  eligibleForCare: boolean;
}

const CareValueProps: React.FC<CareValuePropsProps> = ({ eligibleForCare }) => {
  const visibleSections = [
    INCLUDED_IN_SECTION_DETAILS,
    HOW_IT_WORKS_SECTION_DETAILS,
    WHY_IT_WORKS_SECTION_DETAILS,
  ].filter(
    (section: Section) => eligibleForCare || !section.hiddenIfNotEligibleForCare
  );

  const [expandedSection, setExpandedSection] = useState<string | null>(
    visibleSections[0]?.title || null
  );

  const toggleSection = (title: string) => {
    setExpandedSection((prev) => (prev === title ? null : title));
  };

  return (
    <div className="pt-20 pb-10 md:py-28 px-5 bg-evvy-dark-cream flex flex-row justify-center items-start lg:gap-10">
      <div className="md:w-2/5 lg:w-1/3 min-h-fit">
        <h3 className="lg:text-[40px] mb-10">{CARE_VALUE_TITLE}</h3>
        <img
          src={CareFullTreatmentDiagramMinimized}
          alt="Care probiotics diagram"
          className="mx-auto max-h-80 xs:max-h-96 md:hidden mb-8"
        />
        {visibleSections.map((section: Section) => {
          const isExpanded = expandedSection === section.title;
          return (
            <div key={section.title} className="mb-6">
              <div
                className="flex justify-between w-full cursor-pointer"
                onClick={() => toggleSection(section.title)}
              >
                <div className="text-lg font-medium">{section.title}</div>
                {isExpanded ? (
                  <ChevronUpIcon className="h-5 w-5 transition-transform duration-300 flex-shrink-0" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5 transition-transform duration-300 flex-shrink-0" />
                )}
              </div>

              <div
                className={cn(
                  "overflow-hidden transition-all duration-300 ease-linear",
                  {
                    "max-h-[550px] opacity-100": isExpanded,
                    "max-h-0 opacity-0": !isExpanded,
                  }
                )}
              >
                <div className="mt-8">
                  <Bullets
                    bullets={section.bullets}
                    iconType={section?.iconType}
                  />
                  {section.disclaimer && (
                    <p className="text-xs mt-4 text-evvy-black ml-4">
                      {section.disclaimer}
                    </p>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <img
        src={CareFullTreatmentDiagram}
        alt="Care probiotics diagram"
        className="hidden md:block object-contain sm:w-1/3 md:w-[45%] max-w-2xl"
      />
    </div>
  );
};

export default CareValueProps;
