import { createContext } from "react";
import { Consult } from "../types/care";

export type ConsultsContextType = {
  consults?: Consult[];
  setConsults: (consults: Consult[]) => void;
  loading: boolean;
};

export const ConsultsContext = createContext<ConsultsContextType>({
  setConsults: () => {},
  loading: false,
});
