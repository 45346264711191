import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import PersonalInformationForm from "../../../components/healthContext/personalInformationForm";
import BasicsForm from "../../../components/healthContext/basicsForm";
import SymptomsForm from "../../../components/healthContext/symptomsForm";
import DiagnosesForm from "../../../components/healthContext/diagnosesForm";
import TreatmentsForm from "../../../components/healthContext/treatmentsForm";
import SexForm from "../../../components/healthContext/sexForm";
import AlreadySubmitted from "../../../components/healthContext/alreadySubmittedMessage";
import AnythingElseForm from "../../../components/healthContext/anythingElseForm";

// borrowing this from health history
import InvalidForm from "../../../components/healthHistory/invalidForm";
import { testsService } from "../../../services/tests";
import { sendPatientRespondedToProviderAuthorization } from "../../../utils/analytics/customEventTracking";

// this is a page used to fill out a section of the health history form
// it'll contain that form's page, and navigation to go forward/back
const ContextPage = ({
  healthContext,
  test,
  updateHealthContext,
  ldtEnabled,
  addressInfo,
  updateAddressInfo,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(null);

  // route params
  const { testHash: hash, page } = useParams();
  const navigate = useNavigate();

  // available pages
  const defaultPages = [
    "basics",
    "symptoms",
    "diagnoses",
    "treatments",
    "sex",
    "anything-else",
  ];
  var additionalPages = []; // we might at some point add additional pages depending on the user's source
  if (ldtEnabled) {
    additionalPages = ["personal-information"];
  }
  const allPages = [...additionalPages, ...defaultPages];

  // get current page's context regarding all pages
  const isValidPage = allPages.includes(page);
  const pageIndex = isValidPage ? allPages.indexOf(page) : null; // starts at 0. increment page number to user appropriately
  const currentPageName = isValidPage ? allPages[pageIndex] : "invalid";

  const isFirstPage = isValidPage ? pageIndex === 0 : null;
  const isLastPage = isValidPage ? pageIndex === allPages.length - 1 : null;
  const previousPage = !isFirstPage ? allPages[pageIndex - 1] : null;
  const nextPage = !isLastPage ? allPages[pageIndex + 1] : null;

  // removing sample requirement for now
  // const completedSample = healthContext && healthContext.sample_ph && healthContext.sample_taken_at;
  const completedSample = healthContext && healthContext.sample_taken_at;

  // actions
  const goToSamplePage = () => {
    if (test?.is_ldt) {
      // go to LDT version of sample page where reminds to put label on tube
      navigate(`/tests/${hash}/label/sample/`);
    } else {
      // go to regular sample taken page
      navigate(`/tests/${hash}/sample/`);
    }
  };

  const submitCurrentPage = (data, onError) => {
    // if user has already taken their sample, just go to the next page
    if (completedSample) {
      if (nextPage) {
        navigate(`/tests/${hash}/context/${nextPage}/`);
        window.scrollTo(0, 0);
      } else {
        // done with the health context, go to sample page
        goToSamplePage();
        window.scrollTo(0, 0);
      }
      return;
    }

    // submits current page then goes to next one if there is one
    setIsSubmitting(true);

    // submit address info
    if (ldtEnabled && currentPageName === "personal-information") {
      const { provider_release, ...rest } = data;

      if (test.is_provider_ordered) {
        testsService.authorizeReleaseToProvider(hash, provider_release);
        sendPatientRespondedToProviderAuthorization({
          providerId: test.provider?.id,
          testHash: hash,
          testOrder: test.test_order,
          resultsReleasedToProvider: provider_release,
        });
      }

      updateAddressInfo(
        rest,
        (updatedAddressInfo) => {
          setIsSubmitting(false);
          // proceed to next page
          navigate(`/tests/${hash}/context/${nextPage}/`);
          window.scrollTo(0, 0);
        },
        (response) => {
          setIsSubmitting(false);
          if (onError) onError(response);
          console.log("error submitting", response);
        }
      );
      return;
    }
    updateHealthContext(
      hash,
      data,
      (newHealthContext) => {
        setIsSubmitting(false);
        if (nextPage) {
          navigate(`/tests/${hash}/context/${nextPage}/`);
          window.scrollTo(0, 0);
        } else {
          // done with the health context, go to take the sample
          // TODO: if you already took the sample, maybe continue to the health history?
          goToSamplePage();
          window.scrollTo(0, 0);
        }
      },
      (response) => {
        console.log("error submitting", response);
        setIsSubmitting(false);
        if (onError) {
          onError(response);
        }
      }
    );
  };

  // pass the pagination props to the subroutes (mainly for the footer)
  const subrouteProps = {
    submitCurrentPage: submitCurrentPage,
    test: test,
    hash: hash,
    isLastPage: isLastPage,
    previousPage: previousPage,
    pageIndex: pageIndex,
    totalPages: allPages.length,
    isSubmitting: isSubmitting,
    healthContext: healthContext,
    completedSample: completedSample,
    addressInfo: addressInfo,
  };

  // don't render any forms until we've loaded up the health context
  if (!healthContext) return "";

  const formElement = () => {
    switch (currentPageName) {
      case "personal-information":
        return <PersonalInformationForm {...subrouteProps} />;
      case "basics":
        return <BasicsForm {...subrouteProps} />;
      case "symptoms":
        return <SymptomsForm {...subrouteProps} />;
      case "diagnoses":
        return <DiagnosesForm {...subrouteProps} />;
      case "treatments":
        return <TreatmentsForm {...subrouteProps} />;
      case "sex":
        return <SexForm {...subrouteProps} />;
      case "anything-else":
        return <AnythingElseForm {...subrouteProps} />;
      default:
        return <InvalidForm />;
    }
  };

  return (
    <div>
      <Helmet>
        <title>Test | Context | {currentPageName}</title>
      </Helmet>
      {completedSample ? <AlreadySubmitted /> : ""}
      {formElement()}
    </div>
  );
};

export default ContextPage;
