import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import BlueRectangularButton from "../../../components/common/blueRectangularButton";
import { ReactComponent as Shield } from "../../../components/care/icons/shield.svg";
import { ReactComponent as Checkmark } from "../../../components/common/icons/checkmark.svg";
import { sendConsultIntakeViewSection } from "../../../utils/analytics/customEventTracking";

const IdentityVerificationInfo = ({
  consultIntake,
  submitPage,
  loading,
  redo,
}) => {
  const idErrorReason = consultIntake?.consult?.error_reason_display;
  const idErrorDetails = consultIntake?.consult?.error_details;
  const isMobile = window && window.innerWidth < 600 ? true : false;

  /* Effects */
  // First render
  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consultIntake.consult.uid,
      testHash: consultIntake?.consult?.test_hash,
      section: "identity",
    });
  }, []);

  const continueToNextPage = () => {
    // submit no data, just continue to next page
    submitPage({});
  };
  const helmetTitleSuffix = redo ? "Redo" : "Info";

  return (
    <div className="block w-full mt-8 sm:mt-10">
      <Helmet>
        <title>{`Care | Consult | ${
          consultIntake.consult.type === "sti" ? "STI Intake" : "Intake"
        } | Identity Verification | ${helmetTitleSuffix}`}</title>{" "}
      </Helmet>

      <div className="max-w-2xl px-4">
        <h3 className="text-center">Identity Verification</h3>
        <div className="flex-1 bg-evvy-white p-5 sm:p-8 rounded-t-lg border-b border-gray-300">
          {redo && (
            <div className="bg-red-100 p-3 mb-3">
              <div>
                {`${idErrorReason ? `${idErrorReason}: ` : ""}${
                  idErrorDetails
                    ? `${idErrorDetails}`
                    : "There was a problem with your originally uploaded images."
                }`}
              </div>
              <div>Please upload again to proceed.</div>
            </div>
          )}
          <div className="t1 medium">
            Share a photo of yourself and your government issued ID
          </div>
          <div className="b1 pt-3">
            In order to consider you for treatment, your provider will use these
            photos to confirm your identity.
          </div>
        </div>
        <div className="flex items-center bg-evvy-white py-5 px-5 sm:px-10 rounded-b-lg">
          <span className="mr-2 self-start">
            <Shield />
          </span>
          <div className="b3 medium">
            Your photos won’t be shared with anyone besides your provider
          </div>
        </div>
        <div className="flex-1 bg-evvy-white px-5 py-5 sm:px-8 sm:pt-8 sm:pb-5 mt-5 mb-3 rounded-lg border-b">
          <div className="t1 medium mb-3">Tips for taking photos:</div>
          <div className="grid grid-cols-4 gap-4">
            <img
              src="/images/headshots/care-selfie-2.png"
              alt="Selfie"
              loading="lazy"
              className="col-span-2"
            />
            <img
              src="/images/graphics/photo-id-2.png"
              alt="PhotoID"
              loading="lazy"
              className="col-span-2"
            />
            <div className="col-span-4 sm:col-span-2">
              <div className="medium mb-4 mt-2 sm:mt-1">
                Tips for taking a good photo:
              </div>
              <ul className={`list-none ml-0 ${isMobile ? "b2" : "b3"}`}>
                <li className="flex items-center">
                  <Checkmark className="mr-2" />
                  The photo is clear and well-lit
                </li>
                <li className="flex items-center">
                  <Checkmark className="mr-2" />
                  You're the only person in the photo
                </li>
                <li className="flex items-center">
                  <Checkmark className="mr-2" />
                  No sunglasses, masks, or hats
                </li>
              </ul>
            </div>
            <div className="col-span-4 sm:col-span-2">
              <div className="medium mb-4 sm:mt-2">
                Tips for taking a photo of your ID:
              </div>
              <ul className={`list-none ml-0 ${isMobile ? "b2" : "b3"}`}>
                <li className="flex items-center">
                  <Checkmark className="mr-2" />
                  Your ID is government-issued
                </li>
                <li className="flex items-center">
                  <Checkmark className="mr-2" />
                  Your photo on the ID is clear
                </li>
                <li className="flex items-center">
                  <Checkmark className="mr-2" />
                  Your name and birth date are clear
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex mt-6 mb-4">
          <BlueRectangularButton
            text="Continue"
            paddingXClass="px-32"
            onClick={continueToNextPage}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default IdentityVerificationInfo;
