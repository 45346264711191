/*
A component for a side panel that swings out from the right on desktop (and is full screen height but pinned to the right in the foreground, everything else is in background / dimmed). On mobile, it is full screen height and width.
*/

import React, { useEffect, useRef } from "react";
import CloseX from "./closeX";

const Panel: React.FC<{
  handleClose: () => void;
  isOpen: boolean;
  children: React.ReactNode;
}> = ({ handleClose, isOpen, children }) => {
  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      //   disable scrolling on body when panel is open
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen, handleClose]);

  return (
    <>
      {isOpen && <div className="fixed inset-0 bg-black opacity-50 z-40" />}
      <div
        className={`fixed top-0 right-0 z-50 h-screen w-full md:w-[560px] bg-evvy-cream shadow-lg duration-500 transition-transform transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
        ref={panelRef}
      >
        {/* top header w/ close button */}
        <div className="h-16 md:h-24 px-4 md:px-7 py-4 flex justify-end">
          <div className="my-auto">
            <CloseX
              handleClose={handleClose}
              textColorClass="text-evvy-black"
              positionClass=""
            />
          </div>
        </div>
        <div>{children}</div>
      </div>
    </>
  );
};

export default Panel;
