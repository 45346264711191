import { useEffect, useMemo, useState } from "react";
import { useLatestReadyTest } from "../../../hooks/test/useLatestReadyTest";
import { useProblemsSummary } from "../../../hooks/care/useProblemsSummary";
import Layout from "../../../components/layout";
import { eventNames } from "../../../utils/analytics/customEventTracking";
import { BaseAnalyticsArgsContext } from "../../../contexts/baseAnalyticsArgsContext";
import TreatmentBundle from "../../../components/care/transparentCare/TreatmentBundle";
import ALaCare from "../../../components/care/aLaCare/ALaCare";
import CareFAQs from "../../../components/care/careFAQs";
import { Link } from "react-router-dom";
import { analyticsClickHandler } from "../../../utils/analytics/helpers";
import { Consult } from "../../../types/care";
import { CareComparison } from "../../../components/care/careComparison";
import { CareReview } from "../../../components/care/careReview";
import CareValueProps from "../../../components/care/transparentCare/CareValueProps";

interface TransparentCareLandingProps {
  completedConsults: Consult[];
}

const TransparentCareLanding = ({
  completedConsults,
}: TransparentCareLandingProps) => {
  const { test } = useLatestReadyTest();
  const [consult, setConsult] = useState<Consult | null>(null);
  const { testSummary } = useProblemsSummary(test?.hash);
  const hasPastTreatments =
    consult?.status === "CP"
      ? completedConsults?.length > 1
      : completedConsults?.length > 0;
  const hasPastConsultMessages = completedConsults.some(
    (c) => c?.consultmessagethread
  );
  const latestVaginitisConsult = test?.latest_vaginitis_consult;
  const eligibleForAnyCare =
    test?.eligible_for_care || test?.eligible_for_a_la_care;
  const PRE_REVIEW_STATUSES = ["IN", "OR", "ST"]; // consult statuses that are "pre-review"
  const CARE_PAGE_VERSION = "v2"; // const for analytics

  const carePageStatus = useMemo(() => {
    if (
      !eligibleForAnyCare ||
      !test?.latest_vaginitis_consult ||
      PRE_REVIEW_STATUSES.includes(test?.latest_vaginitis_consult?.status)
    )
      return "Start";
    else if (test?.latest_vaginitis_consult?.status === "CP") return "Ready";
    else return "In Review";
  }, [eligibleForAnyCare, test?.latest_vaginitis_consult]);

  const baseAnalyticsArgs = useMemo(() => {
    return {
      consultId: test?.latest_vaginitis_consult?.uid,
      testHash: test?.hash,
      careEligible: test?.eligible_for_care,
      eligibleTreatmentPathways:
        test?.eligible_treatment_pathways &&
        test?.eligible_treatment_pathways.length > 0
          ? test?.eligible_treatment_pathways.map((pathway) => pathway.slug)
          : [],
      carePrice: test?.eligible_for_care
        ? test?.care_pricing?.discounted_total
        : null,
      consultStatus: test?.latest_vaginitis_consult?.get_status_display,
      carePageStatus,
      pageVersion: CARE_PAGE_VERSION,
      microbiomeState: testSummary?.problems_summary.microbiome_state,
      conditions: testSummary?.problems_summary.conditions,
      symptoms: testSummary?.problems_summary.symptoms,
      aLaCareEligible: test?.eligible_for_a_la_care,
    };
  }, [
    carePageStatus,
    test?.care_pricing?.discounted_total,
    test?.eligible_for_a_la_care,
    test?.eligible_for_care,
    test?.eligible_treatment_pathways,
    test?.hash,
    test?.latest_vaginitis_consult?.get_status_display,
    test?.latest_vaginitis_consult?.uid,
    testSummary?.problems_summary.conditions,
    testSummary?.problems_summary.microbiome_state,
    testSummary?.problems_summary.symptoms,
  ]);

  useEffect(() => {
    if (test && test.latest_vaginitis_consult) {
      setConsult(test.latest_vaginitis_consult);
    }
  }, [test]);

  const getBaseAnalyticsEventArgs = () => {
    return {
      consultId: consult?.uid,
      testHash: test?.hash,
      testOrder: test?.test_order,
      careEligible: test?.eligible_for_care,
      carePrice: test?.eligible_for_care
        ? test?.care_pricing?.discounted_total
        : null,
      consultStatus: consult?.get_status_display,
      carePageStatus,
      pageVersion: CARE_PAGE_VERSION,
    };
  };

  if (!test) return null;

  return (
    <BaseAnalyticsArgsContext.Provider value={{ baseAnalyticsArgs }}>
      <Layout title={"Care | Shop"} bgClass="bg-evvy-cream" full centered>
        <div className="w-full items-center">
          <TreatmentBundle testHash={test?.hash} />
          <ALaCare testHash={test?.hash} />
          <CareValueProps eligibleForCare={test?.eligible_for_care} />
          <CareComparison />
          <section className="bg-evvy-white">
            <CareReview />
          </section>

          {(hasPastTreatments || hasPastConsultMessages) && (
            <div className="py-6 text-center bg-gradient-to-r from-[#CEDEFC] to-[#BFE3D9] uppercase font-semibold">
              {hasPastTreatments && (
                <Link
                  to="/care/past-treatments/"
                  className="linkedText underline cursor-pointer w-max p-pd"
                  onClick={analyticsClickHandler({
                    eventName: eventNames.CARE_CLICKED_VIEW_PAST_CONSULTATIONS,
                    eventArgs: getBaseAnalyticsEventArgs(),
                  })}
                >
                  View past treatment programs
                </Link>
              )}

              {hasPastTreatments && hasPastConsultMessages && (
                <span className="inline-block caption px-2">|</span>
              )}

              {hasPastConsultMessages && (
                <Link
                  to="/care/consults/messages/"
                  className="linkedText underline cursor-pointer w-max p-pd"
                  onClick={analyticsClickHandler({
                    eventName: eventNames.CARE_CLICKED_VIEW_PAST_MESSAGES,
                    eventArgs: getBaseAnalyticsEventArgs(),
                  })}
                >
                  View past messages
                </Link>
              )}
            </div>
          )}
          <CareFAQs
            consult={latestVaginitisConsult}
            aLaCareEligible={test?.eligible_for_a_la_care}
            v0BundleEligible={test?.eligible_for_care}
            analyticsEventArgs={{}}
            faqsPage={"shop-treatments"}
          />
        </div>
      </Layout>
    </BaseAnalyticsArgsContext.Provider>
  );
};

export default TransparentCareLanding;
