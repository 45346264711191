import { FC, useState } from "react";
import classNames from "classnames";

import { ReactComponent as ExpandIcon } from "../common/icons/expand-icon-white-fill-blue-stroke.svg";
import { ReactComponent as ShrinkIcon } from "../common/icons/shrink-icon-white-fill-blue-stroke.svg";

export type CollapsibleDropdownProps = {
  children: React.ReactNode;
  className?: string;
  headerText: string;
  headerClassname?: string;
  open?: boolean;
  handleOpen?: (open: boolean) => void;
  showHorizontalRule?: boolean;
};

export const CollapsibleDropdown: FC<CollapsibleDropdownProps> = ({
  headerText,
  headerClassname,
  className,
  children,
  open,
  handleOpen,
  showHorizontalRule,
}) => {
  const [internalOpen, setInternalOpen] = useState<boolean>(false); // internal state for when component is not controlled
  const isControlled = open !== undefined;
  const effectiveOpen = isControlled ? open : internalOpen;

  return (
    <div className={className}>
      <div className="flex mb-2">
        <h5 className={classNames("my-auto text-xl", headerClassname)}>
          {headerText}
        </h5>
        <button
          className="ml-auto"
          onClick={() => {
            const newOpen = !effectiveOpen;
            if (!isControlled) {
              setInternalOpen(newOpen);
            }

            if (handleOpen) {
              handleOpen(newOpen);
            }
          }}
        >
          {effectiveOpen ? (
            <ShrinkIcon className="w-6 h-6 border rounded-full " />
          ) : (
            <ExpandIcon className="w-6 h-6 border rounded-full" />
          )}
        </button>
      </div>
      {effectiveOpen && <div className="mt-4">{children}</div>}

      {showHorizontalRule && <hr />}
    </div>
  );
};
