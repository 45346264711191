import { AxiosResponse, AxiosError } from "axios";
import { getRequest, postRequest } from "../utils/axios";
import { AddCartItemPayload } from "../types/cart";

const getOrCreateCart = async () => {
  const response = await postRequest(
    "/api/v1/cart/",
    {},
    (response: AxiosResponse) => {
      return response.data;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

const getLatestActiveCart = async () => {
  const response = await getRequest(
    "/api/v1/cart/",
    (response: AxiosResponse) => {
      if (response.data?.length > 0) {
        return response.data[0];
      }
      return null;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

const fetchCart = async (cartId: string) => {
  const response = await getRequest(
    `/api/v1/cart/${cartId}/`,
    (response: AxiosResponse) => {
      return response.data;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

const addItemsToCart = async (cartId: string, items: AddCartItemPayload[]) => {
  const response = await postRequest(
    `/api/v1/cart/${cartId}/items/`,
    { items },
    (response: AxiosResponse) => {
      return response.data;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

const removeItemsFromCart = async (cartId: string, itemIds: string[]) => {
  const response = await postRequest(
    `/api/v1/cart/${cartId}/items/delete/`,
    { item_ids: itemIds },
    (response: AxiosResponse) => {
      return response.data;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

const createCartCheckout = async (cartId: string) => {
  const response = await postRequest(
    `/api/v1/cart/${cartId}/checkout/`,
    (response: AxiosResponse) => {
      return response.data;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

export const cartService = {
  getOrCreateCart,
  addItemsToCart,
  fetchCart,
  removeItemsFromCart,
  createCartCheckout,
  getLatestActiveCart,
};
