import React from "react";
import { Link } from "react-router-dom";

const IntakeHeader = ({
  onClickBack,
  previousPage,
  baseUrl,
}: {
  onClickBack?: () => void;
  previousPage: string;
  baseUrl: string;
}) => {
  const showNav =
    !window.location.pathname.includes("/intro/") &&
    !window.location.pathname.includes("/done/");

  const isPreviousPageHome =
    previousPage === "/care/" || previousPage === "/tests/";

  return (
    <React.Fragment>
      {/* nav for consult intake page */}
      <nav
        className={`w-full top-0 px-6 md:px-20 py-4 sm:py-5 flex justify-between align-center z-20 border-b-2 border-gray-300 border-solid pointer-events-none`}
      >
        {/* link to "care" */}
        <Link
          to={isPreviousPageHome ? previousPage : `${baseUrl}${previousPage}`}
          onClick={onClickBack ? onClickBack : undefined}
        >
          {/* border  wrapper div*/}
          <button
            className={`p-2 bg-evvy-cream rounded-full border-2 border-evvy-black text-evvy-black group flex items-center pointer-events-auto`}
            aria-label="Back to care"
          >
            {/* back arrow button */}
            {previousPage === "/care/" ? (
              <span className="px-2 h-8 inline uppercase tracking-wider font-semibold">
                <span className="pr-2">
                  <h4 className="font-bold leading-6 margin-0 inline">←</h4>
                </span>{" "}
                Back to care
              </span>
            ) : (
              <span className="uppercase w-7 h-7 text-center">
                <h4 className="font-bold leading-6">←</h4>
              </span>
            )}
          </button>
        </Link>

        {/* Nav for desktop */}
        {showNav ? (
          <div className="mx-auto w-full hidden sm:flex max-w-5xl items-center">
            <div className="flex-1 flex items-center justify-center color-evvy-black p-2 t4 text-center uppercase tracking-wider pointer-events-none font-semibold border-t-4 border-transparent">
              <div
                className={`border rounded-full text-center mr-2 ${
                  window.location.pathname.includes("/demographics")
                    ? "border-crazy-purple bg-crazy-purple text-white"
                    : "border-black"
                }`}
              >
                <div className="w-8 h-8 leading-6 flex items-center justify-center b1 semibold rounded-full">
                  1
                </div>
              </div>
              <div>Demographics</div>
            </div>
            <div className="flex-1 flex items-center justify-center color-evvy-black p-2 t4 text-center uppercase tracking-wider pointer-events-none font-semibold border-t-4 border-transparent">
              <div
                className={`border rounded-full text-center mr-2 ${
                  window.location.pathname.includes("/medical-questions")
                    ? "border-coral bg-coral text-white"
                    : "border-black"
                }`}
              >
                <div className="w-8 h-8 leading-6 flex items-center justify-center b1 semibold rounded-full">
                  2
                </div>
              </div>
              <div>Medical Questions</div>
            </div>
            <div className="flex-1 flex items-center justify-center color-evvy-black p-2 t4 text-center uppercase tracking-wider pointer-events-none font-semibold border-t-4 border-transparent">
              <div
                className={`border rounded-full text-center mr-2 ${
                  window.location.pathname.includes("/treatment")
                    ? "border-dv-pond bg-dv-pond text-white"
                    : "border-black"
                }`}
              >
                <div className="w-8 h-8 leading-6 flex items-center justify-center b1 semibold rounded-full">
                  3
                </div>
              </div>
              <div>Treatment Questions</div>
            </div>
            <div className="flex-1 flex items-center justify-center color-evvy-black p-2 t4 text-center uppercase tracking-wider pointer-events-none font-semibold border-t-4 border-transparent">
              <div
                className={`border rounded-full text-center mr-2 ${
                  window.location.pathname.includes("/identity-verification")
                    ? "border-evvy-silverfish bg-evvy-silverfish text-white"
                    : "border-black"
                }`}
              >
                <div className="w-8 h-8 leading-6 flex items-center justify-center b1 semibold rounded-full">
                  4
                </div>
              </div>
              <div>Identity Verification</div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* evvy logo */}
        <Link to="/" className="pointer-events-auto">
          <img
            className="h-8 mt-2 sm:h-10"
            src="/images/evvy-logo-text.svg"
            loading="lazy"
            alt="Evvy Text Logo"
          />
        </Link>
      </nav>

      {/* nav for mobile */}
      {showNav && (
        <div className="sm:hidden flex py-4 px-2 font-semibold uppercase tracking-wider border-b-2 border-gray-300 border-solid">
          <div
            className={`flex items-center mx-auto ${
              window.location.pathname.includes("/demographics")
                ? "block"
                : "hidden"
            }`}
          >
            <div className="border rounded-full border-crazy-purple bg-crazy-purple text-white text-center mr-2">
              <div className="w-6 h-6 block leading-6">1</div>
            </div>
            <div>Demographics</div>
          </div>
          <div
            className={`flex items-center mx-auto ${
              window.location.pathname.includes("/medical-questions")
                ? "block"
                : "hidden"
            }`}
          >
            <div className="border rounded-full border-coral bg-coral text-white text-center mr-2">
              <div className="w-6 h-6 block leading-6">2</div>
            </div>
            <div>Medical Questions</div>
          </div>
          <div
            className={`flex items-center mx-auto ${
              window.location.pathname.includes("/treatment")
                ? "block"
                : "hidden"
            }`}
          >
            <div className="border rounded-full border-marine bg-marine text-white text-center mr-2">
              <div className="w-6 h-6 block leading-6">3</div>
            </div>
            <div>Treatment Questions</div>
          </div>
          <div
            className={`flex items-center mx-auto ${
              window.location.pathname.includes("/identity-verification")
                ? "block"
                : "hidden"
            }`}
          >
            <div className="border rounded-full border-evvy-silverfish bg-evvy-silverfish text-white text-center mr-2">
              <div className="w-6 h-6 block leading-6">4</div>
            </div>
            <div>Identity Verification</div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default IntakeHeader;
