import React from "react";
import Markdown from "markdown-to-jsx";

import { ReactComponent as GradientCheck } from "./icons/gradient-check.svg";
import { ReactComponent as CircleX } from "./icons/circle-x.svg";
import { ReactComponent as ArrowShort } from "./icons/arrow-short.svg";
import { ReactComponent as ArrowLong } from "./icons/arrow-long.svg";
import { getIsMobile } from "../../utils/general";

const TITLE = "Get specialized care, at a fraction of the cost";

const ROWS_CONFIG = [
  {
    text: "Avg. cost of provider consultation and treatment",
    type: "text",
    stats: ["$100", "< $250", "$2000"],
  },
  {
    text: "Avg. wait time for a provider consultation",
    type: "text",
    stats: ["< 48 hrs", "< 12 hrs", "> 30 days"],
  },
  {
    text: "Access to antibiotics / antifungals",
    type: "checkmark",
    stats: [],
    checkmarks: [true, true, true],
  },
  {
    text: "Access to custom probiotics, both oral and vaginal ",
    type: "checkmark",
    stats: [],
    checkmarks: [false, true, true],
  },
  {
    text: "Comprehensive treatment program that reduces disruptive microbes and builds protective bacteria ",
    type: "checkmark",
    stats: [],
    checkmarks: [false, true, true],
  },
];
const URGENT_CARE_TITLE_MD = "OB-GYN/<br>Urgent Care";
const VAGINTIS_SPECIALIST_TITLE_MD = "Vaginitis<br>Specialist";

export const CareComparison = () => {
  return (
    <div className="justify-center py-14">
      <h3 className="text-center mb-14 sm:mb-20 sm:text-5xl">{TITLE}</h3>
      <div className="max-w-6xl mx-auto px-4 sm:px-0">
        <ComparisonTable />
      </div>
    </div>
  );
};

type TableRowProps = {
  text: string;
  type: string;
  stats: string[];
  checkmarks?: boolean[];
};

const TableRow: React.FC<TableRowProps> = ({
  text,
  stats,
  type,
  checkmarks,
}) => {
  return (
    <div className="flex mb-6 gap-0 bg-white rounded-r-xl">
      <div className="w-1/2 sm:w-2/6 bg-evvy-pine p-2.5 sm:p-4 rounded-l-xl">
        <p className="text-white text-base sm:text-lg sm:font-semibold my-auto !leading-snug">
          {text}
        </p>
      </div>
      <div className="w-1/2 sm:w-4/6 flex text-center justify-center">
        <div className="w-1/3 py-4 px-1 sm:px-4 flex items-center">
          {type === "checkmark" && checkmarks ? (
            checkmarks[0] ? (
              <GradientCheck className="h-10 w-10 sm:h-12 sm:w-12 my-auto mx-auto" />
            ) : (
              <CircleX className="h-10 w-10 sm:h-12 sm:w-12 my-auto mx-auto" />
            )
          ) : (
            <div className="my-auto mx-auto serif text-lg sm:text-[26px] text-gray-600">
              {stats[0]}
            </div>
          )}
        </div>
        <div className="w-1/3 py-4 px-1 sm:px-4 flex items-center">
          {type === "checkmark" && checkmarks ? (
            checkmarks[1] ? (
              <GradientCheck className="h-10 w-10 sm:h-14 sm:w-14 z-20 my-auto mx-auto" />
            ) : (
              <CircleX className="h-10 w-10 sm:h-14 sm:w-14 z-20 my-auto mx-auto" />
            )
          ) : (
            <div className="my-auto mx-auto serif text-lg sm:text-4xl text-evvy-pine z-20">
              {stats[1]}
            </div>
          )}
        </div>
        <div className="w-1/3 py-4 px-1 sm:px-4 flex items-center">
          {type === "checkmark" && checkmarks ? (
            checkmarks[2] ? (
              <GradientCheck className="h-10 w-10 sm:h-12 sm:w-12 my-auto mx-auto" />
            ) : (
              <CircleX className="h-10 w-10 sm:h-12 sm:w-12 my-auto mx-auto" />
            )
          ) : (
            <div className="my-auto mx-auto serif text-lg sm:text-[26px] text-gray-600">
              {stats[2]}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ComparisonTable = () => {
  const isMobile = getIsMobile();

  return (
    <>
      <div className="sm:hidden absolute left-0 w-full">
        <div className="px-6 text-sm/[14px] uppercase tracking-wider font-semibold text-gray-500">
          <div className="w-full flex justify-between pr-5">
            <Markdown>{VAGINTIS_SPECIALIST_TITLE_MD}</Markdown>
            <ArrowLong className="mt-2" />
          </div>
          <div className="w-2/3 flex justify-between -mt-9 pr-5">
            <Markdown>{URGENT_CARE_TITLE_MD}</Markdown>
            <ArrowShort className="mt-2" />
          </div>
        </div>
      </div>
      <div className="pt-8 sm:pt-0">
        <div className="relative pb-2 sm:pb-8">
          <div className="flex py-6 sm:py-8">
            <div className="relative left-1/2 sm:left-1/3 w-1/2 sm:w-4/6 flex text-center justify-center text-base uppercase tracking-wider semibold text-gray-500">
              <div className="hidden sm:block w-1/3 p-4 flex items-center">
                <div className="my-auto mx-auto">
                  <Markdown>{URGENT_CARE_TITLE_MD}</Markdown>
                </div>
              </div>
              <div className="w-1/3 flex items-center">
                <img
                  className="h-6 sm:h-10 my-auto mx-auto z-20"
                  src="/images/evvy-logo-text.svg"
                  loading="lazy"
                  alt="Evvy Text Logo"
                />
              </div>
              <div className="hidden sm:block w-1/3 rounded-r-xl flex items-center">
                <div className="my-auto mx-auto">
                  <Markdown>{VAGINTIS_SPECIALIST_TITLE_MD}</Markdown>
                </div>
              </div>
            </div>
          </div>
          {ROWS_CONFIG.map((row, index) => (
            <TableRow
              key={index}
              text={row.text}
              stats={row.stats}
              type={row.type}
              checkmarks={row.checkmarks}
            />
          ))}
          <div className="absolute left-1/2 sm:left-1/3 top-0 w-1/2 sm:w-2/3 h-full flex justify-center">
            <div
              className="absolute top-0 w-1/3 h-full rounded-xl bg-evvy-silverfish"
              style={
                !isMobile
                  ? {
                      backgroundImage:
                        "url('/images/graphics/plan-bg-gradient.png')",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      transform: "rotate(180deg)",
                    }
                  : {}
              }
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};
