import { useState, useEffect } from "react";
import { testsService } from "../../services/tests";
import { Test } from "../../types/test";
import axios from "axios";

export const useLatestReadyTest = () => {
  const [data, setData] = useState<Test>();
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const fetchLatestReadyTest = async () => {
      setIsLoading(true);
      testsService.fetchLatestReadyTest(
        (response) => {
          setData(response.data);
          setIsLoading(false);
        },
        (error, response) => {
          if (axios.isAxiosError(error)) {
            setError(error.message);
          }
        }
      );
    };
    fetchLatestReadyTest();
  }, [refetch]);

  const fetchLatestReadyTest = () => {
    setRefetch((prev) => !prev);
  };

  return {
    test: data,
    error,
    isLoading,
    fetchLatestReadyTest,
  };
};
